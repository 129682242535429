import axios from 'axios'
import {SkillModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export function getLanguagesSkills() {
  return axios.get<SkillModel[]>(`${API_URL}/api/skills?type=1`)
}

export function getExtraSkills(skills: string[]) {
  let relatedSkills = '';
  skills.map(function (relatedSkill){
    relatedSkills += '&relatedSkills.slug[]=' + relatedSkill;
  });

  return axios.get<SkillModel[]>(`${API_URL}/api/skills?type[]=2&type[]=3${relatedSkills}`)
}
