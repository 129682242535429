import React, {FC} from 'react'
import {useIntl} from "react-intl";
import {UserBasicModel} from "../auth";
import {Link} from "react-router-dom";

type Props = {
  user?: UserBasicModel,
  showImage?: boolean
}

const ProfileHeader: FC<Props> = ({user, showImage = true}) => {
  const intl = useIntl()

  return (
    <div className='card mb-1'>
      <div className='card-body pt-5 pb-0 p-5 pb-5'>
        <div className='d-flex flex-wrap flex-sm-nowrap'>
          <div className='me-4'>
            {showImage &&
              <div className='symbol symbol-50px symbol-lg-90px symbol-fixed position-relative'>
                <img src={user?.image} alt={''} />
                {/*<div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'/>*/}
              </div>
            }
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center'>
                  <div className='text-gray-800 text-hover-primary fs-3 fw-bolder me-1'>
                    {user?.firstName}
                  </div>
                  {/*<i className="bi fs-2 bi-shield-check mx-3 me-0 text-success fw-bold"/>*/}
                </div>
                {user?.url !== null &&
                <div className='fw-bold mb-2'>
                  <span className='text-gray-400 text-hover-primary fs-3'>
                    <Link to={'/' + user?.url}>zdalny.dev/{user?.url}</Link>
                  </span>
                </div>
                }
              </div>

              <div className='d-flex'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded py-3 px-3 me-2 mb-2'>
                    <div className='d-flex flex-wrap fw-bold fs-6 d-flex align-items-center h-100' style={{marginLeft: '5px'}}>
                      {user?.skills.map((skill, projectIndex) => (
                          <button key={projectIndex} className={skill.colorClass + ' technology-button'} style={{height: "100%", width: '50px'}} title={skill.name}><i className={skill.svgIcon}/></button>
                      ))}
                    </div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded py-3 px-3 me-2 mb-2'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-3 fw-bolder'>{user?.leadingJobRole?.name ?? '-'}</div>
                    </div>
                    <div className='fw-bold fs-6 text-gray-400'>{intl.formatMessage({id: 'PROFILE_HEADER.LEADING_ROLE'})}</div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded py-3 px-3 me-2 mb-2'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-3 fw-bolder'>{user?.experience ?? '-'}</div>
                    </div>
                    <div className='fw-bold fs-6 text-gray-400'>{intl.formatMessage({id: 'PROFILE_HEADER.EXPERIENCE'})}</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded py-3 px-3 me-2 mb-2'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-3 fw-bolder'>{user?.availability ? intl.formatMessage({id: ('USER.AVAILABILITY.' + user?.availability)}) : '-'}</div>
                    </div>
                    <div className='fw-bold fs-6 text-gray-400'>{intl.formatMessage({id: 'PROFILE_HEADER.AVAILABILITY'})}</div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded py-3 px-3 mb-2'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-3 fw-bolder'>{user?.ratePerHour ?? '-'}</div>
                    </div>
                    <div className='fw-bold fs-6 text-gray-400'>{intl.formatMessage({id: 'PROFILE_HEADER.RATE'})} {user?.rateCurrencySymbol ?? '-'}/h</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {user?.extraSkills && (
            <div className='d-flex flex-wrap mt-3'>
              {user?.extraSkills.map((skill, index) => (
                  <div key={index} className="border border-1 border-gray-300 border-dashed rounded py-1 px-2 text-left me-1 mt-1">
                    <i className="text-primary bi bi-check"/> {skill.name}
                  </div>
              ))}
            </div>
        )}
      </div>
    </div>
  )
}

export {ProfileHeader}
