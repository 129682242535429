import axios from 'axios'
import {ProjectSerialized} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export function getProject(slug: string) {
    return axios.get<ProjectSerialized[]>(`${API_URL}/api/projects?page=1&slug=${slug}`)
}

export function getProjects(page: number = 1) {
    return axios.get<ProjectSerialized[]>(`${API_URL}/api/projects?order[created]=DESC&page=${page}`)
}
