import React from "react";
import {useParams} from 'react-router-dom';
import {useAuth} from "../auth";
import {useIntl} from "react-intl";
import {PageTitle} from "../../../_metronic/layout/core";

const JobsPage = () => {
    const intl = useIntl()
    const { currentUser } = useAuth()
    let { skillSlug } = useParams();

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DEDICATED_JOBS'}) + ' - ' + skillSlug}</PageTitle>
            <div className='mw-500px px-1 py-3 m-auto text-center'>
                <div className='d-flex'>
                    <div className="bg-light-primary text-primary border border-primary rounded m-9 p-9 w-100 shadow align-content-center align-items-center d-flex">
                        <i className="bi bi-emoji-neutral text-primary fs-3x me-4"/>
                        <div className='w-100 fs-4'>
                            {intl.formatMessage({id: 'JOBS_PAGE.NOT_FOUND'})}
                            <small className='text-gray-500 pt-2 d-block fs-7'>
                                {intl.formatMessage({id: 'JOBS_PAGE.NOT_FOUND.SUBTITLE'})}
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export {JobsPage}
