import React, {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {useIntl} from "react-intl";
import axios from "axios";
import {Formik, Form, FormikValues, Field, ErrorMessage, useFormik} from 'formik'
import {TagCategorySerialized, useAuth} from "../../auth";
import {Login} from "../../auth/components/Login";
import {getUserByToken} from "../../auth/core/_requests";

const API_URL = process.env.REACT_APP_API_URL

const updateUserTagCategories = (values: TopicsForm) => {
    return axios.post(`${API_URL}/rest/api/users/me/tag-categories`, values)
}

export function getTopics() {
    return axios.get<TagCategorySerialized[]>(`${API_URL}/api/tag_categories?enabled=1`)
}

export interface TopicsForm {
    topics: TagCategorySerialized[]
}

type Props = {
    show: boolean
    handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const TopicsModal = ({show, handleClose}: Props) => {
    const intl = useIntl()
    const { currentUser, setCurrentUser } = useAuth()

    const [loading, setLoading] = useState(false)
    const [loginRequired, setLoginRequired] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const [tagCategories, setTagCategories] = useState<TagCategorySerialized[]>([]);

    useEffect(() => {
        if(!show) return;
        setSaveLoading(false);
        setLoginRequired(false);
        setLoading(true)

        const loadTopics = async () => {
            const response = await getTopics();
            setTagCategories(response.data);
            setLoading(false)
        }

        loadTopics().catch(() => {
            setLoading(false)
        });
    }, [show])

    useEffect(() => {
        setLoginRequired(false);
    }, [currentUser])

    const submitStep = async (values : TopicsForm , actions: FormikValues) => {
        if(currentUser){
            setLoginRequired(false);
            setSaveLoading(true);

            const {data: auth} = await updateUserTagCategories(values)
            const {data: user} = await getUserByToken(auth.token)
            setCurrentUser(user)
            setSaveLoading(false);
            handleClose();

            setLoading(false)
        }else{
            setLoginRequired(true);
        }
    }

    return createPortal(
        <Modal
            id='kt_modal_topics'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-550px'
            show={show}
            onHide={handleClose}
            backdrop={true}
        >
            <div className={'modal-body py-0 border border-primary rounded border-1 py-4 px-0 ' + (!loginRequired && 'bg-dark')}>
                {!loginRequired &&
                    <div className='px-5'>
                        <div className='fs-1 mb-2 fw-bold text-white text-center'>
                            Wybierz tematy do obserwowania
                        </div>
                        <div className='fs-5 mb-4 text-white text-center'>
                            Wybierz tematy, które Cię interesują. <br/>Zawsze możesz je później zmienić.
                        </div>
                    </div>
                }
                <div className='text-center pt-4'>
                    {loading &&
                    <div className='mw-500px px-1 py-3 m-auto text-center'>
                        <span className="spinner-border text-primary" role="status"/>
                    </div>
                    }

                    {loginRequired &&
                    <>
                        <Login />
                    </>
                    }

                    {(!loading && tagCategories) &&
                    <Formik
                        initialValues={{topics: []}}
                        // validationSchema={validationSchema}
                        onSubmit={submitStep}
                    >
                        {() => (
                            <>
                            <Form>
                                {!loginRequired &&
                                    <>
                                        <div className='row g-6 row-cols-1 row-cols-sm-2 row-cols-md-3 mh-500px overflow-scroll mx-5'>
                                            {tagCategories.map((tagCategory, key) => {
                                                return (
                                                    <div className='col mb-2' key={key}>
                                                        <Field type='checkbox' className='btn-check' name='topics' value={tagCategory.id} id={'topics_form_topics_' + tagCategory.id} />
                                                        <label className='btn btn-primary btn-active-light-primary w-100 p-4 h-80px d-flex align-items-center hover-rotate-end' htmlFor={'topics_form_topics_' + tagCategory.id} >
                                                            <span className='d-inline-block w-100 text-center fs-4'>{tagCategory.name}</span>
                                                        </label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className='d-flex pt-5 align-items-center border border-primary border-bottom-0 border-right-0 border-left-0'>
                                            <div className='w-200px px-1 py-3 m-auto text-center fs-4'>
                                                {!saveLoading &&
                                                <button type='submit' className='btn btn-lg btn-primary hover-scale'>
                                              <span className='indicator-label'>
                                                  {currentUser &&
                                                  <>
                                                      <i className='bi bi-check2-square fs-3'/> Zapisz
                                                  </>
                                                  }
                                                  {!currentUser &&
                                                  <>
                                                      <i className='bi bi-check2-square fs-3'/> Dalej
                                                  </>
                                                  }
                                              </span>
                                                </button>
                                                }
                                                {saveLoading && <span className="spinner-border text-primary" role="status"/>}
                                            </div>
                                        </div>
                                    </>
                                }
                            </Form>
                            </>
                        )}
                    </Formik>
                    }
                </div>

                {/*<div className='mw-600px m-auto mt-10 mb-2 d-flex align-items-center bg-light-primary border border-primary rounded px-5 py-3'>*/}
                {/*    <div className='w-100 fs-5 fw-bold text-center me-2'>*/}
                {/*        /!*Uzyskaj treści, które Cie <strong>naprawdę</strong> interesują..*!/*/}
                {/*        Uzyskaj treści, <strong>dopasowane do Twoich</strong> zainteresowań..*/}
                {/*    </div>*/}
                {/*    <button*/}
                {/*        className='btn btn-light-primary border border-dashed border-primary hover-scale w-200px px-2 py-2 d-flex align-items-center'*/}
                {/*        // onClick={() => {setTopicsOpened(true);}}*/}
                {/*    >*/}
                {/*        /!*<i className="bi bi-columns-gap px-0 me-2 fs-3"/>*!/*/}
                {/*        /!*<i className="bi bi-hand-index-thumb px-0 me-2 fs-3"/>*!/*/}
                {/*        /!*<i className="bi bi-hand-index px-0 me-2 fs-3"/>*!/*/}
                {/*        <i className="bi bi-ui-checks-grid px-0 me-2 fs-3"/>*/}
                {/*        Wybierz tematy*/}
                {/*    </button>*/}
                {/*</div>*/}
            </div>
        </Modal>,
        modalsRoot
    )
}

export {TopicsModal}
