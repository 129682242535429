import axios from 'axios'
import {SourceSerialized, TagInterface, TwitListInterface, TwitSerialized} from './_models'
import {TagCategorySerialized} from "../../auth";

const API_URL = process.env.REACT_APP_API_URL

export function getTwit(slug: string) {
    return axios.get<TwitSerialized[]>(`${API_URL}/api/twits?page=1&slug=${slug}`)
}

export function getSource(handle: string) {
    return axios.get<SourceSerialized[]>(`${API_URL}/api/sources?page=1&handle=${handle}`)
}

export function getTag(slug: string) {
    return axios.get<TagInterface[]>(`${API_URL}/api/tags?page=1&slug=${slug}`)
}

export function getTopic(slug: string) {
    return axios.get<TagCategorySerialized[]>(`${API_URL}/api/tag_categories?enabled=1&page=1&slug=${slug}`)
}

export function getTwits({listType, sourceHandle, tagSlug, topicSlug}: TwitListInterface, page: number = 1) {
    if(listType === 'trending'){
        return axios.get<TwitSerialized[]>(`${API_URL}/api/twits?order[created]=DESC&order[numViews]=DESC&numViews[gte]=1&page=${page}&language.slug=PL`)
    }
    if(listType === 'upvoted'){
        return axios.get<TwitSerialized[]>(`${API_URL}/api/twits?order[created]=DESC&order[numUpvotes]=DESC&numUpvotes[gte]=1&page=${page}&language.slug=PL`)
    }
    if(listType === 'bookmarks'){
        return axios.get<TwitSerialized[]>(`${API_URL}/api/twits?order[created]=DESC&page=${page}&language.slug=PL`)
    }

    if(listType === 'by-source'){
        return axios.get<TwitSerialized[]>(`${API_URL}/api/twits?order[created]=DESC&page=${page}&source.handle=${sourceHandle}&language.slug=PL`)
    }
    if(listType === 'by-tag'){
        return axios.get<TwitSerialized[]>(`${API_URL}/api/twits?order[created]=DESC&page=${page}&tags.slug=${tagSlug}&language.slug=PL`)
    }
    if(listType === 'by-topic'){
        return axios.get<TwitSerialized[]>(`${API_URL}/api/twits?order[created]=DESC&page=${page}&tags.category.slug=${topicSlug}&language.slug=PL`)
    }

    return axios.get<TwitSerialized[]>(`${API_URL}/api/twits?order[created]=DESC&page=${page}&language.slug=PL`)
}

export function patchTwitUpvote(twit: TwitSerialized) {
    return axios.post(`${API_URL}/rest/api/twits/${twit.id}/upvote`, {
        'twitId': twit.id
    })
}

export function isTwitBookmark(twit: TwitSerialized) {
    return axios.get(`${API_URL}/rest/api/twits/${twit.id}/bookmark`)
}

export function isTwitUpvoted(twit: TwitSerialized) {
    return axios.get(`${API_URL}/rest/api/twits/${twit.id}/upvoted`)
}

export function postTwitBookmark(twit: TwitSerialized) {
    return axios.post(`${API_URL}/rest/api/users/me/bookmarks`, {
        'twitId': twit.id
    })
}

export function patchVerifyTwits(twits: TwitSerialized[]) {
    let ids: string[] = [];

    twits.map((twit)=> {
        ids.push(twit.id);
    })

    return axios.post(`${API_URL}/rest/api/users/me/verify-twits`, {
        'twits': ids
    })
}
