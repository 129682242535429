/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  EngageWidget10,
} from '../../../_metronic/partials/widgets'
import {ProfileHeader} from "../../modules/profile/ProfileHeader";
import {OnboardingSteps} from "./components/OnboardingSteps";
import {OnboardingWizard} from "../../modules/wizards/onboarding/OnboardingWizard";
import {useAuth} from "../../modules/auth";
import {ResumeUploader} from "./components/ResumeUploader";
import {DefineQuestionnaire} from "./components/DefineQuestionnaire";
import {FeaturedProjects} from "./components/FeaturedProjects";
import {Proposals} from "./components/Proposals";

const DashboardPage: FC = () => {
  const {currentUser} = useAuth()

  return (
      <>
        <div className='g-5 g-xl-10 mb-5 mb-xl-10'>
          <ProfileHeader user={currentUser} />

          {currentUser?.onboarding.currentStep !== null && <OnboardingSteps className='' color='primary' />}
          {currentUser?.onboarding.currentStep === 'skills' && <OnboardingWizard />}
          {currentUser?.onboarding.currentStep === 'resumeUpload' && <ResumeUploader />}
          {currentUser?.onboarding.currentStep === 'questionnaire' && <DefineQuestionnaire />}
          {currentUser?.onboarding.currentStep === null && <Proposals />}
          {/*<FeaturedProjects />*/}


          {/*<div className="alert alert-primary d-flex align-items-center p-5 mb-10">*/}
          {/*  <span className="svg-icon svg-icon-2hx svg-icon-primary me-3">*/}
          {/*    <i className="bi bi-shield-fill-check"/>*/}
          {/*  </span>*/}

          {/*  <div className="d-flex flex-column">*/}
          {/*    <h5 className="mb-1">This is an alert</h5>*/}
          {/*    <span>The alert component can be used to highlight certain parts of your page for higher content visibility.</span>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>*/}
          {/*  <CardsWidget20*/}
          {/*    className='h-md-50 mb-5 mb-xl-10'*/}
          {/*    description='Active Projects'*/}
          {/*    color='#F1416C'*/}
          {/*    img={toAbsoluteUrl('/media/patterns/vector-1.png')}*/}
          {/*  />*/}
          {/*  <CardsWidget7*/}
          {/*    className='h-md-50 mb-5 mb-xl-10'*/}
          {/*    description='Professionals'*/}
          {/*    icon={false}*/}
          {/*    stats={357}*/}
          {/*    labelColor='dark'*/}
          {/*    textColor='gray-300'*/}
          {/*  />*/}
          {/*</div>*/}

          {/*<div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>*/}
          {/*  <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />*/}
          {/*</div>*/}

          {/*<div className='col-xxl-6'>*/}
          {/*  <EngageWidget10 className='h-md-100' />*/}
          {/*</div>*/}
        </div>

        {/*<div className='row gx-5 gx-xl-10'>*/}
        {/*  <div className='col-xxl-6 mb-5 mb-xl-10'>*/}
        {/*    /!* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> *!/*/}
        {/*  </div>*/}

        {/*  <div className='col-xxl-6 mb-5 mb-xl-10'>*/}
        {/*    /!* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> *!/*/}
        {/*  </div>*/}
        {/*</div>*/}
      </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
