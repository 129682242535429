/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import LinkedInPage from "../modules/auth/LinkedInPage";
import {QuestionnairePage} from "../modules/questionnaire/QuestionnairePage";
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {HomePage} from "../modules/home/HomePage";
import {TwitsPage} from "../modules/twits/TwitsPage";
import {TwitPage} from "../modules/twits/TwitPage";
import {ReferralPage} from "../modules/referral/ReferralPage";
import {PolicyPrivacyPage} from "../modules/policy-privacy/PolicyPrivacyPage";
import {AboutPremiumPage} from "../modules/about-premium/AboutPremiumPage";
import {AboutProPage} from "../modules/about-pro/AboutProPage";

const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='linkedin' element={<LinkedInPage />} />
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              <Route path='auth/*' element={<Navigate to='/crm/dashboard' />} />
              <Route path='crm/*' element={<PrivateRoutes />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='crm/*' element={<Navigate to='/auth' />} />
            </>
          )}
          <Route element={<MasterLayout noTopPadding={true} />}>
            {/*<Route path='/' element={<TwitsPage listType={'trending'} />} />*/}
            <Route path='/' element={<HomePage />} />
            <Route path='/intro' element={<HomePage />} />
            <Route path='/referral' element={<ReferralPage />} />
            <Route path='/about-premium' element={<AboutPremiumPage />} />
            <Route path='/about-pro' element={<AboutProPage />} />
            <Route path='/policy' element={<PolicyPrivacyPage />} />
            <Route path='news/trending' element={<TwitsPage listType={'trending'} />} />
            <Route path='news/upvoted' element={<TwitsPage listType={'upvoted'} />} />
            {/*<Route path='news/bookmarks' element={<TwitsPage listType={'bookmarks'} />} />*/}
            <Route path='news/:twitSlug' element={<TwitPage />} />

            <Route path='sources/:sourceHandle' element={<TwitsPage listType={'by-source'} />} />
            <Route path='tags/:tagSlug' element={<TwitsPage listType={'by-tag'} />} />
            <Route path='topics/:topicSlug' element={<TwitsPage listType={'by-topic'} />} />
          </Route>
          <Route element={<MasterLayout noPadding={true} />}>
            <Route path='*' element={<QuestionnairePage />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
