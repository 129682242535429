import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {useAuth} from "../../../../../app/modules/auth";
import React from "react";

export function MenuInner() {
    const intl = useIntl()
    const {currentUser} = useAuth()

    return (
        <>
            {currentUser ? (
                <MenuItem to='/crm/dashboard' title={intl.formatMessage({id: 'MENU.DASHBOARD'})} fontIcon='bi-app-indicator' />
            ) : (
                <>
                    <MenuItem to='' title={intl.formatMessage({id: 'MENU.HOMEPAGE'})} />
                    <MenuItem to='/intro' title={intl.formatMessage({id: 'MENU.INTRO.JOIN_US'})} fontIcon='bi-app-indicator' />
                </>
            )}

            <MenuItem to='/news/trending' title={intl.formatMessage({id: 'MENU.DISCOVER.TRENDING_NEWS'})} fontIcon='bi-fire'/>
            <MenuItem to='/about-premium' title={intl.formatMessage({id: 'MENU.DISCOVER.ACCOUNT_PREMIUM'})} fontIcon='bi-speedometer2'/>
            <MenuItem to='/about-pro' title={intl.formatMessage({id: 'MENU.DISCOVER.ACCOUNT_PRO'})} fontIcon='bi-shield-check'/>
            <MenuItem to='/referral' title={intl.formatMessage({id: 'MENU.REFERRAL.INVITE'})} fontIcon='bi-person-plus'/>
        </>
      )
}
