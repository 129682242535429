import {useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'
import {Step4} from './steps/Step4'
import {Step5} from './steps/Step5'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {Formik, Form, FormikValues} from 'formik'
import {getUserByToken} from "../../auth/core/_requests";
import {getExtraSkills, getLanguagesSkills} from './core/_requests'
import {useAuth} from "../../auth";

import axios from 'axios'
import {useIntl} from "react-intl";
import {SkillModel} from "./core/_models";
import * as Yup from "yup";
const API_URL = process.env.REACT_APP_API_URL
export const UPDATE_USER_URL = `${API_URL}/rest/api/users/me`

export interface ICreateAccount {
  availability: string
  leadingRole: string
  experience: string
  skills: string[]
  extraSkills: []
  ratePerHour: number
  rateCurrency: string
}

const OnboardingWizard = () => {
  const intl = useIntl()
  const {setCurrentUser, currentUser} = useAuth()

  const inits: ICreateAccount = {
    availability: currentUser?.availability ? currentUser?.availability : '',
    leadingRole: currentUser?.leadingRole ? currentUser?.leadingRole : '',
    experience: currentUser?.experience ? currentUser?.experience : '',
    skills: [],
    extraSkills: [],
    ratePerHour: currentUser?.ratePerHour ? currentUser?.ratePerHour : 100,
    rateCurrency: currentUser?.rateCurrencyKey ? currentUser?.rateCurrencyKey : 'PLN'
  }

  // console.log(inits);

  const createAccountSchemas = [
    Yup.object({
      availability: Yup.number()
          .required(intl.formatMessage({id: 'ONBOARDING_WIZARD.AVAILABILITY.ERROR.REQUIRED'}))
    }),
    Yup.object({
      leadingRole: Yup.string()
          .required(intl.formatMessage({id: 'ONBOARDING_WIZARD.LEADING_ROLE.ERROR.REQUIRED'})),
      experience: Yup.string()
          .required(intl.formatMessage({id: 'ONBOARDING_WIZARD.EXPERIENCE.ERROR.REQUIRED'}))
    }),
    Yup.object({
      skills: Yup.array()
          .required(intl.formatMessage({id: 'ONBOARDING_WIZARD.SKILLS.ERROR.REQUIRED'}))
          .min(1, intl.formatMessage({id: 'ONBOARDING_WIZARD.SKILLS.ERROR.MIN'}))
          .max(3, intl.formatMessage({id: 'ONBOARDING_WIZARD.SKILLS.ERROR.MAX'}))
      ,
    }),
    Yup.object({

    }),
    Yup.object({
      ratePerHour: Yup.number()
          .required(intl.formatMessage({id: 'ONBOARDING_WIZARD.RATE_PER_HOUR.ERROR.REQUIRED'})),
      rateCurrency: Yup.string()
          .required().label('Rate currency'),
    }),
  ]

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [loading, setLoading] = useState(false)

  const [skills, setSkills] = useState<SkillModel[]>([]);
  const [extraSkills, setExtraSkills] = useState<SkillModel[]>([]);

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) return

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  const updateUser = (values: ICreateAccount) => {
    return axios.post(UPDATE_USER_URL, values)
  }

  const submitStep = async (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) return

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {

      if(stepper.current.currentStepIndex === 2){
        setLoading(true)
        const response = await getLanguagesSkills();
        setSkills(response.data);
        setLoading(false)
      }

      if(stepper.current.currentStepIndex === 3){
        setLoading(true)
        const response = await getExtraSkills(values.skills);
        setExtraSkills(response.data);
        setLoading(false)
      }

      stepper.current.goNext()
    } else {
      setLoading(true)
      try {
        const {data: auth} = await updateUser(values)
        const {data: user} = await getUserByToken(auth.token)
        setCurrentUser(user)
        setLoading(false)
        stepper.current.goto(1)
      } catch (error) {
        console.error(error)
        setLoading(false)
        stepper.current.goto(1)
        actions.resetForm()
      }
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid mt-0'
      id='kt_create_account_stepper'
    >
      <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-250px w-xxl-250px me-4'>
        <div className='card-body px-7'>
          <div className='stepper-nav'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper'>
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'/><span className='stepper-number'>1</span>
                </div>
                <div className='stepper-label'>
                  <h3 className='stepper-title'>
                    {intl.formatMessage({id: 'ONBOARDING.AVAILABILITY'})}
                  </h3>
                  {/*<div className='stepper-desc fw-semibold'>..do you work in IT?</div>*/}
                </div>
              </div>
              <div className='stepper-line h-40px'/>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper'>
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'/><span className='stepper-number'>2</span>
                </div>
                <div className='stepper-label'>
                  <h3 className='stepper-title'>
                    {intl.formatMessage({id: 'ONBOARDING.LEADING_ROLE'})} & {intl.formatMessage({id: 'ONBOARDING.EXPERIENCE'})}
                  </h3>
                  {/*<div className='stepper-desc fw-semibold'>Setup Your Account Settings</div>*/}
                </div>
              </div>
              <div className='stepper-line h-40px'/>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper'>
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'/><span className='stepper-number'>3</span>
                </div>
                <div className='stepper-label'>
                  <h3 className='stepper-title'>
                    {intl.formatMessage({id: 'ONBOARDING.SKILLS'})}
                  </h3>
                  {/*<div className='stepper-desc fw-semibold'>Your Business Related Info</div>*/}
                </div>
              </div>
              <div className='stepper-line h-40px'/>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper'>
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'/><span className='stepper-number'>4</span>
                </div>
                <div className='stepper-label'>
                  <h3 className='stepper-title'>
                    {intl.formatMessage({id: 'ONBOARDING.EXTRA_SKILLS'})}
                  </h3>
                  {/*<div className='stepper-desc fw-semibold'>Set Your Payment Methods</div>*/}
                </div>
              </div>
              <div className='stepper-line h-40px'/>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper'>
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'/><span className='stepper-number'>5</span>
                </div>
                <div className='stepper-label'>
                  <h3 className='stepper-title'>
                    {intl.formatMessage({id: 'ONBOARDING.SALARY'})}
                  </h3>
                  {/*<div className='stepper-desc fw-semibold'>Woah, we are here</div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
        <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
          {() => (
            <Form className='py-20 w-100 px-9' noValidate id='kt_create_account_form'>
              <div className='current' data-kt-stepper-element='content'>
                <Step1 />
              </div>

              <div data-kt-stepper-element='content'>
                <Step2 />
              </div>

              <div data-kt-stepper-element='content'>
                <Step3 skills={skills} />
              </div>

              <div data-kt-stepper-element='content'>
                <Step4 extraSkills={extraSkills} />
              </div>

              <div data-kt-stepper-element='content'>
                <Step5 />
              </div>

              <div className='d-flex flex-stack pt-10'>
                <div className='mr-2'>
                  {!loading &&
                    <button onClick={prevStep} type='button' className='btn btn-lg btn-light-primary me-3' data-kt-stepper-action='previous'>
                      <KTSVG path='/media/icons/duotune/arrows/arr063.svg' className='svg-icon-4 me-1'/> {intl.formatMessage({id: 'ONBOARDING.BACK'})}
                    </button>
                  }
                </div>

                <div>
                  {!loading &&
                    <button type='submit' className='btn btn-lg btn-primary me-3'>
                      <span className='indicator-label'>
                        {stepper.current?.currentStepIndex !== stepper.current?.totatStepsNumber! && (intl.formatMessage({id: 'ONBOARDING.NEXT'}))}
                        {stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! && (intl.formatMessage({id: 'ONBOARDING.SUBMIT'}))}
                        <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-3 ms-2 me-0'/>
                      </span>
                    </button>
                  }
                  {loading && <span className="spinner-border text-primary" role="status"/>}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export {OnboardingWizard}
