import {FC} from 'react'
import {Field, ErrorMessage} from 'formik'
import {useIntl} from "react-intl";

const Step1: FC = () => {
  const intl = useIntl()

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          {intl.formatMessage({id: 'ONBOARDING.AVAILABILITY'})}
        </h2>

        <div className='text-gray-400 fw-bold fs-6'>
          {intl.formatMessage({id: 'ONBOARDING.AVAILABILITY.DESCRIPTION'})}
        </div>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-4'>
            <Field
              type='radio'
              className='btn-check'
              name='availability'
              value='1'
              id='kt_create_account_form_account_type_personal'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary p-7 d-flex align-items-center mb-10'
              htmlFor='kt_create_account_form_account_type_personal'
            >
              <i className="bi bi-emoji-sunglasses fs-1 me-5"/>
              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                  {intl.formatMessage({id: 'ONBOARDING.AVAILABILITY.FULLY_AVAILABLE'})}
                </span>
                <span className='text-gray-400 fw-bold fs-6'>
                  {intl.formatMessage({id: 'ONBOARDING.AVAILABILITY.FULLY_AVAILABLE.DESCRIPTION'})}
                </span>
              </span>
            </label>
          </div>

          <div className='col-lg-4'>
            <Field
              type='radio'
              className='btn-check'
              name='availability'
              value='2'
              id='kt_create_account_form_account_type_corporate'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary p-7 d-flex align-items-center'
              htmlFor='kt_create_account_form_account_type_corporate'
            >
              <i className="bi bi-emoji-smile fs-1 me-5"/>
              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                  {intl.formatMessage({id: 'ONBOARDING.AVAILABILITY.PARTLY_AVAILABLE'})}
                </span>
                <span className='text-gray-400 fw-bold fs-6'>
                  {intl.formatMessage({id: 'ONBOARDING.AVAILABILITY.PARTLY_AVAILABLE.DESCRIPTION'})}
                </span>
              </span>
            </label>
          </div>

          <div className='col-lg-4'>
            <Field
              type='radio'
              className='btn-check'
              name='availability'
              value='0'
              id='kt_create_account_form_account_type_not_available'
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary p-7 d-flex align-items-center'
              htmlFor='kt_create_account_form_account_type_not_available'
            >
              <i className="bi bi-emoji-neutral fs-1 me-5"/>
              <span className='d-block fw-bold text-start'>
                <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                  {intl.formatMessage({id: 'ONBOARDING.AVAILABILITY.NOT_AVAILABLE'})}
                </span>
                <span className='text-gray-400 fw-bold fs-6'>
                  {intl.formatMessage({id: 'ONBOARDING.AVAILABILITY.NOT_AVAILABLE.DESCRIPTION'})}
                </span>
              </span>
            </label>
          </div>

          <div className='text-danger mt-2'>
            <ErrorMessage name='availability' />
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step1}
