import React, {useEffect, useRef, useState} from 'react'
import axios from "axios";
import {JobRoleModel} from "../../../modules/auth";
import {SkillModel} from "../../../modules/wizards/onboarding/core/_models";
import {ProposalCard} from "../../../modules/proposals/components/ProposalCard";
import {ICreateAccount} from "../../../modules/wizards/onboarding/OnboardingWizard";

const API_URL = process.env.REACT_APP_API_URL

export function getMyProposals() {
  return axios.get<ProposalSerialized[]>(`${API_URL}/rest/api/users/me/proposals`)
}

export interface ProposalSerialized {
  uuid: string
  answers: ProposalAnswerSerialized
}

export interface ProposalAnswerSerialized {
  companyName: string
  companyDescription: string
  benefits: BenefitSerialized[]
  projectDescription: string
  englishMandatory: boolean
  leadingRole: JobRoleModel | null
  skills: SkillModel[]
  experience: string
  teamDescription: string
  candidateResponsibility: string
  workType: string
  recruiterPosition: string
  recruiterEmail: string
  recruiterAlternativeContact: string
  recruiterType: string
  dedicatedOffer: number | null
  budgetFrom: number
  budgetTo: number
}

export interface BenefitSerialized {
  name: string
}

const Proposals = () => {
  const [loading, setLoading] = useState(false)
  const [proposals, setProposals] = useState<ProposalSerialized[]>([]);
  const [sampleProposals, setSampleProposals] = useState<boolean>(false);

  const fillSampleProposals = () => {
    const temp: ProposalSerialized[] = [
      {
        "uuid": "01874506-32da-7222-a30c-541e5ea53455",
        "answers": {
          "companyName": "Przykładowa firma",
          "companyDescription": "Przykładowa firma to zaawansowany technologicznie FinTech, o profilu działalności Domu Maklerskiego.",
          "benefits": [
            {"name": "health insurance"},
            {"name": "compensation for language courses"},
            {"name": "paid conferences"},
            {"name": "compensation for gym membership"},
            {"name": "corporate events"}
          ],
          "projectDescription": "Szukamy osoby na stanowisko Frontend Developera, która dołączy do zespołu rozwijającego frontowe aplikacje tradingowe, budowane w oparciu o framework Angular.",
          "englishMandatory": true,
          "leadingRole": {
            "name": "Frontend",
            "slug": "frontend-developer",
            "id": "01873435-e015-7c01-be75-69530b816e63"
          },
          "skills": [
            {
              "name": "PHP",
              "slug": "php",
              "type": 1,
              "svgIcon": "devicon-php-plain",
              "colorClass": "skill-color-3"
            },
          ],
          "experience": "lead",
          "teamDescription": "team_description_3",
          "candidateResponsibility": "candidate_responsibility_2",
          "workType": "work_type_remote",
          "recruiterPosition": "",
          "recruiterEmail": "rekruter@zdalny.dev",
          "recruiterAlternativeContact": "",
          "recruiterType": "recruiter_type_in_house",
          "dedicatedOffer": 12000,
          "budgetFrom": 8000,
          "budgetTo": 18000,
        }
      },
      {
        "uuid": "01874f53-aeec-7734-aac3-c799da5dee20",
        "answers": {
          "companyName": "Przykładowa firma",
          "companyDescription": "Przykładowa firma to zaawansowany technologicznie FinTech, o profilu działalności Domu Maklerskiego.",
          "benefits": [
            {"name": "health insurance"},
            {"name": "compensation for language courses"},
            {"name": "paid conferences"},
            {"name": "compensation for gym membership"},
            {"name": "corporate events"}
          ],
          "projectDescription": "Szukamy osoby na stanowisko Frontend Developera, która dołączy do zespołu rozwijającego frontowe aplikacje tradingowe, budowane w oparciu o framework Angular.",
          "englishMandatory": true,
          "leadingRole": {
            "name": "Frontend",
            "slug": "frontend-developer",
            "id": "01873435-e015-7c01-be75-69530b816e63"
          },
          "skills": [
            {
              "name": "PHP",
              "slug": "php",
              "type": 1,
              "svgIcon": "devicon-php-plain",
              "colorClass": "skill-color-3"
            },
          ],
          "experience": "lead",
          "teamDescription": "team_description_3",
          "candidateResponsibility": "candidate_responsibility_2",
          "workType": "work_type_remote",
          "recruiterPosition": "",
          "recruiterEmail": "rekruter@zdalny.dev",
          "recruiterAlternativeContact": "",
          "recruiterType": "recruiter_type_in_house",
          "dedicatedOffer": 12000,
          "budgetFrom": 8000,
          "budgetTo": 18000,
        }
      },
      {
        "uuid": "01874f64-1924-7da5-a1e8-ddef2cc6c62e",
        "answers": {
          "companyName": "Przykładowa firma",
          "companyDescription": "Przykładowa firma to zaawansowany technologicznie FinTech, o profilu działalności Domu Maklerskiego.",
          "benefits": [
            {"name": "health insurance"},
            {"name": "compensation for language courses"},
            {"name": "paid conferences"},
            {"name": "compensation for gym membership"},
            {"name": "corporate events"}
          ],
          "projectDescription": "Szukamy osoby na stanowisko Frontend Developera, która dołączy do zespołu rozwijającego frontowe aplikacje tradingowe, budowane w oparciu o framework Angular.",
          "englishMandatory": true,
          "leadingRole": {
            "name": "Frontend",
            "slug": "frontend-developer",
            "id": "01873435-e015-7c01-be75-69530b816e63"
          },
          "skills": [
            {
              "name": "PHP",
              "slug": "php",
              "type": 1,
              "svgIcon": "devicon-php-plain",
              "colorClass": "skill-color-3"
            },
          ],
          "experience": "lead",
          "teamDescription": "team_description_3",
          "candidateResponsibility": "candidate_responsibility_2",
          "workType": "work_type_remote",
          "recruiterPosition": "",
          "recruiterEmail": "rekruter@zdalny.dev",
          "recruiterAlternativeContact": "",
          "recruiterType": "recruiter_type_in_house",
          "dedicatedOffer": 12000,
          "budgetFrom": 8000,
          "budgetTo": 18000,
        }
      }
    ];

    setProposals(temp);
  };

  useEffect(() => {
    setLoading(true)

    const loadProposals = async () => {
      const response = await getMyProposals();
      setProposals(response.data);
      if(response.data.length === 0){
        fillSampleProposals();
        setSampleProposals(true);
      }else{
        setSampleProposals(false);
      }

      setLoading(false)
    }

    loadProposals().catch(() => {
      setLoading(false)
    });
  }, [])

  return (
      <div>
        {loading &&
          <div className='mw-500px px-1 py-3 m-auto text-center'>
            <span className="spinner-border text-primary" role="status"/>
          </div>
        }

        <br />
        <div className='row g-6 g-xl-9'>
          {(!loading && proposals) &&
              <>
                {/*<ul className="pagination">*/}
                {/*  <li className="page-item previous disabled"><a href="#" className="page-link"><i className="previous"></i></a></li>*/}
                {/*  <li className="page-item "><a href="#" className="page-link">1</a></li>*/}
                {/*  <li className="page-item active"><a href="#" className="page-link">2</a></li>*/}
                {/*  <li className="page-item "><a href="#" className="page-link">3</a></li>*/}
                {/*  <li className="page-item next"><a href="#" className="page-link"><i className="next"></i></a></li>*/}
                {/*</ul>*/}
                {proposals.map((proposal, key) => {
                  return (
                      <div className='col-md-6 col-xl-6 col-xxl-4' key={key}>
                        <ProposalCard
                            badgeColor='primary'
                            statusColor='success'
                            progress={75}
                            proposal={proposal}
                            sample={sampleProposals}
                        />
                      </div>
                  )
                })}
              </>
          }
        </div>
      </div>
  )
}

export {Proposals}
