import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {PageTitle} from "../../../_metronic/layout/core";

// @ts-ignore
import { Helmet } from "react-helmet";
import {Projects} from "../projects/components/Projects";
import {Link} from "react-router-dom";
import {ProjectsChart} from "../projects/components/ProjectsChart";
import {ProjectCard} from "../projects/components/ProjectCard";

const TasksPage = () => {
    const intl = useIntl()

    return (
        <>
            <Helmet>
                <title>{intl.formatMessage({id: 'MENU.TASKS'})} | {intl.formatMessage({id: 'META.GENERAL.TITLE'})}</title>
            </Helmet>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.TASKS'})}</PageTitle>

            <div className="row g-6 g-xl-9">
                {/*<div className="col-md-6 col-xl-4">*/}
                {/*    <ProjectsChart numPrimary={7} numSuccess={10} />*/}
                {/*</div>*/}
                <div className="col-md-6 col-xl-6">

                    {/*IF currentUser isPro -> pokazywać jego reputację*/}
                    {/*IF currentUser !isPro -> pokazywać boxa z informacjami*/}

                    <div className="card border-0 w-100 h-md-100 ribbon ribbon-top" style={{background: 'linear-gradient(112.14deg, #D57B3A 0%, #FFD200 100%)'}}>
                        <div className="card-body">
                            <div className="ribbon-label bg-success">Dostępna dla konta &#160;<i className='bi bi-shield-check text-white me-1'/><strong>PRO</strong></div>
                            <div className="align-items-center h-100">
                                <div className="text-white mb-4 pt-4">
                                    {/*<span className="fs-4 fw-semibold me-2 d-block lh-1 pb-2 opacity-75">Zaczniesz dziś - kwalifikujesz się do otrzymania Airdrop!</span>*/}
                                    <span className="fs-2qx fw-bold">Aplikuj bez rekrutacji</span>
                                </div>
                                <div className="fw-semibold text-white fs-6 mb-8 d-block opacity-75">
                                    Jako jedyna platforma w Polsce umożliwiamy Ci dołączanie do projektów IT bez rozmowy o pracę - na bazie Twojego potwierdzonego CV, które budujesz, zdobywając odpowiednie punkty reputacji.
                                    {/*<ul>*/}
                                    {/*    <li>Generuj dedykowane linki polecające, przesyłaj je znajomym. </li>*/}
                                    {/*    <li>Otrzymuj natychmiastową wypłatę w BTC oraz naszych tokenach.</li>*/}
                                    {/*</ul>*/}
                                </div>
                                <div className="d-flex align-items-center flex-wrap d-grid gap-2 mb-10">
                                    <div className="d-flex align-items-center me-5">
                                        <div className="symbol symbol-30px symbol-circle me-3">
                                            <span className="symbol-label" style={{backgroundColor: '#FFC735'}}><i className="bi-magic fs-5 text-white"/></span>
                                        </div>
                                        <div className="text-white">
                                            <span className="fw-semibold d-block fs-8 opacity-75">Automatyczne umowy</span>
                                            <span className="fw-bold fs-7">Smart kontrakt</span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="symbol symbol-30px symbol-circle me-3">
                                            <span className="symbol-label" style={{backgroundColor: '#FFC735'}}><i className="bi-diagram-3 fs-5 text-white"/></span>
                                        </div>
                                        <div className="text-white">
                                            <span className="fw-semibold opacity-75 d-block fs-8">Płatności instant</span>
                                            <span className="fw-bold fs-7">Blockchain</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column flex-sm-row d-grid gap-2">
                                    {/*<Link type='button' className='btn btn-sm btn-success me-2 hover-scale' style={{background: 'rgba(255, 255, 255, 0.2)'}} to={'/about-pro'}>*/}
                                    {/*    <i className='bi bi-info'/>*/}
                                    {/*    Czytaj więcej*/}
                                    {/*</Link>*/}
                                    {/*<button type='button' className='btn btn-lg btn-primary me-2 hover-scale' style={{background: 'rgba(255, 255, 255, 0.2)'}} onClick={() => setLoginOpened(true)}>*/}
                                    {/*    Czytaj Whitepaper*/}
                                    {/*</button>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xl-6">
                    <div className="card h-100">
                        <div className="card-body p-9">
                            <div className="fs-2hx fw-bold">27</div>
                            <div className="fs-4 fw-semibold text-gray-400 mb-7">
                                {intl.formatMessage({id: 'PROJECTS.STATS.ACTIVE_CONTRACTS'})}
                            </div>
                            <div className="fs-6 d-flex justify-content-between mb-4">
                                <div className="fw-semibold">
                                    {intl.formatMessage({id: 'PROJECTS.STATS.CONTRACTS_PER_USER'})}
                                </div>
                                <div className="d-flex fw-bold">
                                    $6,570&#160;
                                    <span className="svg-icon svg-icon-3 me-1 svg-icon-success">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.4 10L5.3 18.1C4.9 18.5 4.9 19.1 5.3 19.5C5.7 19.9 6.29999 19.9 6.69999 19.5L14.8 11.4L13.4 10Z" fill="currentColor"/><path opacity="0.3" d="M19.8 16.3L8.5 5H18.8C19.4 5 19.8 5.4 19.8 6V16.3Z" fill="currentColor"/></svg>
                                    </span>
                                </div>
                            </div>
                            <div className="separator separator-dashed"/>
                            <div className="fs-6 d-flex justify-content-between my-4">
                                <div className="fw-semibold">
                                    {intl.formatMessage({id: 'PROJECTS.STATS.CONTRACT_PER_USER'})}
                                </div>
                                <div className="d-flex fw-bold">
                                    $408&#160;
                                    <span className="svg-icon svg-icon-3 me-1 svg-icon-danger">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.4 14.8L5.3 6.69999C4.9 6.29999 4.9 5.7 5.3 5.3C5.7 4.9 6.29999 4.9 6.69999 5.3L14.8 13.4L13.4 14.8Z" fill="currentColor"/><path opacity="0.3" d="M19.8 8.5L8.5 19.8H18.8C19.4 19.8 19.8 19.4 19.8 18.8V8.5Z" fill="currentColor"/></svg>
                                    </span>
                                </div>
                            </div>
                            <div className="separator separator-dashed"/>
                            <div className="fs-6 d-flex justify-content-between mt-4">
                                <div className="fw-semibold">
                                    {intl.formatMessage({id: 'PROJECTS.STATS.TOKENS_PER_USER'})}
                                </div>
                                <div className="d-flex fw-bold">
                                    920&#160;
                                    <span className="svg-icon svg-icon-3 me-1 svg-icon-success">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.4 10L5.3 18.1C4.9 18.5 4.9 19.1 5.3 19.5C5.7 19.9 6.29999 19.9 6.69999 19.5L14.8 11.4L13.4 10Z" fill="currentColor"/><path opacity="0.3" d="M19.8 16.3L8.5 5H18.8C19.4 5 19.8 5.4 19.8 6V16.3Z" fill="currentColor"/>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className='d-flex flex-wrap flex-stack mb-6 mt-6'>
                    <h3 className='fw-bolder my-2'>
                        Lista otwartych zadań
                        {/*<span className='fs-6 text-gray-400 fw-bold ms-1'>Active</span>*/}
                    </h3>

                    <div className='d-flex flex-wrap my-2'>
                        <div className='me-4'>
                            <select
                                name='status'
                                data-control='select2'
                                data-hide-search='true'
                                className='form-select form-select-sm form-select-white w-125px'
                                defaultValue='all'
                            >
                                <option value='all'>Wszystkie</option>
                                <option value='new' disabled={true}>Nowe</option>
                            </select>
                        </div>
                    </div>
                </div>

                <>
                    <div className='alert alert-primary'>
                        Lista dostępnych zadań jest pusta. Nie posiadasz obecnie dostępu do wersji PRO, która pozwala na bezpośrednie dołączanie do projektów i realizacji zadań.
                    </div>
                </>
            </div>
        </>
    );
}

export {TasksPage}
