/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef} from 'react'
import {KTSVG} from '../../../../helpers'
import {getCSSVariableValue} from '../../../../assets/ts/_utils'
import {useThemeMode} from '../../../layout/theme-mode/ThemeModeProvider'
import {useAuth} from "../../../../../app/modules/auth";

type Props = {
  className: string
  chartSize?: number
  chartLine?: number
  chartRotate?: number
}

const CardsWidget17: FC<Props> = ({
  className,
  chartSize = 90,
  chartLine = 10,
  chartRotate = 0,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const {currentUser} = useAuth()

  useEffect(() => {
    refreshChart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    setTimeout(() => {
      initChart(chartSize, chartLine, chartRotate, currentUser?.referralTokens.available, currentUser?.referralTokens.awaiting)
    }, 10)
  }

  return (
    <div className={`card card-flush ${className} ribbon ribbon-top`}>
      <div className='card-header pt-5'>
        <div className="ribbon-label bg-dark cursor-pointer" onClick={() => {}}>
          <i className='bi bi-coin text-white me-1' />
          ZDEV
        </div>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <span className='text-gray-400 me-1'><i className="bi fs-2 bi-coin"/></span>
            <span className='fs-2hx text-dark me-2 lh-1 ls-n2'>{currentUser?.referralTokens.total}</span>

            {/*<button  className="btn btn-sm btn-light-success ms-2 fs-8 py-1 px-4">*/}
            {/*  <i className='bi bi-cart2' /> Kup więcej*/}
            {/*</button>*/}
          </div>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>Tokeny uzyskane z poleceń/zaproszeń</span>
        </div>
      </div>

      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
        <div className='d-flex flex-center me-5'>
          <div id='kt_card_widget_17_chart' ref={chartRef} style={{minWidth: chartSize + 'px', minHeight: chartSize + 'px'}} data-kt-size={chartSize} data-kt-line={chartLine}/>
        </div>

        <div className='d-flex flex-column content-justify-center flex-row-fluid'>
          <div className='d-flex fw-semibold align-items-center'>
            <div className='bullet w-8px h-3px rounded-2 bg-success me-3'/>
            <div className='text-gray-500 flex-grow-1 me-4'>Dostępne</div>
            <div className='fw-bolder text-gray-700 text-xxl-end'>{currentUser?.referralTokens.available}</div>
          </div>
          <div className='d-flex fw-semibold align-items-center my-3'>
            <div className='bullet w-8px h-3px rounded-2 bg-primary me-3'/>
            <div className='text-gray-500 flex-grow-1 me-4'>Airdrop (oczekujące)</div>
            <div className='fw-bolder text-gray-700 text-xxl-end'>{currentUser?.referralTokens.awaiting}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const initChart = function (
  chartSize: number = 50,
  chartLine: number = 11,
  chartRotate: number = 145,
  available: number = 0,
  awaiting: number = 0
) {
  const el = document.getElementById('kt_card_widget_17_chart')
  if (!el) { return }
  el.innerHTML = ''

  var options = {
    size: chartSize,
    lineWidth: chartLine,
    rotate: chartRotate,
    //percent:  el.getAttribute('data-kt-percent') ,
  }

  const canvas = document.createElement('canvas')
  const span = document.createElement('span')

  // @ts-ignore
  if (typeof G_vmlCanvasManager !== 'undefined') {
    // @ts-ignore
    G_vmlCanvasManager.initElement(canvas)
  }

  const ctx = canvas.getContext('2d')
  canvas.width = canvas.height = options.size

  el.appendChild(span)
  el.appendChild(canvas)

  // @ts-ignore
  ctx.translate(options.size / 2, options.size / 2) // change center
  // @ts-ignore
  ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

  //imd = ctx.getImageData(0, 0, 240, 240);
  const radius = (options.size - options.lineWidth) / 2

  const drawCircle = function (color: string, lineWidth: number, percent: number) {
    percent = Math.min(Math.max(0, percent || 1), 1)
    if (!ctx) {
      return
    }

    ctx.beginPath()
    ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
    ctx.strokeStyle = color
    ctx.lineCap = 'round' // butt, round or square
    ctx.lineWidth = lineWidth
    ctx.stroke()
  }

  if(available === 0 && awaiting === 0){
    drawCircle('#E4E6EF', options.lineWidth, 100/100)
  }else{
    let totalTokens = available + awaiting;
    if(totalTokens > 0){
      if(available === 0 && awaiting === 0){
        drawCircle('#E4E6EF', options.lineWidth, 100/100)
      }

      if(available > 0 && awaiting === 0){
        drawCircle(getCSSVariableValue('--kt-success'), options.lineWidth, 100/100)
      }

      if(available === 0 && awaiting > 0){
        drawCircle(getCSSVariableValue('--kt-primary'), options.lineWidth, 100/100)
      }

      if(available > 0 && awaiting > 0){
        if(available >= awaiting){
          drawCircle(getCSSVariableValue('--kt-success'), options.lineWidth, 100/100)
          drawCircle(getCSSVariableValue('--kt-primary'), options.lineWidth, awaiting/totalTokens)
        }else{
          drawCircle(getCSSVariableValue('--kt-primary'), options.lineWidth, 100/100)
          drawCircle(getCSSVariableValue('--kt-success'), options.lineWidth, available/totalTokens)
        }
      }
    }
  }
}

export {CardsWidget17}
