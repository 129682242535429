/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {UsersList} from '../../../../app/modules/profile/components/UsersList'
import {toAbsoluteUrl} from "../../../../_metronic/helpers";
import {ProjectSerialized} from "./_models";
import {useIntl} from "react-intl";

type Props = {
    project: ProjectSerialized
}

const ProjectCard: FC<Props> = ({project}) => {
    const intl = useIntl()

    const projectUsers = [
        {name: 'Łukasz Rak', avatar: '/media/nfts/6.png'},
        {name: 'Tomasz Marcińczak', avatar: '/media/nfts/9.png'},
        {name: 'Artur Warzecha', avatar: '/media/nfts/13.png'},
        {name: 'Krystian Żwak', avatar: '/media/nfts/18.png'},
    ]

    const resolveBadgeColor = () => {
        if(project?.status === 'active') return 'primary';
        if(project?.status === 'hiring') return 'success';
        if(project?.status === 'paused') return 'warning';
        if(project?.status === 'closed') return 'danger';

        return 'dark';
    }

    const resolveBudgetProcess = () => {
        if(project?.budgetSpent && project?.budgetProtected && project?.budgetProtected > 0){
            return project?.budgetSpent/project?.budgetProtected * 100;
        }

        return 0;
    }

    const resolveProgressColor = () => {
        const progress = resolveBudgetProcess();
        if(progress > 90) return 'danger';
        if(progress > 50) return 'warning';
        if(progress > 20) return 'primary';

        return 'success';
    }

    return (
        <div className='card border border-2 border-gray-300 border-hover-primary'>
            <div className='card-header border-0 pt-9'>
                <div className='card-title m-0'>
                    <div className='symbol symbol-75px w-75px bg-white'>
                        <img src={toAbsoluteUrl(project.image)} alt='card2' className='p-3' />
                    </div>
                </div>

                <div className='card-toolbar'>
                    <div className="d-flex align-items-center flex-wrap d-grid gap-2 me-5">
                        <div className="d-flex align-items-center me-5">
                            <div className="symbol symbol-30px symbol-circle me-3">
                                <img src="/media/nfts/6.png" className="" alt="" />
                            </div>
                            <div className="m-0">
                                <span className="fw-semibold text-gray-400 d-block fs-8">
                                    {intl.formatMessage({id: 'PROJECT.MANAGER'})}
                                </span>
                                <div className="fw-bold text-gray-800 text-hover-primary fs-7">
                                    Łukasz Rak
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className={`badge badge-light-${resolveBadgeColor()} fw-bolder me-auto px-4 py-3`}>
                        {intl.formatMessage({id: 'PROJECT.STATUS.' + project.status})}
                    </span>
                </div>
            </div>

            <div className='card-body p-9 pt-5'>
                <div className='fs-3 fw-bolder text-dark'>{project.name}</div>

                <p className='text-gray-400 fw-bold fs-5 mt-1 mb-7'>{project.summary}</p>

                <div className='d-flex flex-wrap mb-5'>
                    {/*<div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>*/}
                    {/*    <div className='fs-6 text-gray-800 fw-bolder'>{project.budgetPlanned} {project.budgetCurrency?.shortname}</div>*/}
                    {/*    <div className='fw-bold text-gray-400'>*/}
                    {/*        {intl.formatMessage({id: 'PROJECT.BUDGET'})}*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="d-flex align-items-center me-7">
                        <div className="symbol symbol-30px symbol-circle me-3">
                            <span className="symbol-label bg-white"><i className="bi bi-cash-coin fs-1 text-success"/></span>
                        </div>
                        <div className="m-0">
                                <span className="fw-semibold text-gray-400 d-block fs-8">
                                    {intl.formatMessage({id: 'PROJECT.BUDGET'})}
                                </span>
                            <span className="fw-bold text-gray-800 fs-7">{project.budgetPlanned} {project.budgetCurrency?.shortname}</span>
                        </div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                        <div className='fs-6 text-gray-800 fw-bolder'>{project.budgetProtected} {project.budgetCurrency?.shortname}</div>
                        <div className='fw-bold text-gray-400'>
                            {intl.formatMessage({id: 'PROJECT.BUDGET_PROTECTED'})}
                        </div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                        <div className='fs-6 text-gray-800 fw-bolder'>{project.budgetSpent} {project.budgetCurrency?.shortname}</div>
                        <div className='fw-bold text-gray-400'>
                            {intl.formatMessage({id: 'PROJECT.BUDGET_SPENT'})}
                        </div>
                    </div>
                </div>

                <div
                    className='h-4px w-100 bg-light mb-5'
                    data-bs-toggle='tooltip'
                    title='This project completed'
                >
                    <div
                        className={`bg-${resolveProgressColor()} rounded h-4px`}
                        role='progressbar'
                        style={{width: `${resolveBudgetProcess()}%`}}
                    ></div>
                </div>

                <UsersList users={projectUsers} />
            </div>
        </div>
    )
}

export {ProjectCard}
