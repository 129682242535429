import React, {useState, useRef} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {useIntl} from "react-intl";
import {toAbsoluteUrl} from "../../../../_metronic/helpers";
import {TwitSerialized} from "./_models";

type Props = {
    show: boolean
    twit: TwitSerialized|null
    handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const TwitShareModal = ({show, twit, handleClose}: Props) => {
    const intl = useIntl()

    const shareUrl = (platform: string) => {
        if(!twit) return '';

        const twitUrl = process.env.REACT_APP_URL + '/news/' + twit.slug;

        switch (platform) {
            case 'twitter':
                return 'http://twitter.com/share?url=' + twitUrl;
            case 'facebook':
                return 'https://www.facebook.com/sharer/sharer.php?display=page&u=' + twitUrl;
            case 'linkedin':
                return 'https://www.linkedin.com/sharing/share-offsite/?url=' + twitUrl;
            case 'telegram':
                return 'https://t.me/share/url?url=' + twitUrl;
            default:
                return '';
        }
    }

    return createPortal(
        <Modal
            id='kt_modal_twit_share'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-550px'
            show={show}
            onHide={handleClose}
            backdrop={true}
        >
            {twit &&
            <div className={'modal-body py-0 border bg-dark border-primary rounded border-1'}>
                <div className='row'>
                    <div className='col-10 py-5'>
                        <div className='fs-2 mb-4 fw-bold text-white'>
                            {intl.formatMessage({id: 'TWIT_SHARE_MODAL.SHARE_TO'})}
                        </div>
                        <div className='text-center'>
                            <a className='btn btn-dark hover-scale' href={shareUrl('twitter')} target="_blank" rel="noreferrer">
                                <img src={toAbsoluteUrl('/media/svg/brand-logos/twitter-2.svg')} className='w-30px' alt=''/><br />
                                Twitter
                            </a>
                            <a className='btn btn-dark hover-scale' href={shareUrl('facebook')}  target="_blank" rel="noreferrer">
                                <img src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')} className='w-30px' alt=''/><br />
                                Facebook
                            </a>
                            <a className='btn btn-dark hover-scale' href={shareUrl('linkedin')} target="_blank" rel="noreferrer">
                                <img src={toAbsoluteUrl('/media/svg/brand-logos/linkedin-2.svg')} className='w-30px' alt=''/><br />
                                LinkedIn
                            </a>
                            <a className='btn btn-dark hover-scale' href={shareUrl('telegram')} target="_blank" rel="noreferrer">
                                <img src={toAbsoluteUrl('/media/svg/brand-logos/telegram.svg')} className='w-30px' alt=''/><br />
                                Telegram
                            </a>
                        </div>
                    </div>
                    <div className='col-2 border border-secondary border-left-1 border-bottom-0 border-top-0 border-right-0'>
                        <div className='d-flex border-0 py-5 px-3 pe-0 align-items-center text-center'>
                            <div className='btn btn-sm btn-icon btn-color-white btn-outline-white btn-active-color-dark m-auto' onClick={handleClose}>
                                <i className='bi bi-x fs-1' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </Modal>,
        modalsRoot
    )
}

export {TwitShareModal}
