/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import {useIntl} from "react-intl";

const HeaderUserMenu: FC = () => {
  const intl = useIntl()
  const {currentUser, logout} = useAuth()

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            {/*<img alt='Logo' src={toAbsoluteUrl('/media/avatars/300-1.jpg')} />*/}
            <img alt='Logo' src={currentUser?.image} />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.firstName} {currentUser?.lastName}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      {/*<div className='separator my-2'/>*/}

      {/*<div className='menu-item px-5'>*/}
      {/*  <div className='menu-item px-3'>*/}
      {/*    <div className='menu-content px-3'>*/}
      {/*      <label className='form-check form-switch form-check-custom form-check-solid'>*/}
      {/*        <input*/}
      {/*            className='form-check-input w-30px h-20px'*/}
      {/*            type='checkbox'*/}
      {/*            value='1'*/}
      {/*            defaultChecked={true}*/}
      {/*            name='notifications'*/}
      {/*        />*/}
      {/*        <span className='form-check-label text-muted fs-7'>*/}
      {/*          {intl.formatMessage({id: 'HEADER.USER_MENU.AVAILABILITY'})}*/}
      {/*        </span>*/}
      {/*      </label>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/crm/profile-builder'} className='menu-link px-5'>
          {intl.formatMessage({id: 'HEADER.USER_MENU.PROFILE'})}
        </Link>
      </div>

      <div className='menu-item px-5'>
        <Link to={'/crm/proposals'} className='menu-link px-5'>
          <span className='menu-text'>
            {intl.formatMessage({id: 'HEADER.USER_MENU.PROPOSALS'})}
          </span>
          {/*<span className='menu-badge'>*/}
          {/*  <span className='badge badge-primary fw-bolder fs-7'>3</span>*/}
          {/*</span>*/}
        </Link>
      </div>

      <div className='separator my-2'></div>

      <Languages />

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          {intl.formatMessage({id: 'HEADER.USER_MENU.SIGN_OUT'})}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
