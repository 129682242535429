import React, {FC, useState} from 'react'
import {Field, ErrorMessage} from 'formik'
import {useIntl} from "react-intl";

export interface RoleModel {
  id: string
  name: string
}

const Step2: FC = () => {
  const intl = useIntl()

  const [roles, setRoles] = useState<RoleModel[]>([
    {'id': 'backend-developer', 'name': 'Backend'},
    {'id': 'frontend-developer', 'name': 'Frontend'},
    {'id': 'full-stack-developer', 'name': 'Full Stack'},
    {'id': 'mobile-developer', 'name': 'Mobile'},
    {'id': 'blockchain-developer', 'name': 'Blockchain'},
  ]);

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>
          {intl.formatMessage({id: 'ONBOARDING.LEADING_ROLE'})} <sup><span className="badge badge-primary">{intl.formatMessage({id: 'ONBOARDING.SELECT_ONE'})}</span></sup>
        </h2>

        <div className='text-gray-400 fw-bold fs-6'>
          {intl.formatMessage({id: 'ONBOARDING.LEADING_ROLE.DESCRIPTION'})}
        </div>
      </div>

      <div className='mb-10 fv-row'>

        <div className='row mb-5 mx-5' data-kt-buttons='true'>
          <>
            {roles.map((role, key) => {
              return (
                  <div className='col mb-4' key={key}>
                    <Field type='radio' className='btn-check' name='leadingRole' value={role.id} id={'kt_leading_role_select_' + role.id} />
                    <label className='btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary w-100 p-4' htmlFor={'kt_leading_role_select_' + role.id} >
                      <span className='fw-bolder fs-3'>{role.name}</span>
                    </label>
                  </div>
              )
            })}
          </>
        </div>
        <div className='text-danger mt-2'>
          <ErrorMessage name='leadingRole' />
        </div>
      </div>

      <br />
      <br />
      <br />

      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>
          {intl.formatMessage({id: 'ONBOARDING.EXPERIENCE'})}
          {/*<sup><span className="badge badge-primary">({intl.formatMessage({id: 'ONBOARDING.SELECT_ONE'})})</span></sup>*/}
        </h2>

        <div className='text-gray-400 fw-bold fs-6'>
          {intl.formatMessage({id: 'ONBOARDING.EXPERIENCE.DESCRIPTION'})}
        </div>
      </div>

      <div className='mb-10 fv-row'>
        <div className='row mb-5 mx-5' data-kt-buttons='true'>
          <div className='col'>
            <Field type='radio' className='btn-check' name='experience' value='none' id='kt_experience_select_1'/>
            <label className='btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary w-100 p-4' htmlFor='kt_experience_select_1'>
              <span className='fw-bolder fs-3'>{intl.formatMessage({id: 'ONBOARDING.EXPERIENCE.NONE'})}</span>
            </label>
          </div>

          <div className='col'>
            <Field type='radio' className='btn-check' name='experience' value='junior' id='kt_experience_select_2'/>
            <label className='btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary w-100 p-4' htmlFor='kt_experience_select_2'>
              <span className='fw-bolder fs-3'>{intl.formatMessage({id: 'ONBOARDING.EXPERIENCE.JUNIOR'})}</span>
            </label>
          </div>

          <div className='col'>
            <Field type='radio' className='btn-check' name='experience' value='middle' id='kt_experience_select_3'/>
            <label className='btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary w-100 p-4' htmlFor='kt_experience_select_3'>
              <span className='fw-bolder fs-3'>{intl.formatMessage({id: 'ONBOARDING.EXPERIENCE.MIDDLE'})}</span>
            </label>
          </div>

          <div className='col'>
            <Field type='radio' className='btn-check' name='experience' value='senior' id='kt_experience_select_4'/>
            <label className='btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary w-100 p-4' htmlFor='kt_experience_select_4'>
              <span className='fw-bolder fs-3'>{intl.formatMessage({id: 'ONBOARDING.EXPERIENCE.SENIOR'})}</span>
            </label>
          </div>
        </div>
        <div className='text-danger mt-2'>
          <ErrorMessage name='experience' />
        </div>
      </div>
    </div>
  )
}

export {Step2}
