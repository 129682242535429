import React from "react";
import {useAuth} from "../auth";
import {useIntl} from "react-intl";
import {OnboardingWizard} from "../wizards/onboarding/OnboardingWizard";
import {ResumeUploader} from "../../pages/dashboard/components/ResumeUploader";
import {DefineQuestionnaire} from "../../pages/dashboard/components/DefineQuestionnaire";
import {ProfileHeader} from "../profile/ProfileHeader";
import {PageTitle} from "../../../_metronic/layout/core";

const ProfileBuilderPage = () => {
    const intl = useIntl()
    const {currentUser} = useAuth()

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.PROFILE_BUILDER'})}</PageTitle>
            <ProfileHeader user={currentUser} />
            <br />
            <DefineQuestionnaire initialUrl={currentUser?.url} />
            <br />
            <OnboardingWizard />
            <br />
            <ResumeUploader initialUrl={currentUser?.resumeUrl} />
            <br />
        </>
    );
}

export {ProfileBuilderPage}
