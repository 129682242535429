import React from "react";
import {useIntl} from "react-intl";
import {toAbsoluteUrl} from "../../../_metronic/helpers";
import {PageTitle} from "../../../_metronic/layout/core";

const HomePage = () => {
    const intl = useIntl()

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.INTRO'}) + ' - ' + intl.formatMessage({id: 'MENU.INTRO.JOIN_US'})}</PageTitle>
            <div className="mb-0" id="home" style={{marginLeft: '-30px', marginRight: '-30px'}}>
                <div
                    className="bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg">
                    <div className="d-flex flex-column flex-center w-100 min-h-350px min-h-lg-300px px-9">
                        <div className="text-center mb-5 mb-lg-10 py-10 py-lg-20">
                            <h1 className="text-white lh-base fw-bold fs-2x fs-lg-3x mb-10 mt-20">
                                Zmęczony otrzymywaniem<br />nietrafionych ofert pracy?
                            </h1>
                            <div className='subtitle fs-2 mb-10'>
                                Mamy pomysł - niech rekruterzy aplikują do Ciebie!
                            </div>
                            <a href="/auth" className="btn btn-lg btn-success">
                                <span className='bi bi-check pe-5' />
                                Dołącz do ZDALNY.DEV
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-sm-n10" style={{marginLeft: '-30px', marginRight: '-30px'}}>
                <div className="landing-dark-bg pb-20">
                    <div className="container">
                        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
                            <div className={'row mb-5 align-items-center align-content-center'}>
                                <div className={'col-md-7'}>
                                    <img
                                        className='mx-auto w-100 mw-600px px-10 mb-20'
                                        src={toAbsoluteUrl('/media/zdalny-dev/landing-chat.png')}
                                        alt=''
                                    />
                                </div>
                                <div className={'col-md-5'}>
                                    <h2 className='text-white fs-1 fw-bolder title2'>
                                        {intl.formatMessage({id: 'AUTH.LAYOUT.TITLE2'})}
                                    </h2>
                                    <div className='subtitle fs-5'>
                                        {intl.formatMessage({id: 'AUTH.LAYOUT.SUBTITLE2'})}
                                    </div>

                                    <br /><br /><br />

                                    <h2 className='text-white fs-1 fw-bolder title3'>
                                        {intl.formatMessage({id: 'AUTH.LAYOUT.TITLE3'})}
                                    </h2>
                                    <div className='subtitle fs-5'>
                                        {intl.formatMessage({id: 'AUTH.LAYOUT.SUBTITLE3'})}
                                    </div>

                                    <br /><br /><br />

                                    <h2 className='text-white fs-1 fw-bolder title4'>
                                        {intl.formatMessage({id: 'AUTH.LAYOUT.TITLE4'})}
                                    </h2>
                                    <div className='subtitle fs-5'>
                                        {intl.formatMessage({id: 'AUTH.LAYOUT.SUBTITLE4'})}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{marginLeft: '-30px', marginRight: '-30px', marginBottom: '-30px'}}>
                <div className="landing-dark-bg">
                    {/*<div className="container">*/}
                    {/*    <div className="row py-10">*/}
                    {/*        <div className="col-8">*/}
                    {/*            /!*<div*!/*/}
                    {/*            /!*    className="d-flex flex-stack flex-wrap flex-md-nowrap card-rounded shadow p-8 mx-10"*!/*/}
                    {/*            /!*    style={{backgroundColor: '#20AA3E'}}>*!/*/}
                    {/*            /!*    <div className="my-2 me-5">*!/*/}
                    {/*            /!*        <div className="fs-1 fw-bold text-white mb-2">*!/*/}
                    {/*            /!*            Budujemy społeczność dla zdalnych programistów - <span className="fw-normal">dołącz do naszej grupy na Facebooku!</span></div>*!/*/}
                    {/*            /!*        <div className="fs-6 text-white fw-semibold opacity-75">*!/*/}
                    {/*            /!*            Dołącz do społeczności ponad 6500 programistów.*!/*/}
                    {/*            /!*        </div>*!/*/}
                    {/*            /!*    </div>*!/*/}
                    {/*            /!*    <a href="https://www.facebook.com/groups/587923741352777" target="_blank" className="btn btn-lg btn-outline border-2 btn-outline-white flex-shrink-0 my-2">Zapisz się</a>*!/*/}
                    {/*            /!*</div>*!/*/}
                    {/*            /!*<div className="rounded landing-dark-border p-9 mb-10">*!/*/}
                    {/*            /!*    <h2 className="text-white">Propozycja biznesowa?</h2>*!/*/}
                    {/*            /!*    <span className="fw-normal fs-4 text-gray-700">Napisz do CEO*!/*/}
					{/*			/!*		<a href="https://www.linkedin.com/in/lukaszrak-pl/" target="_blank"*!/*/}
                    {/*            /!*           className="text-white opacity-50 text-hover-primary">*!/*/}
					{/*			/!*			<img src="/media/svg/brand-logos/linkedin-2.svg" className="h-20px"*!/*/}
                    {/*            /!*                 alt="LinkedIn"/>*!/*/}
					{/*			/!*			<span*!/*/}
                    {/*            /!*                className="text-white opacity-50 text-hover-primary fs-5 mb-6">Łukasz</span>*!/*/}
					{/*			/!*		</a>*!/*/}
					{/*			/!*	</span>*!/*/}
                    {/*            /!*</div>*!/*/}
                    {/*        </div>*/}
                    {/*        /!*<div className="col-4">*!/*/}
                    {/*        /!*    <div className="d-flex justify-content-center">*!/*/}
                    {/*        /!*        <div className="d-flex fw-semibold flex-column text-right">*!/*/}
                    {/*        /!*            <h4 className="fw-bold text-gray-400 mb-6">Bądźmy w kontakcie</h4>*!/*/}
                    {/*        /!*            <a href="https://www.linkedin.com/company/zdalny-dev/" target="_blank" className="mb-6">*!/*/}
                    {/*        /!*                <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">LinkedIn</span>*!/*/}
                    {/*        /!*                <img src="/media/svg/brand-logos/linkedin-2.svg" className="h-20px mx-2" alt="LinkedIn"/>*!/*/}
                    {/*        /!*            </a>*!/*/}
                    {/*        /!*            <a href="https://twitter.com/ZdalnyDev" target="_blank" className="mb-6">*!/*/}
                    {/*        /!*                <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">Twitter</span>*!/*/}
                    {/*        /!*                <img src="/media/svg/brand-logos/twitter.svg" className="h-20px mx-2" alt="Twitter"/>*!/*/}
                    {/*        /!*            </a>*!/*/}
                    {/*        /!*            <a href="https://www.facebook.com/zdalny.dev" target="_blank" className="mb-6">*!/*/}
                    {/*        /!*                <span className="text-white opacity-50 text-hover-primary fs-5 mb-6">Facebook</span>*!/*/}
                    {/*        /!*                <img src="/media/svg/brand-logos/facebook-4.svg" className="h-20px mx-2" alt="Facebook"/>*!/*/}
                    {/*        /!*            </a>*!/*/}
                    {/*        /!*        </div>*!/*/}
                    {/*        /!*    </div>*!/*/}
                    {/*        /!*</div>*!/*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="landing-dark-separator"/>
                    <div className="container">
                        <div className="d-flex flex-column flex-md-row flex-stack py-7 py-lg-10">
                            <div className="d-flex align-items-center order-2 order-md-1">
                                <span className="mx-5 fs-6 fw-semibold text-gray-600 pt-1">&copy; 2023 Zdalny.Dev</span>
                            </div>
                            <ul className="menu menu-gray-600 menu-hover-primary fw-semibold fs-6 fs-md-5 order-1 mb-5 mb-md-0">
                                <li className="menu-item">
                                    <a href="https://www.linkedin.com/company/zdalny-dev/" target="_blank" className="menu-link px-2">
                                        <span className="text-white opacity-50 text-hover-primary fs-5">LinkedIn</span>
                                        <img src="/media/svg/brand-logos/linkedin-2.svg" className="h-20px mx-2" alt="LinkedIn"/>
                                    </a>
                                </li>
                                <li className="menu-item mx-5">
                                    <a href="https://twitter.com/ZdalnyDev" target="_blank" className="menu-link px-2">
                                        <span className="text-white opacity-50 text-hover-primary fs-5">Twitter</span>
                                        <img src="/media/svg/brand-logos/twitter.svg" className="h-20px mx-2" alt="Twitter"/>
                                    </a>
                                </li>
                                <li className="menu-item">
                                    <a href="https://www.facebook.com/zdalny.dev" target="_blank" className="menu-link px-2">
                                        <span className="text-white opacity-50 text-hover-primary fs-5">Facebook</span>
                                        <img src="/media/svg/brand-logos/facebook-4.svg" className="h-20px mx-2" alt="Facebook"/>
                                    </a>
                                </li>
                                <li className="menu-item mx-5">
                                    <a href="/policy" className="menu-link px-2">Polityka prywatności</a>
                                </li>
                                {/*<li className="menu-item">*/}
                                {/*    <a href="" className="menu-link px-2">Regulamin</a>*/}
                                {/*</li>*/}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export {HomePage}
