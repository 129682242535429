import React, {useEffect, useRef, useState} from 'react'
import axios from "axios";
import {useIntl} from "react-intl";

const API_URL = process.env.REACT_APP_API_URL

export interface InvitedUser {
  handle: string
  onboarding: InvitedUserOnboarding
}

export interface InvitedUserOnboarding {
  isSkillFilled: boolean
  isQuestionnaireDefined: boolean
  isResumeUploaded: boolean
  isPremium: boolean
  isPro: boolean
}

export function getInvitedUsers() {
  return axios.get<InvitedUser[]>(`${API_URL}/rest/api/users/me/invited-users`)
}

const InvitedUsers = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [invitedUsers, setInvitedUsers] = useState<InvitedUser[]>([]);

  useEffect(() => {
    setLoading(true)

    const loadData = async () => {
      let {data: response} = await getInvitedUsers();

      setInvitedUsers(response);
      setLoading(false)
    }

    loadData().catch(() => {
      setLoading(false)
    });
  }, [])

  return (
      <div>
        <div className="card card-flush mb-xxl-10 mt-10">
          <div className="card-header pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold text-dark"><i className="bi fs-1 bi-people text-danger" /> {intl.formatMessage({id: 'INVITED_USERS.TITLE'})}</span>

              <span className="text-gray-400 pt-2 fw-semibold fs-6">{intl.formatMessage({id: 'INVITED_USERS.DESCRIPTION'})}</span>
            </h3>
            <div className="card-toolbar">

            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table align-middle gs-0 gy-4 my-0">
                <thead>
                <tr className="fs-7 fw-bold text-gray-500">
                  <th className="p-0 min-w-150px d-block pt-3">{intl.formatMessage({id: 'INVITED_USERS.LIST.HANDLE'})}</th>
                  <th className="text-center min-w-140px pt-3">{intl.formatMessage({id: 'INVITED_USERS.LIST.SKILL_FILLED'})}</th>
                  <th className="text-center min-w-140px pt-3">{intl.formatMessage({id: 'INVITED_USERS.LIST.QUESTIONNAIRE_DEFINED'})}</th>
                  <th className="text-center min-w-120px pt-3">{intl.formatMessage({id: 'INVITED_USERS.LIST.RESUME_UPLOADED'})}</th>
                  <th className="text-center min-w-120px pt-3">{intl.formatMessage({id: 'INVITED_USERS.LIST.IS_PREMIUM'})}</th>
                  <th className="text-center min-w-120px pt-3">{intl.formatMessage({id: 'INVITED_USERS.LIST.IS_PRO'})}</th>
                </tr>
                </thead>
                <tbody>

                {loading &&
                <tr>
                  <td colSpan={6}>
                    <div className='w-100 m-auto text-center'>
                      <span className="spinner-border text-primary" role="status"/>
                    </div>
                  </td>
                </tr>
                }

                {!loading &&
                    <>
                      {invitedUsers.length === 0 ?
                        <tr>
                          <td colSpan={6}>
                            <div className='alert alert-primary'>
                              {intl.formatMessage({id: 'INVITED_USERS.LIST.NOT_FOUND'})}
                            </div>
                          </td>
                        </tr>
                          :
                          <>
                            {invitedUsers.map((invitedUser, index) => (
                                <tr key={index}>
                                  <td className="position-relative ps-6 pe-3 my-2">
                                    <div className="position-absolute start-0 top-0 w-5px h-100 rounded-2 bg-info" />
                                    <div className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6"><small>@</small>{invitedUser.handle}</div>
                                  </td>

                                  <td className="text-center">
                                    {invitedUser.onboarding.isSkillFilled && <span className="badge badge-light-success fs-7 fw-bold"><i className='bi bi-check-square text-success'/></span>}
                                    {!invitedUser.onboarding.isSkillFilled && <span className="badge badge-light-primary fs-7 fw-bold"><i className='bi bi-square text-primary'/></span>}
                                  </td>

                                  <td className="text-center">
                                    {invitedUser.onboarding.isQuestionnaireDefined && <span className="badge badge-light-success fs-7 fw-bold"><i className='bi bi-check-square text-success'/></span>}
                                    {!invitedUser.onboarding.isQuestionnaireDefined && <span className="badge badge-light-primary fs-7 fw-bold"><i className='bi bi-square text-primary'/></span>}
                                  </td>

                                  <td className="text-center">
                                    {invitedUser.onboarding.isResumeUploaded && <span className="badge badge-light-success fs-7 fw-bold"><i className='bi bi-check-square text-success'/></span>}
                                    {!invitedUser.onboarding.isResumeUploaded && <span className="badge badge-light-primary fs-7 fw-bold"><i className='bi bi-square text-primary'/></span>}
                                  </td>

                                  <td className="text-center">
                                    {invitedUser.onboarding.isPremium && <span className="badge badge-light-success fs-7 fw-bold"><i className='bi bi-check-square text-success'/></span>}
                                    {!invitedUser.onboarding.isPremium && <span className="badge badge-light-primary fs-7 fw-bold"><i className='bi bi-square text-primary'/></span>}
                                  </td>

                                  <td className="text-center">
                                    {invitedUser.onboarding.isPro && <span className="badge badge-light-success fs-7 fw-bold"><i className='bi bi-check-square text-success'/></span>}
                                    {!invitedUser.onboarding.isPro && <span className="badge badge-light-primary fs-7 fw-bold"><i className='bi bi-square text-primary'/></span>}
                                  </td>
                                </tr>
                            ))}
                          </>
                      }
                    </>
                }

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
  )
}

export {InvitedUsers}
