import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {PageTitle} from "../../../_metronic/layout/core";
import {Twits} from "../../pages/dashboard/components/Twits";
import {SourceSerialized, TagInterface, TwitListInterface} from "./components/_models";
import {useParams} from "react-router-dom";
import {getSource, getTag, getTopic} from "./components/_requests";
import {TagCategorySerialized} from "../auth";

// @ts-ignore
import { Helmet } from "react-helmet";

const TwitsPage = ({listType}: TwitListInterface) => {
    const intl = useIntl()
    const { sourceHandle, tagSlug, topicSlug } = useParams();

    const [source, setSource] = useState<SourceSerialized>();
    const [tag, setTag] = useState<TagInterface>();
    const [topic, setTopic] = useState<TagCategorySerialized>();

    useEffect(() => {
        if(sourceHandle){
            const loadSource = async () => {
                const {data: sourceResponse} = await getSource(sourceHandle);
                setSource(sourceResponse[0]);
            }
            loadSource().catch(() => {});
        }
    }, [sourceHandle])

    useEffect(() => {
        if(tagSlug){
            const loadTag = async () => {
                const {data: tagResponse} = await getTag(tagSlug);
                setTag(tagResponse[0]);
            }
            loadTag().catch(() => {});
        }
    }, [tagSlug])

    useEffect(() => {
        if(topicSlug){
            const loadTopic = async () => {
                const {data: topicResponse} = await getTopic(topicSlug);
                setTopic(topicResponse[0]);
            }
            loadTopic().catch(() => {});
        }
    }, [topicSlug])

    return (
        <>
            {listType === 'trending' &&
                <>
                    <Helmet>
                        <title>{intl.formatMessage({id: 'MENU.DISCOVER'}) + ' - ' + intl.formatMessage({id: 'MENU.DISCOVER.TRENDING'})} | {intl.formatMessage({id: 'META.GENERAL.TITLE'})}</title>
                    </Helmet>
                    <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DISCOVER'}) + ' - ' + intl.formatMessage({id: 'MENU.DISCOVER.TRENDING'})}</PageTitle>
                    <Twits sourceHandle={sourceHandle} tagSlug={tagSlug} topicSlug={topicSlug} listType={listType} />
                </>
            }
            {listType === 'upvoted' &&
                <>
                    <Helmet>
                        <title>{intl.formatMessage({id: 'MENU.DISCOVER'}) + ' - ' + intl.formatMessage({id: 'MENU.DISCOVER.UPVOTED'})} | {intl.formatMessage({id: 'META.GENERAL.TITLE'})}</title>
                    </Helmet>
                    <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DISCOVER'}) + ' - ' + intl.formatMessage({id: 'MENU.DISCOVER.UPVOTED'})}</PageTitle>
                    <Twits sourceHandle={sourceHandle} tagSlug={tagSlug} topicSlug={topicSlug} listType={listType} />
                </>
            }
            {listType === 'bookmarks' &&
                <>
                    <Helmet>
                        <title>{intl.formatMessage({id: 'MENU.DISCOVER'}) + ' - ' + intl.formatMessage({id: 'MENU.DISCOVER.BOOKMARKS'})} | {intl.formatMessage({id: 'META.GENERAL.TITLE'})}</title>
                    </Helmet>
                    <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DISCOVER'}) + ' - ' + intl.formatMessage({id: 'MENU.DISCOVER.BOOKMARKS'})}</PageTitle>
                    <Twits sourceHandle={sourceHandle} tagSlug={tagSlug} topicSlug={topicSlug} listType={listType} />
                </>
            }
            {listType === 'by-source' && source &&
                <>
                    <Helmet>
                        <title>{intl.formatMessage({id: 'MENU.DISCOVER.TRENDING'})} | {intl.formatMessage({id: 'META.GENERAL.TITLE'})}</title>
                    </Helmet>
                    <PageTitle breadcrumbs={[
                        {
                            title: intl.formatMessage({id: 'MENU.DISCOVER.TRENDING'}),
                            path: '/news/trending',
                            isSeparator: false,
                            isActive: false,
                        },
                        { title: '', path: '', isSeparator: true, isActive: false }
                    ]}>{intl.formatMessage({id: 'SOURCE'}) + ': ' + source?.name}</PageTitle>
                    <Twits sourceHandle={sourceHandle} tagSlug={tagSlug} topicSlug={topicSlug} listType={listType} />
                </>
            }
            {listType === 'by-tag' && tag &&
                <>
                    <Helmet>
                        <title>{intl.formatMessage({id: 'MENU.DISCOVER.TRENDING'})} | {intl.formatMessage({id: 'META.GENERAL.TITLE'})}</title>
                    </Helmet>
                    <PageTitle breadcrumbs={[
                        {
                            title: intl.formatMessage({id: 'MENU.DISCOVER.TRENDING'}),
                            path: '/news/trending',
                            isSeparator: false,
                            isActive: false,
                        },
                        { title: '', path: '', isSeparator: true, isActive: false }
                    ]}>{intl.formatMessage({id: 'TAG'}) + ': #' + tag?.slug}</PageTitle>
                    <Twits sourceHandle={sourceHandle} tagSlug={tagSlug} topicSlug={topicSlug} listType={listType} />
                </>
            }

            {listType === 'by-topic' && topic &&
                <>
                    <Helmet>
                        <title>{intl.formatMessage({id: 'MENU.DISCOVER.TRENDING'})} | {intl.formatMessage({id: 'META.GENERAL.TITLE'})}</title>
                    </Helmet>
                    <PageTitle breadcrumbs={[
                        {
                            title: intl.formatMessage({id: 'MENU.DISCOVER.TRENDING'}),
                            path: '/news/trending',
                            isSeparator: false,
                            isActive: false,
                        },
                        { title: '', path: '', isSeparator: true, isActive: false }
                    ]}>{intl.formatMessage({id: 'TOPIC'}) + ': ' + topic?.name}</PageTitle>
                    <Twits sourceHandle={sourceHandle} tagSlug={tagSlug} topicSlug={topicSlug} listType={listType} />
                </>
            }
        </>
    );
}

export {TwitsPage}
