import {FC, useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {HeaderWrapper} from './components/header'
import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/scroll-top'
import {Content, ContentNoPadding, ContentNoTopPadding} from './components/content'
import {FooterWrapper} from './components/footer'
import {Sidebar} from './components/sidebar'
import {
  InviteUsers,
  ThemeModeProvider,
} from '../partials'
import {PageDataProvider} from './core'
import {reInitMenu} from '../helpers'
import {ToolbarWrapper} from './components/toolbar'

type Props = {
  noPadding?: boolean
  noTopPadding?: boolean
}

const MasterLayout: FC<Props> = ({noPadding = false, noTopPadding = false}) => {
  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
          <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
            <HeaderWrapper />
            <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
              <Sidebar />
              <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid'>

                  {noPadding ? (
                      <>
                        <ContentNoPadding>
                          <Outlet />
                        </ContentNoPadding>
                      </>
                  ) : (
                      noTopPadding ? (
                            <>
                              <ToolbarWrapper />
                              <ContentNoTopPadding>
                                <Outlet />
                              </ContentNoTopPadding>
                            </>
                        ) : (
                            <>
                              <ToolbarWrapper />
                              <Content>
                                <Outlet />
                              </Content>
                            </>
                        )
                  )}

                </div>
                {/*<FooterWrapper />*/}
              </div>
            </div>
          </div>
        </div>

        {/*<RightToolbar />*/}

        {/* begin:: Modals */}
        {/*<InviteUsers />*/}
        {/* end:: Modals */}
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
