import React, {FC, useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Formik, Form, FormikValues, Field, ErrorMessage} from 'formik'
import {useAuth} from "../../../modules/auth";
import {getUserByToken} from '../../../modules/auth/core/_requests'

import axios from 'axios'
import {useIntl} from "react-intl";
const API_URL = process.env.REACT_APP_API_URL

const ReferralForm = () => {
  const intl = useIntl()
  const {setCurrentUser} = useAuth()

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const generateReferral = () => {
    return axios.patch(`${API_URL}/rest/api/users/me/generate-referral`)
  }

  const submitStep = async (values : { } , actions: FormikValues) => {
    setLoading(true)
    setError(false)
    setSuccess(false)
    try {
      const {data: auth} = await generateReferral()
      const {data: user} = await getUserByToken(auth.token)
      setCurrentUser(user)
      setLoading(false)
      setSuccess(true)
    } catch (error) {
      setError(true)
      setLoading(false)
    }
  }

  return (
    <div className='d-flex flex-column flex-xl-row flex-row-fluid mt-0' id='define_questionnaire'>
      <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-500px w-xxl-500px me-4">
        <div className="">
          <div className="card border-0 h-md-100 ribbon ribbon-top" data-bs-theme="light" style={{background: 'linear-gradient(112.14deg, #3A7BD5 0%, #00D2FF 100%)'}}>
            <div className="card-body">
              <div className="ribbon-label bg-success"><i className='bi bi-ui-checks text-white me-1'/>Krok 1 z 1</div>
              <div className="align-items-center h-100">
                <div className="px-10">
                  <div className="text-white mb-6 pt-6">
                    {/*<span className="fs-4 fw-semibold me-2 d-block lh-1 pb-2 opacity-75">Get best offer</span>*/}

                    <span className="fs-2qx fw-bold">Wygeneruj link polecający</span>
                  </div>
                  <span className="fw-semibold text-white fs-6 mb-8 d-block opacity-75">
                    Twój indywidualny link polecający pozwoli Ci zapraszać nowych użytkowników do swojej sieci!
                  </span>
                  <div className="d-flex align-items-center flex-wrap d-grid gap-2 mb-10">
                    <div className="d-flex align-items-center me-5 me-xl-13">
                      <div className="symbol symbol-30px symbol-circle me-3">
                        <span className="symbol-label" style={{backgroundColor: '#35C7FF'}}><i className="bi-infinity fs-5 text-white"/></span>
                      </div>
                      <div className="text-white">
                        <span className="fw-semibold d-block fs-8 opacity-75">Polecanych ofert pracy</span>
                        <span className="fw-bold fs-7">Bez limitu</span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center me-5 me-xl-13">
                      <div className="symbol symbol-30px symbol-circle me-3">
                        <span className="symbol-label" style={{backgroundColor: '#35C7FF'}}><i className="bi-infinity fs-5 text-white"/></span>
                      </div>
                      <div className="text-white">
                        <span className="fw-semibold d-block fs-8 opacity-75">Zaproszonych kandydatów</span>
                        <span className="fw-bold fs-7">Bez limitu</span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-30px symbol-circle me-3">
                        <span className="symbol-label" style={{backgroundColor: '#35C7FF'}}><i className="bi-infinity fs-5 text-white"/></span>
                      </div>
                      <div className="text-white">
                        <span className="fw-semibold opacity-75 d-block fs-8">Zyski z poleceń</span>
                        <span className="fw-bold fs-7">Bez limitu</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
        <Formik initialValues={{}} onSubmit={submitStep} >
          {() => (
            <Form className='p-8 w-100' noValidate id='kt_create_account_form'>
              <div className='current'>
                <div className='w-100'>
                  <div className='pb-10'>
                    <div className='subtitle fs-2 text-center'>
                      {intl.formatMessage({id: 'REFERRAL_FORM.TITLE'})}<br />
                      {intl.formatMessage({id: 'REFERRAL_FORM.SUBTITLE'})}
                    </div>
                  </div>
                </div>
              </div>

              <div className='d-flex pt-5 align-items-center'>
                <div className='d-flex align-items-center flex-wrap w-100'>
                  {error &&
                    <div className='notice d-flex bg-light-danger rounded border-danger border border-dashed p-6 w-100 text-danger fw-bold'>
                      {intl.formatMessage({id: 'REFERRAL_FORM.ERROR'})}
                    </div>
                  }
                  {success &&
                    <div className='notice d-flex bg-light-success rounded border-success border border-dashed p-6 w-100 text-success fw-bold'>
                      {intl.formatMessage({id: 'REFERRAL_FORM.SUCCESS'})}
                    </div>
                  }
                </div>
                <div className='w-200px px-1 py-3 m-auto text-center fs-4'>
                  {!loading &&
                    <button type='submit' className='btn btn-lg btn-primary'>
                      <span className='indicator-label'>
                        {intl.formatMessage({id: 'REFERRAL_FORM.SUBMIT'})}

                        <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-3 ms-2 me-0'/>
                      </span>
                    </button>
                  }
                  {loading && <span className="spinner-border text-primary" role="status"/>}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export {ReferralForm}
