import React from "react";
import {useAuth} from "../auth";
import {useIntl} from "react-intl";
import {PageTitle} from "../../../_metronic/layout/core";

const NewsletterPage = () => {
    const intl = useIntl()
    const {currentUser} = useAuth()

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.NEWSLETTERS.ZDALNY_DEV'})}</PageTitle>
            <div className='mw-500px px-1 py-3 m-auto text-center'>
                <div className='d-flex'>
                    <div className="bg-light-primary text-primary border border-primary rounded m-9 p-9 w-100 shadow align-content-center align-items-center d-flex">
                        <i className="bi bi-envelope text-primary fs-3x me-4"/>
                        <div className='w-100 fs-4'>
                            {intl.formatMessage({id: 'SOON'})}!
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export {NewsletterPage}
