import React, {useEffect, useState} from "react";
import {useAuth} from "../auth";
import {useIntl} from "react-intl";
import {PageTitle} from "../../../_metronic/layout/core";
import {LoginModal} from "../twits/components/LoginModal";
import {ReferralForm} from "../../pages/dashboard/components/ReferralForm";
import {ReferralCard} from "./components/ReferralCard";
import {ReferralHeader} from "../proposals/components/ReferralHeader";
import {InvitedUsers} from "../../pages/dashboard/components/InvitedUsers";
// @ts-ignore
import { Helmet } from "react-helmet";

const ReferralPage = () => {
    const intl = useIntl()
    const {currentUser} = useAuth()
    const [loginOpened, setLoginOpened] = useState<boolean>(false);

    return (
        <>
            <Helmet>
                <title>{intl.formatMessage({id: 'MENU.REFERRAL'})} | {intl.formatMessage({id: 'META.GENERAL.TITLE'})}</title>
            </Helmet>

            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.REFERRAL'}) + ' - ' + intl.formatMessage({id: 'MENU.REFERRAL.INVITE'})}</PageTitle>
            <>
                {!currentUser &&
                <div className="mb-0" id="home" style={{marginLeft: '-30px', marginRight: '-30px'}}>
                    <div className="bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg">
                        <div className="d-flex flex-column flex-center w-100 min-h-150px min-h-lg-100px px-9">
                            <div className="text-center mb-5 mb-lg-10 py-10 py-lg-10">
                                <h1 className="text-white lh-base fw-bold fs-2x mb-10 mt-10">
                                    Udostępniaj oferty pracy znajomym.<br />
                                    Zarabiaj kryptowaluty.
                                </h1>
                                <div className='subtitle fs-2'>
                                    Doskonały sposób na zarabianie na swojej sieci i odbiorcach, <br />przyczyniając się do rozwoju Twoich ulubionych projektów.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }

                {currentUser &&
                <div className='d-flex flex-column flex-lg-row flex-row-fluid mt-0 landing-dark-bg' style={{marginLeft: '-30px', marginRight: '-30px'}}>
                    <div className="d-flex flex-column flex-center w-100 min-h-150px min-h-lg-100px px-9">
                        <div className="text-center mb-5 mb-lg-10 py-10 py-lg-10">
                            <h1 className="text-white lh-base fw-bold fs-2x mb-10 mt-10">
                                Udostępniaj oferty pracy znajomym.<br />
                                Zarabiaj kryptowaluty.
                            </h1>
                            <div className='subtitle fs-2'>
                                Doskonały sposób na zarabianie na swojej sieci i odbiorcach,<br />
                                przyczyniając się do rozwoju Twoich ulubionych projektów.
                            </div>
                        </div>
                    </div>
                    <div className="card d-none d-lg-flex justify-content-center flex-row-auto w-25 mw-250px m-10 my-20">
                        <div className="card-body w-100 text-center" style={{position: "relative"}}>
                            <img alt='Logo' src='/media/vectors/20943543.png' className='w-100 mw-230px p-2' style={{position: 'absolute', margin: 'auto', left: 0, top: 0, bottom: 0, right: 0}} />
                        </div>
                    </div>
                </div>
                }

                {currentUser &&
                    <>
                        <br />
                        {!currentUser.referralId && <ReferralForm />}
                        {currentUser.referralId &&
                            <>
                                <ReferralHeader />
                                <InvitedUsers />
                            </>
                        }
                    </>
                }
                {!currentUser &&
                    <>
                        <br />

                        <div className='d-flex flex-column flex-xl-row flex-row-fluid mt-0'>
                            <div className="card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-450px">
                                <div className="card-body px-7 text-center">
                                    <img alt='Logo' src='/media/vectors/20943543.png' className='w-100 mw-400px' />
                                </div>
                            </div>
                            <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
                                <div className="card border-0 w-100 h-md-100 ribbon ribbon-top" style={{background: 'linear-gradient(112.14deg, #3A7BD5 0%, #00D2FF 100%)'}}>
                                    <div className="card-body">
                                        <div className="ribbon-label bg-success"><i className='bi bi-lightning-fill text-white me-1'/>Airdrop - tylko w 2023</div>
                                        <div className="align-items-center h-100">
                                            <div className="text-white mb-4 pt-4">
                                                <span className="fs-4 fw-semibold me-2 d-block lh-1 pb-2 opacity-75">Zaczniesz dziś - kwalifikujesz się do otrzymania Airdrop!</span>
                                                <span className="fs-2qx fw-bold">Nagrody oraz tokeny czekają</span>
                                            </div>
                                            <div className="fw-semibold text-white fs-6 mb-8 d-block opacity-75">
                                                Aktywuj swój profil w systemie poleceń w mniej niż 30 sekund.
                                                <ul>
                                                    <li>Generuj dedykowane linki polecające, przesyłaj je znajomym. </li>
                                                    <li>Otrzymuj natychmiastową wypłatę w BTC oraz naszych tokenach.</li>
                                                </ul>
                                            </div>
                                            <div className="d-flex align-items-center flex-wrap d-grid gap-2 mb-10">
                                                <div className="d-flex align-items-center me-5 me-xl-13">
                                                    <div className="symbol symbol-30px symbol-circle me-3">
                                                        <span className="symbol-label" style={{backgroundColor: '#35C7FF'}}><i className="bi-infinity fs-5 text-white"/></span>
                                                    </div>
                                                    <div className="text-white">
                                                        <span className="fw-semibold d-block fs-8 opacity-75">Polecanych ofert pracy</span>
                                                        <span className="fw-bold fs-7">Bez limitu</span>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center me-5 me-xl-13">
                                                    <div className="symbol symbol-30px symbol-circle me-3">
                                                        <span className="symbol-label" style={{backgroundColor: '#35C7FF'}}><i className="bi-infinity fs-5 text-white"/></span>
                                                    </div>
                                                    <div className="text-white">
                                                        <span className="fw-semibold d-block fs-8 opacity-75">Zaproszonych kandydatów</span>
                                                        <span className="fw-bold fs-7">Bez limitu</span>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <div className="symbol symbol-30px symbol-circle me-3">
                                                        <span className="symbol-label" style={{backgroundColor: '#35C7FF'}}><i className="bi-infinity fs-5 text-white"/></span>
                                                    </div>
                                                    <div className="text-white">
                                                        <span className="fw-semibold opacity-75 d-block fs-8">Zyski z poleceń</span>
                                                        <span className="fw-bold fs-7">Bez limitu</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column flex-sm-row d-grid gap-2">
                                                <button type='button' className='btn btn-lg btn-success me-2 hover-scale' onClick={() => setLoginOpened(true)}>
                                                    <i className='bi bi-check'/>
                                                    Zacznij teraz
                                                </button>
                                                <button type='button' className='btn btn-lg btn-primary me-2 hover-scale' style={{background: 'rgba(255, 255, 255, 0.2)'}} onClick={() => setLoginOpened(true)}>
                                                    Czytaj Whitepaper
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br />

                        <div className='landing-dark-bg p-10'>
                            <div className='row row-cols-2 mb-6'>
                                <div className='col-12 col-md-5 text-center'>
                                    <ReferralCard
                                        icon={'bi-cash-coin'}
                                        title={'Zarabiaj na poważnie'}
                                        subtitle={'nawet do 10K$ za zatrudnionego kandydata'}
                                        image={'https://framerusercontent.com/images/6Q3BC2qNGL2fVo5rDNJDuxLzLU.svg'}
                                        extraDeal={true}
                                    />
                                </div>
                                <div className='col-12 col-md-7 text-center'>
                                    <ReferralCard
                                        icon={'bi-fire'}
                                        title={'Poradzisz sobie, to proste!'}
                                        subtitle={'wybierz ofertę i udostępnij swój niestandardowy link jednym kliknięciem'}
                                        image={'https://framerusercontent.com/images/ud8sl34bkhu6C7kOocur2OHi5g.svg'}
                                    />
                                </div>
                            </div>
                            <div className='row row-cols-2 mb-6'>
                                <div className='col-12 col-md-7 text-center'>
                                    <ReferralCard
                                        icon={'bi-graph-up'}
                                        title={'Wszystko w CRM'}
                                        subtitle={'śledź swoje linki oraz status wszystkich zaproszonych'}
                                        image={'https://framerusercontent.com/images/j786vt9r4IGujMiC5RWjIPBHU4.svg'}
                                    />
                                </div>
                                <div className='col-12 col-md-5 text-center'>
                                    <ReferralCard
                                        icon={'bi-infinity'}
                                        title={'Nie ograniczaj się'}
                                        subtitle={'zapraszaj do wybranej ofery lub do całego systemu'}
                                        image={'https://framerusercontent.com/images/j786vt9r4IGujMiC5RWjIPBHU4.svg'}
                                    />
                                </div>
                            </div>
                            <div className='row row-cols-2'>
                                <div className='col-12 col-md-6 text-center'>
                                    <ReferralCard
                                        icon={'bi-diagram-3'}
                                        title={'Nieskończony łańcuch poleceń'}
                                        subtitle={'zarabiaj również na "pośrednich poleceniach"'}
                                        image={'https://framerusercontent.com/images/j786vt9r4IGujMiC5RWjIPBHU4.svg'}
                                    />
                                </div>
                                <div className='col-12 col-md-6 text-center'>
                                    <ReferralCard
                                        icon={'bi-stopwatch'}
                                        title={'Natychmiastowe wynagrodzenie'}
                                        subtitle={'dzięki technologii blockchain dostajesz prowizję natychmiast'}
                                        image={'https://framerusercontent.com/images/j786vt9r4IGujMiC5RWjIPBHU4.svg'}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                }
            </>

            <LoginModal
                show={loginOpened}
                handleClose={()=> {
                    setLoginOpened(false);
                }}
            />
        </>
    );
}

export {ReferralPage}
