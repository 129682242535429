/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from "../../../../../app/modules/auth";

const SidebarMenuMain = () => {
    const intl = useIntl()
    const {currentUser} = useAuth()

  return (
      <>
          {currentUser ? (
              <>
                  <SidebarMenuItem to='/crm/dashboard' title={intl.formatMessage({id: 'MENU.DASHBOARD'})} fontIcon='bi-app-indicator'/>
                  <div className="app-sidebar-separator separator"/>
                  <div className='menu-item'>
                      <div className='menu-content pb-2'>
                          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({id: 'MENU.PROPOSALS'})}</span>
                      </div>
                  </div>
                  <SidebarMenuItem to='/crm/profile-builder' title={intl.formatMessage({id: 'MENU.PROFILE_BUILDER'})} fontIcon='bi-person-badge'/>
                  {/*<SidebarMenuItem to='/my-availability' title={intl.formatMessage({id: 'MENU.PROFILE_BUILDER'})} fontIcon='bi-person-badge'/>*/}
                  <SidebarMenuItem to='/crm/proposals' title={intl.formatMessage({id: 'MENU.PROPOSALS_SHORT'})} fontIcon='bi-calendar-week'/>

                  <div className="app-sidebar-separator separator"/>
              </>
          ) : (
              <>
                  <div className='menu-item'>
                      <div className='menu-content pb-2'>
                          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({id: 'MENU.INTRO'})}</span>
                      </div>
                  </div>
                  <SidebarMenuItem to='/intro' title={intl.formatMessage({id: 'MENU.INTRO.JOIN_US'})} fontIcon='bi-app-indicator'/>
                  <SidebarMenuItem to='/auth' title={intl.formatMessage({id: 'MENU.INTRO.LOGIN'})} fontIcon='bi-person'/>
                  <div className="app-sidebar-separator separator"/>
              </>
          )}

          <div className='menu-item'>
              <div className='menu-content pb-2'>
                  <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                      {intl.formatMessage({id: 'MENU.PREMIUM'})}
                  </span>
              </div>
          </div>
          {!currentUser && <SidebarMenuItem to='/about-premium' title={intl.formatMessage({id: 'MENU.DISCOVER.ACCOUNT_PREMIUM'})} fontIcon='bi-speedometer2'/>}
          {currentUser && <SidebarMenuItem to='/about-premium' title={intl.formatMessage({id: 'MENU.DISCOVER.ACCOUNT_PREMIUM'})} fontIcon='bi-speedometer2'/>}
          {/*{currentUser && <SidebarMenuItem to='/crm/wallets' title={intl.formatMessage({id: 'MENU.WALLETS'})} fontIcon='bi-wallet2' />}*/}
          <div className="app-sidebar-separator separator"/>

          <div className='menu-item'>
              <div className='menu-content pb-2'>
                  <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                      {intl.formatMessage({id: 'MENU.PRO'})}
                  </span>
              </div>
          </div>
          {!currentUser && <SidebarMenuItem to='/about-pro' title={intl.formatMessage({id: 'MENU.DISCOVER.ACCOUNT_PRO'})} fontIcon='bi-shield-check'/>}
          {currentUser && <SidebarMenuItem to='/about-pro' title={intl.formatMessage({id: 'MENU.DISCOVER.ACCOUNT_PRO'})} fontIcon='bi-shield-check'/>}
          {currentUser && <SidebarMenuItem to='/crm/projects' title={intl.formatMessage({id: 'MENU.PROJECTS'})} fontIcon='bi-hdd-stack' hasBadge={true} badgeValue={0} badgeColor={'badge-primary'} /> }
          {currentUser && <SidebarMenuItem to='/crm/tasks' title={intl.formatMessage({id: 'MENU.TASKS'})} fontIcon='bi-kanban' hasBadge={true} badgeValue={0} badgeColor={'badge-primary'} />}

          {/*<div className="app-sidebar-separator separator"/>*/}

          {/*<div className='menu-item'>*/}
          {/*    <div className='menu-content pb-2'>*/}
          {/*        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({id: 'MENU.CONTRIBUTE'})}</span>*/}
          {/*    </div>*/}
          {/*</div>*/}
          {/*<SidebarMenuItem to='/news/new' title={intl.formatMessage({id: 'MENU.CONTRIBUTE.SUBMIT_LINK'})} fontIcon='bi-link-45deg'/>*/}
          {/*<SidebarMenuItem to='/news/suggest-source' title={intl.formatMessage({id: 'MENU.CONTRIBUTE.SUGGEST_SOURCE'})} fontIcon='bi-code'/>*/}

          <div className="app-sidebar-separator separator"/>

          {/*<div className='menu-item'>*/}
          {/*    <div className='menu-content pb-2'>*/}
          {/*        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({id: 'MENU.ONBOARDING'})}</span>*/}
          {/*    </div>*/}
          {/*</div>*/}
          {/*<div className="app-sidebar-separator separator"/>*/}

          {/*{currentUser ? (*/}
          {/*    <>*/}
          {/*        <div className='menu-item'>*/}
          {/*            <div className='menu-content pb-2'>*/}
          {/*                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({id: 'MENU.DEDICATED_JOBS'})}</span>*/}
          {/*            </div>*/}
          {/*        </div>*/}

          {/*        <SidebarMenuItem to='/crm/jobs/symfony' title='Symfony' devIcon='devicon-symfony-plain' hasBadge={false} badgeValue={0} />*/}
          {/*        <SidebarMenuItem to='/crm/jobs/php' title='PHP' devIcon='devicon-php-plain' hasBadge={false} badgeValue={0} />*/}
          {/*        <SidebarMenuItem to='/crm/jobs/js' title='JavaScript' devIcon='devicon-javascript-plain' hasBadge={false} badgeValue={0} />*/}
          {/*        <SidebarMenuItem to='/crm/jobs/java' title='Java' devIcon='devicon-java-plain' hasBadge={false} badgeValue={0} />*/}
          {/*        /!*<SidebarMenuItem to='/crm/jobs/scala' title='Scala' devIcon='devicon-scala-plain' hasBadge={false} badgeValue={0} />*!/*/}
          {/*        /!*<SidebarMenuItem to='/crm/jobs/dotnet' title='.NET' devIcon='devicon-dot-net-plain' hasBadge={false} badgeValue={0} />*!/*/}
          {/*        /!*<SidebarMenuItem to='/crm/jobs/ruby' title='Ruby' devIcon='devicon-ruby-plain' hasBadge={false} badgeValue={0} />*!/*/}
          {/*        /!*<SidebarMenuItem to='/crm/jobs/rust' title='Rust' devIcon='devicon-rust-plain' hasBadge={false} badgeValue={0} />*!/*/}
          {/*        /!*<SidebarMenuItem to='/crm/jobs/go' title='Goland' devIcon='devicon-go-original-wordmark' hasBadge={false} badgeValue={0} />*!/*/}
          {/*        /!*<SidebarMenuItem to='/crm/proposals' title='C' devIcon='devicon-c-plain' hasBadge={true} badgeValue={0} />*!/*/}
          {/*        /!*<SidebarMenuItem to='/crm/proposals' title='C#' devIcon='devicon-csharp-plain' hasBadge={true} badgeValue={0} />*!/*/}
          {/*        /!*<SidebarMenuItem to='/crm/proposals' title='C++' devIcon='devicon-cplusplus-plain' hasBadge={true} badgeValue={0} />*!/*/}

          {/*        <div className="app-sidebar-separator separator"/>*/}
          {/*    </>*/}
          {/*) : (*/}
          {/*    <>*/}

          {/*    </>*/}
          {/*)}*/}



          <div className='menu-item'>
              <div className='menu-content pb-2'>
                  <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({id: 'MENU.DISCOVER'})}</span>
              </div>
          </div>
          <SidebarMenuItem to='/news/trending' title={intl.formatMessage({id: 'MENU.DISCOVER.TRENDING'})} fontIcon='bi-fire'/>
          {/*<SidebarMenuItem to='/news/popular' title={intl.formatMessage({id: 'MENU.DISCOVER.UPVOTED'})} fontIcon='bi-arrow-up-square'/>*/}
          <SidebarMenuItem to='/news/upvoted' title={intl.formatMessage({id: 'MENU.DISCOVER.UPVOTED'})} fontIcon='bi-box-arrow-in-up'/>
          {/*<SidebarMenuItem to='/news/popular' title={intl.formatMessage({id: 'MENU.DISCOVER.UPVOTED'})} fontIcon='bi-caret-up'/>*/}
          {/*<SidebarMenuItem to='/news/popular' title={intl.formatMessage({id: 'MENU.DISCOVER.UPVOTED'})} fontIcon='bi-caret-up-square'/>*/}
          {/*<SidebarMenuItem to='/news/bookmarks' title={intl.formatMessage({id: 'MENU.DISCOVER.BOOKMARKS'})} fontIcon='bi-bookmarks'/>*/}

          <div className="app-sidebar-separator separator"/>
          <div className='menu-item'>
              <div className='menu-content pb-2'>
                  <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({id: 'MENU.REFERRAL'})}</span>
              </div>
          </div>
          <SidebarMenuItem to='/referral' title={intl.formatMessage({id: 'MENU.REFERRAL.INVITE'})} fontIcon='bi-person-plus'/>
          <div className="app-sidebar-separator separator"/>






          {/*<SidebarMenuItem to='/dashboard' title={intl.formatMessage({id: 'MENU.DASHBOARD'})} fontIcon='bi-app-indicator'/>*/}
          {/*<SidebarMenuItem to='/profile-builder' title='Profile builder' fontIcon='bi-person-badge'/>*/}
          {/*<div className="app-sidebar-separator separator"/>*/}
          {/*<SidebarMenuItem to='/career-paths' title='Career paths' fontIcon='bi-signpost-split'/>*/}
          {/*<div className="app-sidebar-separator separator"/>*/}
          {/*<SidebarMenuItem to='/resume-manager' title='Resume manager' fontIcon='bi-person-vcard'/>*/}

          {/*/!*<SidebarMenuItem to='/builder' title='Layout Builder' fontIcon='bi-layers' />*!/*/}

          {/*<div className="app-sidebar-separator separator"/>*/}
          {/*<div className='menu-item'>*/}
          {/*    <div className='menu-content pb-2'>*/}
          {/*        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Certificates</span>*/}
          {/*    </div>*/}
          {/*</div>*/}

          {/*<SidebarMenuItem to='/business-references' title='Business references' fontIcon='bi-buildings' hasBadge={true} />*/}
          {/*<SidebarMenuItem to='/work-references' title='Work references' fontIcon='bi-briefcase' hasBadge={true} badgeValue={4} badgeColor={'badge-primary'} />*/}
          {/*<SidebarMenuItem to='/tech-achievement' title='Tech achievement' fontIcon='bi-boxes' hasBadge={true} badgeValue={9} badgeColor={'badge-primary'} />*/}
          {/*<SidebarMenuItem to='/online-credentials' title='Online credentials' fontIcon='bi-award' hasBadge={true} badgeValue={7} badgeColor={'badge-primary'} />*/}
          {/*<SidebarMenuItem to='/school-diplomas' title='School diplomas' fontIcon='bi-book' hasBadge={true} />*/}

          {/*<div className="app-sidebar-separator separator"/>*/}
          {/*<div className='menu-item'>*/}
          {/*    <div className='menu-content pb-2'>*/}
          {/*      <span className='menu-section text-muted text-uppercase fs-8 ls-1'>*/}
          {/*          Career automation <i className="bi fs-3 bi-lightning"/>*/}
          {/*      </span>*/}
          {/*    </div>*/}
          {/*</div>*/}

          {/*<SidebarMenuItem to='/ai-assistance' title='AI assistance' fontIcon='bi-chat-square-text'/>*/}
          {/*<SidebarMenuItem to='/gem-jobs' title='Gem jobs' fontIcon='bi-gem'/>*/}
          {/*<SidebarMenuItem to='/quick-deal' title='QuickDeal' fontIcon='bi-cash-coin'/>*/}
          {/*<SidebarMenuItem to='/ai-recruitement' title='AI recruitement' fontIcon='bi-chat-square-text'/>*/}



          {/*<div className="app-sidebar-separator separator"/>*/}
          {/*<div className='menu-item'>*/}
          {/*    <div className='menu-content pb-2'>*/}
          {/*      <span className='menu-section text-muted text-uppercase fs-8 ls-1'>*/}
          {/*          Extras*/}
          {/*          /!*<i className="bi fs-3 bi-lightning"/>*!/*/}
          {/*      </span>*/}
          {/*    </div>*/}
          {/*</div>*/}
          {/*<SidebarMenuItem to='/tokenomy' title='Tokenomy' fontIcon='bi-rocket-takeoff' />*/}
          {/*<SidebarMenuItem to='/green-check' title='Green Check' fontIcon='bi-shield-check text-success' />*/}



          {/*<div className='menu-item'>*/}
          {/*    <div className='menu-content pb-2'>*/}
          {/*        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({id: 'MENU.NEWSLETTERS'})}</span>*/}
          {/*    </div>*/}
          {/*</div>*/}
          {/*/!*<SidebarMenuItem to='/crm/newsletters/zdalny-dev' title={intl.formatMessage({id: 'MENU.NEWSLETTERS.ZDALNY_DEV'})} fontIcon='bi-code-slash'/>*!/*/}
          {/*<SidebarMenuItem to='https://www.aicoders.pl' title={intl.formatMessage({id: 'MENU.NEWSLETTERS.AI'})} fontIcon='bi-robot'/>*/}

          {/*<div className="app-sidebar-separator separator"/>*/}


          {/*  <SidebarMenuItem to='/hot-projects' title='Hot projects' fontIcon='bi-fire' hasBadge={true} badgeValue={5} badgeColor={'badge-danger'} />*/}
        {/*  <div className="app-sidebar-separator separator"/>*/}
        {/*  <SidebarMenuItem to='/resume-manager' title='Resume manager' fontIcon='bi-person-vcard'/>*/}
        {/*  <SidebarMenuItem to='/wallets' title='Blockchain wallets' fontIcon='bi-wallet2'/>*/}

        {/*  <SidebarMenuItem to='/builder' title='Layout Builder' fontIcon='bi-layers' />*/}

        {/*  <SidebarMenuItem to='/business-references' title='Business references' fontIcon='bi-buildings' hasBadge={true} />*/}
        {/*  <SidebarMenuItem to='/work-references' title='Work references' fontIcon='bi-briefcase' hasBadge={true} badgeValue={4} badgeColor={'badge-primary'} />*/}
        {/*  <SidebarMenuItem to='/tech-achievement' title='Tech achievement' fontIcon='bi-boxes' hasBadge={true} badgeValue={9} badgeColor={'badge-primary'} />*/}
        {/*  <SidebarMenuItem to='/online-credentials' title='Online credentials' fontIcon='bi-award' hasBadge={true} badgeValue={7} badgeColor={'badge-primary'} />*/}
        {/*  <SidebarMenuItem to='/school-diplomas' title='School diplomas' fontIcon='bi-book' hasBadge={true} />*/}

        {/*<div className="app-sidebar-separator separator"/>*/}
        {/*<div className='menu-item'>*/}
        {/*    <div className='menu-content pb-2'>*/}
        {/*        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>*/}
        {/*            Career automation <i className="bi fs-3 bi-lightning"/>*/}
        {/*        </span>*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*  <SidebarMenuItem to='/ai-assistance' title='AI assistance' fontIcon='bi-chat-square-text'/>*/}
        {/*  <SidebarMenuItem to='/gem-jobs' title='Gem jobs' fontIcon='bi-gem'/>*/}
        {/*  <SidebarMenuItem to='/quick-deal' title='QuickDeal' fontIcon='bi-cash-coin'/>*/}
        {/*  <SidebarMenuItem to='/ai-recruitement' title='AI recruitement' fontIcon='bi-chat-square-text'/>*/}

        {/*<div className="app-sidebar-separator separator"/>*/}
        {/*<div className='menu-item'>*/}
        {/*    <div className='menu-content pb-2'>*/}
        {/*        <span className='menu-section text-muted text-uppercase fs-8 ls-1'>*/}
        {/*            Extras*/}
        {/*            /!*<i className="bi fs-3 bi-lightning"/>*!/*/}
        {/*        </span>*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/*<SidebarMenuItem to='/tokenomy' title='Tokenomy' fontIcon='bi-rocket-takeoff' />*/}
        {/*<SidebarMenuItem to='/green-check' title='Green Check' fontIcon='bi-shield-check text-success' />*/}
    </>
  )
}

export {SidebarMenuMain}
