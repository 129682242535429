/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useIntl} from "react-intl";

const AuthLayout = () => {
  const intl = useIntl()

  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-1 order-lg-1'>
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          <div className='w-100 p-10'>
            <Outlet />
          </div>
        </div>

        {/*<div className='d-flex flex-center flex-wrap px-5'>*/}
        {/*  <div className='d-flex fw-semibold text-primary fs-base'>*/}
        {/*    <a href='#' className='px-5' target='_blank'>*/}
        {/*      Terms*/}
        {/*    </a>*/}

        {/*    <a href='#' className='px-5' target='_blank'>*/}
        {/*      Plans*/}
        {/*    </a>*/}

        {/*    <a href='#' className='px-5' target='_blank'>*/}
        {/*      Contact Us*/}
        {/*    </a>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>

      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-2 order-lg-2'
        // style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`}}
        style={{background: `#1e1e2d`}}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          <h1 className='text-white fs-2qx fw-bolder text-center mb-7 w-75 border-bottom border-1 pb-5'>
            {intl.formatMessage({id: 'AUTH.LAYOUT.TITLE'})}
          </h1>
          <div className={'row mb-5'}>
            <div className={'col-6'}>
              <h2 className='text-white fs-2 fw-bolder text-center title2'>
                {intl.formatMessage({id: 'AUTH.LAYOUT.TITLE2'})}
              </h2>
            </div>
            <div className={'col-6'}>
              <div className='subtitle fs-5'>
                {intl.formatMessage({id: 'AUTH.LAYOUT.SUBTITLE2'})}
              </div>
            </div>
          </div>
          <div className={'row mb-5'}>
            <div className={'col-6'}>
              <h2 className='text-white fs-2 fw-bolder text-center title3'>
                {intl.formatMessage({id: 'AUTH.LAYOUT.TITLE3'})}
              </h2>
            </div>
            <div className={'col-6'}>
              <div className='subtitle fs-5'>
                {intl.formatMessage({id: 'AUTH.LAYOUT.SUBTITLE3'})}
              </div>
            </div>
          </div>

          <img
            className='mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-10'
            src={toAbsoluteUrl('/media/zdalny-dev/landing-chat.png')}
            // src='https://talentservice.com/assets/screen-24.ea84f780.png'
            alt=''
          />

          <div className={'row'}>
            <div className={'col-6'}>
              <h2 className='text-white fs-2 fw-bolder text-center title4'>
                {intl.formatMessage({id: 'AUTH.LAYOUT.TITLE4'})}
              </h2>
            </div>
            <div className={'col-6'}>
              <div className='subtitle fs-5'>
                {intl.formatMessage({id: 'AUTH.LAYOUT.SUBTITLE4'})}
              </div>
            </div>
          </div>

          {/*<div className='text-white fs-base text-center'>*/}
          {/*  We focus on real problems and provide the solutions (with help of web3). <br />*/}
          {/*  Our product is the beginning of great decentralized hiring ecosystem which we are going to build. <br />*/}
          {/*  We are going to make hiring process <strong>fully decentralized</strong>!*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  )
}

export {AuthLayout}
