import React, {FC} from 'react'
import {Field, ErrorMessage} from 'formik'
import {useIntl} from "react-intl";
import {SkillModel} from "../core/_models";

type Props = {
    extraSkills: SkillModel[]
}

const Step4: FC<Props> = ({extraSkills}) => {
    const intl = useIntl()

    return (
        <div className='w-100'>
            <div className='pb-10 pb-lg-15'>
                <h2 className='fw-bolder text-dark'>
                    {intl.formatMessage({id: 'ONBOARDING.EXTRA_SKILLS'})} <sup><span className="badge badge-primary">{intl.formatMessage({id: 'ONBOARDING.OPTIONAL'})}</span></sup>
                </h2>

                <div className='text-gray-400 fw-bold fs-6'>
                    {intl.formatMessage({id: 'ONBOARDING.EXTRA_SKILLS.DESCRIPTION'})}
                </div>
            </div>

            <div className='mb-10 fv-row'>
                <div className='row mb-5' data-kt-buttons='true'>
                    <>
                        {extraSkills.map((skill, key) => {
                            return (
                                <div className='col mb-4' key={key}>
                                    <Field type='checkbox' className='btn-check' name='extraSkills' value={skill.slug} id={'kt_extra_skills_select_' + skill.id} />
                                    <label className='btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary w-100 p-4' htmlFor={'kt_extra_skills_select_' + skill.id} >
                                        <span className='fw-bolder fs-3'>{skill.name}</span>
                                    </label>
                                </div>
                            )
                        })}
                    </>
                </div>
                <div className='text-danger mt-2'>
                    <ErrorMessage name='extraSkills' />
                </div>
            </div>
        </div>
    )
}

export {Step4}
