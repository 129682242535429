import {useLocation} from 'react-router-dom'
import {QuestionnaireForm} from "./questionnaire-form/QuestionnaireForm";
import React, {useEffect, useState} from "react";
import {UserBasicModel} from "../auth";
import axios from "axios";
import {ProfileHeader} from "../profile/ProfileHeader";
import {useIntl} from "react-intl";
const API_URL = process.env.REACT_APP_API_URL

export function getUserBySlug(slug: string) {
    return axios.get<UserBasicModel>(`${API_URL}/api/users/${slug}`)
}

export function patchProposalConfirmation(userSlug: string, proposalId: string) {
    return axios.patch(`${API_URL}/rest/api/proposals/${userSlug}/confirm/${proposalId}`)
}

const QuestionnairePage = () => {
    const intl = useIntl()
    const location = useLocation();
    const [user, setUser] = useState<UserBasicModel>();
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    const [confirmed, setConfirmed] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true)
        setError(false)
        setSuccess(false)

        const loadUser = async (userSlug: string) => {
            const response = await getUserBySlug(userSlug);
            setUser(response.data);

            setLoading(false)
            setSuccess(true)
        }

        const confirmProposal = async (userSlug: string, proposalId: string) => {
            const response = await patchProposalConfirmation(userSlug, proposalId);
            setConfirmed(true);

            setLoading(false)
            setSuccess(true)
        }

        let userUrl = location.pathname.split('/');
        if(userUrl[2] === undefined){
            loadUser(userUrl[1]).catch(() => {
                setError(true)
                setLoading(false)
            });
        }else{
            confirmProposal(userUrl[1], userUrl[2]).catch(() => {
                setError(true)
                setLoading(false)
            });
        }
    }, [])

    return (
        <>
            {!user &&
            <div className='mw-500px px-1 py-3 m-auto text-center'>
                {error &&
                <div className='px-1 py-3 m-auto text-center fs-4 mt-4'>
                    <div className='notice d-flex bg-light-danger rounded border-danger border border-dashed p-6 w-100 text-danger fw-bold'>
                        {intl.formatMessage({id: 'QUESTIONNAIRE_PAGE.ERROR'})}
                    </div>
                </div>
                }

                {loading && <span className="spinner-border text-primary" role="status"/>}

                {confirmed &&
                <div className='px-1 py-3 m-auto text-center fs-4 mt-4'>
                    <div className='notice d-flex bg-light-success rounded border-success border border-dashed p-6 w-100 text-success fw-bold'>
                        {intl.formatMessage({id: 'QUESTIONNAIRE_PAGE.CONFIRMED'})}
                    </div>
                </div>
                }
            </div>
            }

            {user &&
                <>
                    <ProfileHeader user={user} showImage={false} />
                    <br />
                    <QuestionnaireForm user={user} />
                </>
            }
        </>
    );
}

export {QuestionnairePage}
