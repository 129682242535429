import React, {FC, useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Formik, Form, FormikValues, Field, ErrorMessage} from 'formik'
import {useAuth} from "../../auth";
import {getUserByToken} from '../../auth/core/_requests'

import axios from 'axios'
import {useIntl} from "react-intl";
import {CardsWidget17} from "../../../../_metronic/partials/widgets";
const API_URL = process.env.REACT_APP_API_URL

type Props = {
  initialUrl?: string
}

const ReferralHeader: FC<Props> = ({ initialUrl = '' }) => {
  const intl = useIntl()
  const {currentUser, setCurrentUser} = useAuth()

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const generateReferral = () => {
    return axios.patch(`${API_URL}/rest/api/users/me/generate-referral`)
  }

  const submitStep = async (values : { url: string } , actions: FormikValues) => {
    setLoading(true)
    setError(false)
    setSuccess(false)
    try {
      const {data: auth} = await generateReferral()
      const {data: user} = await getUserByToken(auth.token)
      setCurrentUser(user)
      setLoading(false)
      setSuccess(true)
    } catch (error) {
      setError(true)
      setLoading(false)
    }
  }

  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  return (
    <div className='d-flex flex-column flex-xl-row flex-row-fluid mt-0' id='define_questionnaire'>
      <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-350px me-4">
        <CardsWidget17 className='w-100' />
      </div>
      <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
        <div className="d-flex flex-wrap flex-stack">
          <div className="d-flex flex-column flex-grow-1 m-3 pe-8">
            <div className="d-flex flex-wrap">
              <div className="border border-gray-300 border-dashed rounded min-w-125px p-4">
                <div className="d-flex align-items-center cursor-pointer" onClick={() => copyTextToClipboard('https://zdalny.dev/?ref=' + currentUser?.referralId)} title={'Kliknij aby skopiować'}>
                  <i className="bi bi-link-45deg fs-3 text-primary me-2" />
                  <div className="fs-2 fw-bold">
                    <small>https://zdalny.dev/?ref=</small>{currentUser?.referralId}
                  </div>
                </div>
                <div className="fw-semibold fs-6 text-gray-400">Link polecający - zaproś znajomych do ZdalnyDev</div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center w-200px w-sm-300px flex-column m-3">
            <div className="d-flex justify-content-between w-100 mt-auto mb-2 px-4">
              <span className="fw-semibold fs-6 text-gray-400"><span className="bullet bullet-vertical me-1"/> Rodzaj konta w systemie</span>
              <span className="fw-bold fs-6">Prywatne</span>
            </div>
            <div className="d-flex justify-content-between w-100 mt-auto mb-2 px-4">
              <span className="fw-semibold fs-6 text-gray-400"><span className="bullet bullet-vertical me-1"/> Waluta rozliczeniowa</span>
              <span className="fw-bold fs-6">PLN</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ReferralHeader}
