import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login, loginWithGoogle, loginWithLinkedIn} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import { useLinkedIn } from "react-linkedin-login-oauth2";
import {useIntl} from "react-intl";
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from '@greatsumini/react-facebook-login';

// const initialValues = {
//   email: 'kate@diasset.pl',
//   password: 'kate@diasset.pl',
// }

const initialValues = { email: '', password: '' }

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const intl = useIntl()
  const {saveAuth, setCurrentUser} = useAuth()
  const [loading, setLoading] = useState(false)

  const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email(intl.formatMessage({id: 'AUTH.LOGIN.EMAIL.ERROR.EMAIL'}))
        .min(5, intl.formatMessage({id: 'AUTH.LOGIN.EMAIL.ERROR.MIN'}))
        .max(50, intl.formatMessage({id: 'AUTH.LOGIN.EMAIL.ERROR.MAX'}))
        .required(intl.formatMessage({id: 'AUTH.LOGIN.EMAIL.ERROR.REQUIRED'})),
    password: Yup.string()
        .min(8, intl.formatMessage({id: 'AUTH.LOGIN.PASSWORD.ERROR.MIN'}))
        .max(50, intl.formatMessage({id: 'AUTH.LOGIN.PASSWORD.ERROR.MAX'}))
        .required(intl.formatMessage({id: 'AUTH.LOGIN.PASSWORD.ERROR.REQUIRED'})),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password)
        saveAuth(auth)
        const {data: user} = await getUserByToken(auth.token)
        setCurrentUser(user)
      } catch (error) {
        saveAuth(undefined)
        setStatus(intl.formatMessage({id: 'AUTH.LOGIN.INVALID_CREDENTIALS'}))
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const { linkedInLogin } = useLinkedIn({
    clientId: "86x8rqe2kf1dqx",
    redirectUri: `${window.location.origin}/linkedin`,
    scope: "r_emailaddress r_liteprofile",
    onSuccess: async (code) => {
      setLoading(true);

      let referralId = undefined;
      if(localStorage.getItem('referralId') !== null){
        referralId = localStorage.getItem('referralId');
      }

      const {data: auth} = await loginWithLinkedIn({code, referralId});
      saveAuth(auth)
      const {data: user} = await getUserByToken(auth.token)
      setCurrentUser(user)
    },
    onError: (error) => {
      saveAuth(undefined)
      setLoading(false)
    },
  });

  const googleAuth = async (code: string) => {
    setLoading(true);
    const {data: auth} = await loginWithGoogle({'code': code});
    saveAuth(auth)
    const {data: user} = await getUserByToken(auth.token)
    setCurrentUser(user)
  }

  const loginGoogle = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: codeResponse => googleAuth(codeResponse.code),
  });

  // return (
  //     <div className='text-center mb-20'>
  //         <Link to='/' className='mb-12'>
  //             <img alt='Logo' src={toAbsoluteUrl('/media/zdalny-dev/logo.png')} className='h-150px rounded-1' />
  //             <h1 className='text-white fs-3x fw-bolder text-center title5 mt-5'>
  //                 Zdalny<span className='title3 d-inline-block'>.</span><span className='title6 d-inline-block'>dev</span>
  //             </h1>
  //             <br />
  //             <br />
  //             <div className='subtitle fs-1'>
  //                 Niech rekruterzy aplikują do Ciebie!
  //             </div>
  //         </Link>
  //     </div>
  // );

  return (
      <>
        <div className='text-center mb-10 mt-20'>
          <Link to='/' className='mb-12'>
            <img alt='Logo' src={toAbsoluteUrl('/media/zdalny-dev/logo.png')} className='h-100px rounded-1' />
          </Link>

            <h1 className='text-white fs-3x fw-bolder text-center title5 mt-5'>
                Zdalny<span className='title3 d-inline-block'>.</span><span className='title6 d-inline-block'>dev</span>
            </h1>
            <br />
            {/*<div className='subtitle fs-1'>*/}
            {/*    Niech rekruterzy aplikują do Ciebie!*/}
            {/*</div>*/}
        </div>
          {/*<div className='w-100 px-1 py-3 m-auto text-center mb-10'>*/}
          {/*    <div className='px-1 py-3 m-auto text-center fs-4 mt-4'>*/}
          {/*        <div className='notice bg-light-primary rounded border-primary border border-dashed p-6 w-100 text-primary text-center'>*/}
          {/*            Dołączasz z polecenia ambasadora <br /><strong>@Lukasz</strong><br /><br />*/}

          {/*            Sprawdź co możesz zyskać zostajac jednym ze <strong>Zdalnych Ambasadorów</strong>!<br />*/}
          {/*            <small>Szczegóły znajdziesz po zalogowaniu do CRM 🚀</small>*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*</div>*/}
        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>
            {intl.formatMessage({id: 'AUTH.LOGIN.TITLE'})}
          </h1>
          {/*<div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div>*/}
        </div>
        <div className='row g-3 mb-9'>
          <button
              onClick={linkedInLogin}
              disabled={loading}
              className='btn btn-flex btn-outline btn-text-gray-900 btn-active-color-primary bg-state-light flex-center text-nowrap w-75 m-auto py-5'
          >
            {!loading && <>
              <img alt='Logo' src={toAbsoluteUrl('/media/svg/brand-logos/linkedin-2.svg')} className='theme-light-show h-25px me-3'/>
              <img alt='Logo' src={toAbsoluteUrl('/media/svg/brand-logos/linkedin-2.svg')} className='theme-dark-show h-25px me-3'/>
              {intl.formatMessage({id: 'AUTH.LOGIN.WITH_LINKEDIN'})}
            </>}
            {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'AUTH.LOGIN.PLEASE_WAIT'})}...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'/>
                  </span>
            )}
          </button>

          <div className='mb-2'/>


          {/*<FacebookLogin*/}
          {/*    className='btn btn-flex btn-outline btn-text-gray-900 btn-active-color-primary bg-state-light flex-center text-nowrap w-75 m-auto py-5'*/}
          {/*    appId="574607054804125"*/}
          {/*    onSuccess={(response) => {*/}
          {/*      console.log('Login Success!', response);*/}
          {/*    }}*/}
          {/*    onFail={(error) => {*/}
          {/*      console.log('Login Failed!', error);*/}
          {/*    }}*/}
          {/*    onProfileSuccess={(response) => {*/}
          {/*      console.log('Get Profile Success!', response);*/}
          {/*    }}*/}
          {/*/>*/}

          {/*<button*/}
          {/*    // onClick={linkedInLogin}*/}
          {/*    disabled={loading}*/}
          {/*    className='btn btn-flex btn-outline btn-text-gray-900 btn-active-color-primary bg-state-light flex-center text-nowrap w-75 m-auto py-5'*/}
          {/*>*/}
          {/*  {!loading && <>*/}
          {/*    <img alt='Logo' src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')} className='theme-light-show h-25px me-3'/>*/}
          {/*    <img alt='Logo' src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')} className='theme-dark-show h-25px me-3'/>*/}
          {/*    {intl.formatMessage({id: 'AUTH.LOGIN.WITH_FACEBOOK'})}*/}
          {/*  </>}*/}
          {/*  {loading && (*/}
          {/*      <span className='indicator-progress' style={{display: 'block'}}>*/}
          {/*          {intl.formatMessage({id: 'AUTH.LOGIN.PLEASE_WAIT'})}...*/}
          {/*          <span className='spinner-border spinner-border-sm align-middle ms-2'/>*/}
          {/*        </span>*/}
          {/*  )}*/}
          {/*</button>*/}

          {/*<div className='mb-2'/>*/}







          {/*<button*/}
          {/*    onClick={loginGoogle}*/}
          {/*    disabled={loading}*/}
          {/*    className='btn btn-flex btn-outline btn-text-gray-900 btn-active-color-primary bg-state-light flex-center text-nowrap w-75 m-auto py-5'*/}
          {/*>*/}
          {/*  {!loading && <>*/}
          {/*    <img alt='Logo' src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')} className='theme-light-show h-25px me-3'/>*/}
          {/*    <img alt='Logo' src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')} className='theme-dark-show h-25px me-3'/>*/}
          {/*    {intl.formatMessage({id: 'AUTH.LOGIN.WITH_GOOGLE'})}*/}
          {/*  </>}*/}
          {/*  {loading && (*/}
          {/*      <span className='indicator-progress' style={{display: 'block'}}>*/}
          {/*          {intl.formatMessage({id: 'AUTH.LOGIN.PLEASE_WAIT'})}...*/}
          {/*          <span className='spinner-border spinner-border-sm align-middle ms-2'/>*/}
          {/*        </span>*/}
          {/*  )}*/}
          {/*</button>*/}






          {/*<div className='col-md-6'>*/}
          {/*  <a href='#' className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'>*/}
          {/*    <img alt='Logo' src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')} className='h-15px me-3'/>*/}
          {/*    Sign in with Google*/}
          {/*  </a>*/}
          {/*</div>*/}
        </div>
        {/*<div className='separator separator-content my-14'>*/}
        {/*  <span className='w-125px text-gray-500 fw-semibold fs-7'>{intl.formatMessage({id: 'AUTH.LOGIN.OR_EMAIL'})}</span>*/}
        {/*</div>*/}
        {/*<form*/}
        {/*    className='form w-100'*/}
        {/*    onSubmit={formik.handleSubmit}*/}
        {/*    noValidate*/}
        {/*    id='kt_login_signin_form'*/}
        {/*>*/}
        {/*  {formik.status ? (*/}
        {/*      <div className='mb-lg-15 alert alert-danger'>*/}
        {/*        <div className='alert-text font-weight-bold'>{formik.status}</div>*/}
        {/*      </div>*/}
        {/*  ) : (*/}
        {/*      <>*/}
        {/*        /!*<div className='mb-10 bg-light-info p-8 rounded'>*!/*/}
        {/*        /!*  <div className='text-info'>*!/*/}
        {/*        /!*    Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to*!/*/}
        {/*        /!*    continue.*!/*/}
        {/*        /!*  </div>*!/*/}
        {/*        /!*</div>*!/*/}
        {/*      </>*/}
        {/*  )}*/}

        {/*  <div className='fv-row mb-8'>*/}
        {/*    <label className='form-label fs-6 fw-bolder text-dark'>*/}
        {/*      {intl.formatMessage({id: 'AUTH.LOGIN.EMAIL.TITLE'})}*/}
        {/*    </label>*/}
        {/*    <input*/}
        {/*        placeholder={intl.formatMessage({id: 'AUTH.LOGIN.EMAIL.PLACEHOLDER'})}*/}
        {/*        {...formik.getFieldProps('email')}*/}
        {/*        className={clsx(*/}
        {/*            'form-control bg-transparent',*/}
        {/*            {'is-invalid': formik.touched.email && formik.errors.email},*/}
        {/*            {*/}
        {/*              'is-valid': formik.touched.email && !formik.errors.email,*/}
        {/*            }*/}
        {/*        )}*/}
        {/*        type='email'*/}
        {/*        name='email'*/}
        {/*        autoComplete='off'*/}
        {/*    />*/}
        {/*    {formik.touched.email && formik.errors.email && (*/}
        {/*        <div className='fv-plugins-message-container'>*/}
        {/*          <span role='alert'>{formik.errors.email}</span>*/}
        {/*        </div>*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*  <div className='fv-row mb-3'>*/}
        {/*    <label className='form-label fw-bolder text-dark fs-6 mb-0'>*/}
        {/*      {intl.formatMessage({id: 'AUTH.LOGIN.PASSWORD.TITLE'})}*/}
        {/*    </label>*/}
        {/*    <input*/}
        {/*        placeholder={intl.formatMessage({id: 'AUTH.LOGIN.PASSWORD.PLACEHOLDER'})}*/}
        {/*        type='password'*/}
        {/*        autoComplete='off'*/}
        {/*        {...formik.getFieldProps('password')}*/}
        {/*        className={clsx(*/}
        {/*            'form-control bg-transparent',*/}
        {/*            {*/}
        {/*              'is-invalid': formik.touched.password && formik.errors.password,*/}
        {/*            },*/}
        {/*            {*/}
        {/*              'is-valid': formik.touched.password && !formik.errors.password,*/}
        {/*            }*/}
        {/*        )}*/}
        {/*    />*/}
        {/*    {formik.touched.password && formik.errors.password && (*/}
        {/*        <div className='fv-plugins-message-container'>*/}
        {/*          <div className='fv-help-block'>*/}
        {/*            <span role='alert'>{formik.errors.password}</span>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*  /!*<div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>*!/*/}
        {/*  /!*  <div />*!/*/}
        {/*  /!*  <Link to='/auth/forgot-password' className='link-primary'>Forgot Password ?</Link>*!/*/}
        {/*  /!*</div>*!/*/}

        {/*  <div className='d-grid mb-10'>*/}
        {/*    <button*/}
        {/*        type='submit'*/}
        {/*        id='kt_sign_in_submit'*/}
        {/*        className='btn btn-primary'*/}
        {/*        disabled={formik.isSubmitting || !formik.isValid}*/}
        {/*    >*/}
        {/*      {!loading && <span className='indicator-label'>{intl.formatMessage({id: 'AUTH.LOGIN.BUTTON'})}</span>}*/}
        {/*      {loading && (*/}
        {/*          <span className='indicator-progress' style={{display: 'block'}}>*/}
        {/*            {intl.formatMessage({id: 'AUTH.LOGIN.PLEASE_WAIT'})}...*/}
        {/*            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>*/}
        {/*          </span>*/}
        {/*      )}*/}
        {/*    </button>*/}
        {/*  </div>*/}

          <div className='text-gray-500 text-center fw-semibold fs-6'>
            Logując się akceptujesz politykę politykę prywatności, <br />a także zgadzasz się na regulamin serwisu.<br /> <br />
            <Link to='/policy' className='link-primary'>Polityka prywatności</Link><br />
            <Link to='/terms' className='link-primary'>Regulamin</Link>
          </div>
        {/*</form>*/}
      </>
  )
}
