/* eslint-disable react/jsx-no-target-blank */
import {KTSVG, toAbsoluteUrl} from '../../../helpers'

const SidebarFooter = () => {
  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
      <a href='https://www.linkedin.com/company/zdalny-dev/' target='_blank' className='btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100' title='Zdalny.dev - LinkedIn'>
          <img alt='Zdalny.dev - LinkedIn' src={toAbsoluteUrl('/media/svg/brand-logos/linkedin-2.svg')} className='theme-light-show h-15px me-3'/>
          <span className='btn-label'>LinkedIn</span>
      </a>
    </div>
  )
}

export {SidebarFooter}
