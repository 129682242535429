import React, {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {TwitDetails} from "./TwitDetails";
import {TwitSerialized} from "./_models";
import {getTwit, isTwitBookmark, isTwitUpvoted} from "./_requests";
import {useAuth} from "../../auth";

type Props = {
    show: boolean
    twit: TwitSerialized|null
    handleClose: () => void
    onTwitUpvote: (twit: TwitSerialized, refreshOpened: boolean) => any
    onTwitComment: () => any
    onTwitBookmark: (twit: TwitSerialized, refreshOpened: boolean) => any
    onTwitShare: (twit: TwitSerialized) => any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const TwitModal = ({show, twit, handleClose, onTwitUpvote, onTwitComment, onTwitBookmark, onTwitShare}: Props) => {
    const { currentUser } = useAuth()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(!show) return;
        if(!twit) return;

        setLoading(true);
        const loadTwitAttributes = async () => {
            if(currentUser){
                const {data: bookmarkResponse} = await isTwitBookmark(twit);
                const {data: upvotedResponse} = await isTwitUpvoted(twit);

                twit.bookmark = bookmarkResponse.bookmark;
                twit.upvoted = upvotedResponse.upvoted;

                setLoading(false);
            }

            // setTwit(twitResponse);
        }

        loadTwitAttributes()
            .catch(() => {
                setLoading(false);
            });
    }, [twit])

    return createPortal(
        <Modal
            id='kt_modal_twit_info'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-900px'
            show={show}
            onHide={handleClose}
            backdrop={true}
        >
            {twit &&
                <div className={'modal-body py-0 border border-primary rounded border-2 bg-light ' + (twit.hot ? 'border-danger' : (twit.trending && 'border-info'))}>
                    <TwitDetails twit={twit} handleClose={handleClose} onTwitShare={onTwitShare} onTwitUpvote={onTwitUpvote} onTwitComment={onTwitComment} onTwitBookmark={onTwitBookmark} />
                </div>
            }
        </Modal>,
        modalsRoot
    )
}

export {TwitModal}
