import React, {useEffect, useState} from "react";
import {useParams} from 'react-router-dom';
import {useAuth} from "../auth";
import {useIntl} from "react-intl";
import {PageTitle} from "../../../_metronic/layout/core";
import {TwitSerialized} from "./components/_models";
import {getTwit, patchTwitUpvote, postTwitBookmark, isTwitBookmark, isTwitUpvoted} from "./components/_requests";
import {TwitShareModal} from "./components/TwitShareModal";
import {TwitDetails} from "./components/TwitDetails";
import {TopicsSelection} from "./components/TopicsSelection";
import {TopicsModal} from "./components/TopicsModal";
import {useNavigate} from 'react-router-dom';
import {LoginModal} from "./components/LoginModal";

// @ts-ignore
import { Helmet } from "react-helmet";

const TwitPage = () => {
    const intl = useIntl()
    const { currentUser } = useAuth()
    const { twitSlug } = useParams();

    const [loading, setLoading] = useState(false)
    const [twit, setTwit] = useState<TwitSerialized>();
    const [twitLoading, setTwitLoading] = useState(false);

    const [twitShareOpened, setTwitShareOpened] = useState<TwitSerialized|null>(null);
    const [topicsOpened, setTopicsOpened] = useState<boolean>(false);
    const [loginOpened, setLoginOpened] = useState<boolean>(false);

    const navigate = useNavigate();

    const onTwitShare = (twit: TwitSerialized) => {
        setTwitShareOpened(twit);
    }

    const onTwitComment = () => {
        if(!currentUser) setLoginOpened(true);
    }
    const onTwitUpvote = () => {
        if(!twit) return;

        twit.loadingUpvote = true;
        setTwitLoading(true);

        const twitUpvote = async () => {
            const response = await patchTwitUpvote(twit);
        }

        twitUpvote().then(() => {
            twit.upvoted = true;
            twit.loadingUpvote = false;
            setTwitLoading(false);
        }).catch((error) => {
            resolveResponse(error);
        });
    }
    const onTwitBookmark = () => {
        if(!twit) return;

        twit.loadingBookmark = true;
        setTwitLoading(true);

        const twitBookmark = async () => {
            const response = await postTwitBookmark(twit);
        }

        twitBookmark()
            .then(() => {
                twit.bookmark = true;
                twit.loadingBookmark = false;
                setTwitLoading(false);
            })
            .catch((error) => {
                resolveResponse(error);
            });
    }

    const resolveResponse = (error: any) => {
        if(error.response){
            if(error.response.status === 401 || error.response.status === 403){
                setLoginOpened(true);
            }
        }

        if(twit){
            twit.loadingUpvote = false;
            twit.loadingBookmark = false;
        }

        setTwitLoading(false);
    }

    const generatePageTitle = () => {
        if(twit){
            return intl.formatMessage({id: 'MENU.DISCOVER'}) + ' - ' + twit.title;
        }else{
            return intl.formatMessage({id: 'MENU.DISCOVER'});
        }
    }

    useEffect(() => {
        setLoading(true)

        if(twitSlug){
            const loadTwit = async () => {
                const {data: twitResponse} = await getTwit(twitSlug);
                if(currentUser){
                    const {data: bookmarkResponse} = await isTwitBookmark(twitResponse[0]);
                    const {data: upvotedResponse} = await isTwitUpvoted(twitResponse[0]);

                    twitResponse[0].bookmark = bookmarkResponse.bookmark;
                    twitResponse[0].upvoted = upvotedResponse.upvoted;
                }

                setTwit(twitResponse[0]);
                setLoading(false)
            }

            loadTwit()
                .catch(() => {
                    setLoading(false)
                });
        }
    }, [])

    return (
        <>
            {twit &&
            <Helmet>
                <title>{twit.title} | {intl.formatMessage({id: 'META.GENERAL.TITLE'})}</title>
            </Helmet>
            }


            <PageTitle breadcrumbs={[]}>
                {generatePageTitle()}
            </PageTitle>

            <div className='d-flex'>
                <div className='w-100px mt-10 mb-2 d-flex align-items-center py-3'>
                    <button
                        className='btn btn-sm btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary px-4 bg-light-dark hover-scale me-1'
                        title={intl.formatMessage({id: 'TWIT.GO_LIST.TITLE'})}
                        onClick={() => {
                            navigate(-1);
                        }}
                    >
                        <i className='bi bi-arrow-90deg-left fs-2' />
                    </button>
                    {/*<button*/}
                    {/*    className='btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary px-2 w-100 bg-light-dark hover-scale'*/}
                    {/*>*/}
                    {/*    <i className='bi bi-arrow-left fs-1 me-2' />*/}
                    {/*    {intl.formatMessage({id: 'TWIT.GO_PREV'})}*/}
                    {/*</button>*/}
                </div>
                <TopicsSelection
                    handleOnClick={() => {
                        setTopicsOpened(true);
                    }}
                />
                <div className='w-100px mt-10 mb-2 d-flex align-items-center py-3'>
                    {/*<button*/}
                    {/*    className='btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary px-2 w-150px bg-light-dark hover-scale'*/}
                    {/*>*/}
                    {/*    {intl.formatMessage({id: 'TWIT.GO_NEXT'})}*/}
                    {/*    <i className='bi bi-arrow-right fs-1 mx-2 me-0' />*/}
                    {/*</button>*/}
                </div>
            </div>

            <br />

            {loading &&
                <div className='mw-500px px-1 py-3 m-auto text-center'>
                    <span className="spinner-border text-primary" role="status"/>
                </div>
            }

            {(!loading && twit) &&
                <div className='m-auto mw-900px px-3 border border-secondary border-left-0 border-bottom-1 border-top-1 border-right-0'>
                    <TwitDetails
                        twit={twit}
                        onTwitShare={onTwitShare}
                        onTwitUpvote={onTwitUpvote}
                        onTwitComment={onTwitComment}
                        onTwitBookmark={onTwitBookmark}
                    />
                </div>
            }

            <TwitShareModal
                show={!!twitShareOpened}
                twit={twitShareOpened}
                handleClose={()=> {setTwitShareOpened(null)}}
            />

            <TopicsModal
                show={topicsOpened}
                handleClose={()=> {
                    setTopicsOpened(false);
                }}
                // handleUnauthorized={() => {
                //     setLoginOpened(true)
                // }}
            />

            <LoginModal
                show={loginOpened}
                handleClose={()=> {
                    setLoginOpened(false);
                }}
            />
        </>
    );
}

export {TwitPage}
