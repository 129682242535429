import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  devIcon?: string
  hasBullet?: boolean
  hasBadge?: boolean
  badgeValue?: number
  badgeColor?: string
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  devIcon ,
  hasBullet = false,
  hasBadge = false,
  badgeValue= 0,
  badgeColor= 'badge-secondary',
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config

  return (
    <div className='menu-item'>
      <Link className={clsx('menu-link without-sub', {active: isActive})} to={to}>
        {devIcon &&
            <span className='menu-icon fs-1'>
              <i className={devIcon} />
            </span>
        }
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            {' '}
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
            <span className='menu-icon'>
              <i className={clsx('bi fs-1', fontIcon)}></i>
            </span>
        )}
        {fontIcon && !icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
            <span className='menu-icon'>
              <i className={clsx('bi fs-1', fontIcon)}></i>
            </span>
        )}
        <span className='menu-title fs-6'>{title}</span>

        {hasBadge && (
            <span className="menu-badge">
              <span className={clsx('badge', badgeColor)}>{badgeValue}</span>
            </span>
        )}
      </Link>
      {children}
    </div>
  )
}

export {SidebarMenuItem}
