import React, {FC} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {ErrorMessage, Field} from "formik";
import {useIntl} from "react-intl";

const Step5: FC = () => {
  const intl = useIntl()

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>
          {intl.formatMessage({id: 'ONBOARDING.SALARY'})}
        </h2>
        <div className='text-gray-400 fw-bold fs-6'>
          {intl.formatMessage({id: 'ONBOARDING.SALARY.DESCRIPTION'})}
        </div>
      </div>

      <div className='row mb-10'>
        <div className='col-md-8 fv-row'>
          <label className='required fs-6 fw-bold form-label mb-2'>{intl.formatMessage({id: 'ONBOARDING.SALARY.RATE_PER_HOUR'})}</label>

          <div className='row fv-row'>
            <div className='col-6'>
              <Field type='number' className='form-control form-control-solid' placeholder={intl.formatMessage({id: 'ONBOARDING.SALARY.ENTER_AMOUNT'})} name='ratePerHour'/>
              <div className='text-danger mt-2'>
                <ErrorMessage name='ratePerHour' />
              </div>
            </div>
            <div className='col-6'>
              <Field as='select' name='rateCurrency' className='form-select form-select-solid'>
                <option value='PLN'>PLN</option>
                <option value='USD'>USD</option>
                <option value='EUR'>EUR</option>
              </Field>
              <div className='text-danger mt-2'>
                <ErrorMessage name='rateCurrency' />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='mb-0'>
        <div className='notice d-flex bg-light-success rounded border-success border border-dashed p-6'>
          <KTSVG path='/media/icons/duotune/general/gen044.svg' className='svg-icon-2tx svg-icon-success me-4'/>
          <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-bold'>
              <h4 className='text-gray-800 fw-bolder'>
                {intl.formatMessage({id: 'ONBOARDING.SALARY.NOTICE.TITLE'})}
              </h4>
              <div className='fs-6 text-gray-600'>
                {intl.formatMessage({id: 'ONBOARDING.SALARY.NOTICE.DESCRIPTION'})}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step5}
