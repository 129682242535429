import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import {useIntl} from "react-intl";
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {TwitOptions} from "./TwitOptions";
import {TwitSerialized} from "./_models";

const API_URL = process.env.REACT_APP_API_URL

type Props = {
    twit: TwitSerialized
    onTwitOpen: (twit: TwitSerialized) => any
    onTwitShare: (twit: TwitSerialized) => any
    onTwitUpvote: (twit: TwitSerialized) => any
    onTwitBookmark: (twit: TwitSerialized) => any
}

const TwitCard: FC<Props> = ({ twit, onTwitOpen, onTwitShare, onTwitUpvote, onTwitBookmark }) => {
    const intl = useIntl()

    const generateRedirectUrl = () => {
        return `${API_URL}${twit.redirectUrl}`
    }

    return (
        <div
            className={'card border border-1 border-secondary border-hover-primary ' + (
                twit.hot ? 'border-hover-danger' : (twit.trending && 'border-hover-info')
            )}
        >
            {/*<OverlayTrigger*/}
            {/*    key={`${twit.id}`}*/}
            {/*    placement='top'*/}
            {/*    overlay={*/}
            {/*        twit.hot*/}
            {/*            ? <span className="badge px-4 fs-7 badge-light-danger border border-danger rounded-5 rounded-bottom-0" title={'HOT'}><i className='bi bi-fire text-danger'/> HOT</span>*/}
            {/*            : (*/}
            {/*                twit.trending*/}
            {/*                    ? <span className="badge px-4 fs-7 badge-light-info border border-info rounded-5 rounded-bottom-0" title={'TRENDING'}><i className='bi bi-bar-chart text-info'/> TRENDING</span>*/}
            {/*                    : <></>*/}
            {/*            )*/}
            {/*    }*/}
            {/* defaultShow={false} delay={0} flip={undefined} onHide={undefined} onToggle={undefined} popperConfig={undefined} show={undefined} target={undefined} trigger={undefined}*/}
            {/*>*/}
                <div>
                    <div className='card-header border-0 pt-2 p-2 d-flex align-items-center'>
                        <div className='w-50px my-2'>
                            {twit.source &&
                                <>
                                    <Link to={'/sources/' + twit.source.handle}>
                                        {twit.source.image && <img title={twit.source.name} className='w-30x h-30px rounded-1 ms-2' src={twit.source.image} alt={twit.source.name} />}
                                    </Link>
                                </>
                            }
                        </div>
                        <div className='card-title m-0 d-flex'>
                            <div className='w-100'>
                                <a
                                    className='btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary px-4 w-100 hover-scale'
                                    target="_blank" rel="noreferrer"
                                    href={generateRedirectUrl()}
                                >
                                    <i className='bi bi-box-arrow-up-right fs-1 me-2' />
                                    {intl.formatMessage({id: 'TWIT_MODAL.OPEN_LINK'})}
                                </a>
                            </div>
                            {/*<div className='mx-4 me-0'>*/}
                            {/*    <TwitOptions />*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className='card-body p-2'>
                        <div className='cursor-pointer' onClick={() => { onTwitOpen(twit); }}>
                            <div title={twit.title} className='fs-3 fw-bold px-2 h-50px' style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                {twit.title}
                            </div>

                            <div className='px-2 pb-2'>
                                <p className='text-gray-400 fs-7 mt-0 mb-0 text-right'>
                                    {twit.created}
                                    {/*~{twit.readTime ? twit.readTime : 0} min czytania*/}
                                </p>
                            </div>

                            <img title={twit.title} className='rounded rounded-3 w-100 cursor-pointer h-150px' style={{objectFit: 'cover'}} src={twit.image} alt={twit.title} onClick={() => { onTwitOpen(twit); }} />
                        </div>

                        <div className='row mb-2 mt-3'>
                            <div className='col-3' title={intl.formatMessage({id: 'TWIT_CARD.UPVOTE'})}>
                                {twit.loadingUpvote &&
                                    <div className='m-auto text-center py-2 align-items-center align-content-center'>
                                        <span className="spinner-border spinner-border-sm text-success" style={{width: '1.3rem', height: '1.3rem'}} role="status"/>
                                    </div>
                                }

                                {!twit.loadingUpvote &&
                                    (twit.upvoted ?
                                            <button className='btn m-auto d-block bg-light-success text-success p-1 py-2 align-items-center align-content-center' disabled={true}>
                                                <i className='bi bi-check fs-2 px-1 pe-0 text-success'/><span className='d-inline-block px-1'>{twit.numUpvotes}</span>
                                            </button>
                                    :
                                            <button className='btn text-muted text-hover-success m-auto d-block bg-hover-light-success p-1 py-2 align-items-center align-content-center' onClick={() => {onTwitUpvote(twit);}}>
                                                <i className='bi bi-arrow-up fs-2 px-1 pe-0'/><span className='d-inline-block px-1'>{twit.numUpvotes}</span>
                                            </button>
                                    )
                                }
                            </div>
                            <div className='col-3' title={intl.formatMessage({id: 'TWIT_CARD.COMMENTS'})}>
                                <button
                                    className='btn text-muted text-hover-primary m-auto d-block bg-hover-light-primary p-1 py-2 align-items-center align-content-center'
                                    onClick={() => { onTwitOpen(twit); }}
                                >
                                    <i className='bi bi-chat-square-text fs-2 px-1 pe-0'/><span className='d-inline-block px-1'>{twit.numComments}</span>
                                </button>
                            </div>
                            <div className='col-3' title={intl.formatMessage({id: 'TWIT_CARD.BOOKMARK'})}>
                                {twit.loadingBookmark &&
                                    <div className='m-auto text-center py-2 align-items-center align-content-center'>
                                        <span className="spinner-border spinner-border-sm text-warning" style={{width: '1.3rem', height: '1.3rem'}} role="status"/>
                                    </div>
                                }

                                {!twit.loadingBookmark &&
                                (twit.bookmark ?
                                        <button className='btn m-auto d-block bg-light-warning text-warning p-1 py-2 align-items-center align-content-center' disabled={true}>
                                            <i className='bi bi-bookmark-check fs-2 px-1 pe-0 text-warning'/>
                                            {/*<span className='d-inline-block px-1'>{twit.numUpvotes}</span>*/}
                                        </button>
                                        :
                                        <button className='btn text-muted text-hover-warning m-auto d-block bg-hover-light-warning p-1 py-2 align-items-center align-content-center' onClick={() => {onTwitBookmark(twit);}}>
                                            <i className='bi bi-bookmark fs-2 px-1 pe-0'/>
                                            {/*<span className='d-inline-block px-1'>{twit.numUpvotes}</span>*/}
                                        </button>
                                )
                                }
                            </div>
                            <div className='col-3' title={intl.formatMessage({id: 'TWIT_CARD.SHARE'})}>
                                <button
                                    // className='btn text-hover-info m-auto d-block bg-hover-light-info p-1 py-2 align-items-center align-content-center'
                                    className='btn text-muted text-hover-info m-auto d-block bg-hover-light-info p-1 py-2 align-items-center align-content-center'
                                    onClick={() => { onTwitShare(twit); }}
                                >
                                    <i className='bi bi-arrow-90deg-right fs-2 px-1 pe-0'/>
                                    {/*<span className='d-inline-block px-1'>{twit.numComments}</span>*/}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            {/*</OverlayTrigger>*/}
        </div>
    )
}

export {TwitCard}
