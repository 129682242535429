import React, {useEffect, useState} from "react";
import {useAuth} from "../auth";
import {useIntl} from "react-intl";
import {PageTitle} from "../../../_metronic/layout/core";
import {LoginModal} from "../twits/components/LoginModal";
import {ReferralForm} from "../../pages/dashboard/components/ReferralForm";
import {ReferralCard} from "../referral/components/ReferralCard";
import {ReferralHeader} from "../proposals/components/ReferralHeader";
import {InvitedUsers} from "../../pages/dashboard/components/InvitedUsers";
// @ts-ignore
import { Helmet } from "react-helmet";

const AboutPremiumPage = () => {
    const intl = useIntl()
    const {currentUser} = useAuth()
    const [loginOpened, setLoginOpened] = useState<boolean>(false);

    return (
        <>
            <Helmet>
                <title>{intl.formatMessage({id: 'MENU.DISCOVER.ACCOUNT_PREMIUM'})} | {intl.formatMessage({id: 'META.GENERAL.TITLE'})}</title>
            </Helmet>

            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DISCOVER.ACCOUNT_PREMIUM'})}</PageTitle>
            <>
                <div className="mb-0" id="home" style={{marginLeft: '-30px', marginRight: '-30px'}}>
                    <div className="bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg">
                        <div className="d-flex flex-column flex-center w-100 min-h-150px min-h-lg-100px px-9">
                            <div className="text-center mb-5 mb-lg-10 py-10 py-lg-10">
                                <h1 className="text-white lh-base fw-bold fs-2x mb-10 mt-10">
                                    Buduj potwierdzone CV.
                                </h1>
                                <div className='subtitle fs-2'>
                                    Nasze rozwiązanie sprawia, ze rekrutacje są krótsze, tańsze i bardziej zautomatyzowane.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <>
                    <br />

                    <div className='d-flex flex-column flex-xl-row flex-row-fluid mt-0'>
                        <div className="card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-450px">
                            <div className="card-body px-7 text-center">
                                <img alt='Logo' src='/media/vectors/20944573.png' className='w-100 mw-400px' />
                            </div>
                        </div>
                        <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
                            <div className="card border-0 w-100 h-md-100 ribbon ribbon-top" style={{background: 'linear-gradient(112.14deg, #D57B3A 0%, #D200FF 100%)'}}>
                                <div className="card-body">
                                    {/*<div className="ribbon-label bg-success"><i className='bi bi-lightning-fill text-white me-1'/>Airdrop - tylko w 2023</div>*/}
                                    <div className="align-items-center h-100">
                                        <div className="text-white mb-4 pt-4">
                                            {/*<span className="fs-4 fw-semibold me-2 d-block lh-1 pb-2 opacity-75">Zaczniesz dziś - kwalifikujesz się do otrzymania Airdrop!</span>*/}
                                            <span className="fs-2qx fw-bold">Spersonalizowana strona z potwierdzonym CV</span>
                                        </div>
                                        <div className="fw-semibold text-white fs-6 mb-8 d-block opacity-75">
                                            ZdalnyDev <strong>Premium</strong> pozwala Ci tworzyć w pełni personalizowane CV dostępne 24 godziny 7 dni w tygodniu, do którego prowadzi Twój indywidualny link.<br /><br />
                                            <ul>
                                                <li>CV zawsze będzie aktualne, a w przypadku wprowadzenia zmian(np. dodania nowej technologii, którą udało Ci się opanować) - wszyscy, którzy wcześniej je widzieli i byli zainteresowani, dostaną automatyczne powiadomienia, aby przejrzeć je kolejny raz - co sprawia, że ponowne rekrutacje do tej samej firmy, będą odbywały się automatycznie bez Twojej ingerencji, a Ty w CRM otrzymasz już konkretną ofertę, gdy firma będzie realnie zainteresowana Twoją kandydaturą.</li>
                                                <li>Do każdej umiejętności oraz historii zatrudnienia, możesz dodawać zweryfikowane poświadczenia, które my z wykorzystanem technologii blockchain, zamieniamy na tokeny NFT, tworząc dla Ciebie w 100% potwierdzone i zweryfikowane CV przyszłości. Chcesz wiedzieć w jaki sposób możesz je wykorzystać? Sprawdź ZdalnyDev PRO.</li>
                                            </ul>
                                        </div>
                                        {/*<div className="d-flex align-items-center flex-wrap d-grid gap-2 mb-10">*/}
                                        {/*    <div className="d-flex align-items-center me-5 me-xl-13">*/}
                                        {/*        <div className="symbol symbol-30px symbol-circle me-3">*/}
                                        {/*            <span className="symbol-label" style={{backgroundColor: '#35C7FF'}}><i className="bi-infinity fs-5 text-white"/></span>*/}
                                        {/*        </div>*/}
                                        {/*        <div className="text-white">*/}
                                        {/*            <span className="fw-semibold d-block fs-8 opacity-75">Polecanych ofert pracy</span>*/}
                                        {/*            <span className="fw-bold fs-7">Bez limitu</span>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="d-flex align-items-center me-5 me-xl-13">*/}
                                        {/*        <div className="symbol symbol-30px symbol-circle me-3">*/}
                                        {/*            <span className="symbol-label" style={{backgroundColor: '#35C7FF'}}><i className="bi-infinity fs-5 text-white"/></span>*/}
                                        {/*        </div>*/}
                                        {/*        <div className="text-white">*/}
                                        {/*            <span className="fw-semibold d-block fs-8 opacity-75">Zaproszonych kandydatów</span>*/}
                                        {/*            <span className="fw-bold fs-7">Bez limitu</span>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="d-flex align-items-center">*/}
                                        {/*        <div className="symbol symbol-30px symbol-circle me-3">*/}
                                        {/*            <span className="symbol-label" style={{backgroundColor: '#35C7FF'}}><i className="bi-infinity fs-5 text-white"/></span>*/}
                                        {/*        </div>*/}
                                        {/*        <div className="text-white">*/}
                                        {/*            <span className="fw-semibold opacity-75 d-block fs-8">Zyski z poleceń</span>*/}
                                        {/*            <span className="fw-bold fs-7">Bez limitu</span>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {!currentUser &&
                                        <div className="d-flex flex-column flex-sm-row d-grid gap-2">
                                            <button type='button' className='btn btn-lg btn-success me-2 hover-scale' onClick={() => setLoginOpened(true)}>
                                                <i className='bi bi-check'/>
                                                Zacznij teraz
                                            </button>
                                            {/*<button type='button' className='btn btn-lg btn-primary me-2 hover-scale' style={{background: 'rgba(255, 255, 255, 0.2)'}} onClick={() => setLoginOpened(true)}>*/}
                                            {/*    Czytaj Whitepaper*/}
                                            {/*</button>*/}
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br />

                    <div className='landing-dark-bg p-10'>
                        <div className='row row-cols-2 mb-6'>
                            <div className='col-12 col-md-5 text-center'>
                                <ReferralCard
                                    icon={'bi-receipt'}
                                    title={'Własne kwestionariusze rekrutacyjne'}
                                    subtitle={'Wersja Premium, pozwala na definiowanie własnych pytań oraz dodanie ich do kwestionariusza, tak aby późniejsze filtrowanie spływających ofert było dla Ciebie jeszcze prostsze.'}
                                    image={'https://framerusercontent.com/images/6Q3BC2qNGL2fVo5rDNJDuxLzLU.svg'}
                                />
                            </div>
                            <div className='col-12 col-md-7 text-center'>
                                <ReferralCard
                                    icon={'bi-person-vcard'}
                                    title={'Szablony Premium'}
                                    subtitle={'Masz do wyboru kilka szablonów Premium, które strukturą są dostosowane do branży IT. Po uzupełnieniu wszystkich informacji, pokazują one w pełni Twoje doświadczenie, nie pozostawiając miejsca na niepotrzebne pytania od rekruterów - Twoje procesy rekrutacyjne są znacznie szybsze.'}
                                    image={'https://framerusercontent.com/images/ud8sl34bkhu6C7kOocur2OHi5g.svg'}
                                    extraDeal={true}
                                />
                            </div>
                        </div>
                        <div className='row row-cols-2 mb-6'>
                            <div className='col-12 col-md-7 text-center'>
                                <ReferralCard
                                    icon={'bi-app-indicator'}
                                    title={'Jeden CRM by zarządzać nimi wszystkimi'}
                                    subtitle={'Udostępniamy Ci narzędzie, w którym możesz zarządzać swoimi procesami rekrutacyjnymi, nawet jeśli pochodzą z innych źródeł niż ZdalnyDev. Automatyzuj spotkania, negocjuj stawki, otrzymuj szczegółowy feedback i zawsze bądź na bieżąco ze statusem każdej swojej rekrutacji.'}
                                    image={'https://framerusercontent.com/images/j786vt9r4IGujMiC5RWjIPBHU4.svg'}
                                />
                            </div>
                            <div className='col-12 col-md-5 text-center'>
                                <ReferralCard
                                    icon={'bi-envelope-heart'}
                                    title={'Szczegółowy feedback'}
                                    subtitle={'Nie dostałeś pracy i nie wiesz dlaczego? Generuj formularze z prośbą o informację zwrotną od rekruterów. Pomoże Ci to uzyskać szczegółowe powody odrzucenia Twojej kandydatury, dzięki czemu będziesz wiedzieć nad czym popracować w przyszłości.'}
                                    image={'https://framerusercontent.com/images/j786vt9r4IGujMiC5RWjIPBHU4.svg'}
                                />
                            </div>
                        </div>
                    </div>
                </>
            </>

            <LoginModal
                show={loginOpened}
                handleClose={()=> {
                    setLoginOpened(false);
                }}
            />
        </>
    );
}

export {AboutPremiumPage}
