import React, {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Formik, Form, FormikValues, Field, ErrorMessage, useFormik} from 'formik'
import {getUserByToken} from '../../auth/core/_requests'

import axios from 'axios'
import {useIntl} from "react-intl";
import {useAuth, UserBasicModel} from "../../auth";
import * as Yup from "yup";
import {SkillModel} from "../../wizards/onboarding/core/_models";
import {getLanguagesSkills} from "../../wizards/onboarding/core/_requests";
const API_URL = process.env.REACT_APP_API_URL

export interface BenefitModel {
  id: string
  name: string
}
export interface JobRoleModel {
  id: string
  name: string
  slug: string
}
export interface Questionnaire {
  companyName: string
  companyDescription: string
  benefits: BenefitModel[]
  projectDescription: string
  englishMandatory: boolean
  leadingRole: string
  skills: SkillModel[]
  experience: string
  teamDescription: string
  candidateResponsibility: string
  workType: string
  recruiterPosition: string
  recruiterEmail: string
  recruiterAlternativeContact: string
  recruiterType: string
  dedicatedOffer: number
  budgetFrom: number
  budgetTo: number
}
const initialValues = {
  companyName: '',
  companyDescription: '',
  benefits: [],
  projectDescription: '',
  englishMandatory: false,
  leadingRole: '',
  skills: [],
  experience: '',
  teamDescription: '',
  candidateResponsibility: '',
  workType: '',
  recruiterPosition: '',
  recruiterEmail: '',
  recruiterAlternativeContact: '',
  recruiterType: '',
  dedicatedOffer: 0,
  budgetFrom: 0,
  budgetTo: 0,
}

export function getBenefits() {
  return axios.get<BenefitModel[]>(`${API_URL}/api/benefits`)
}

export function getJobRoles() {
  return axios.get<JobRoleModel[]>(`${API_URL}/api/job_roles`)
}

const allExperiences = [
  // { id: 'none', name: 'no experience' },
  { id: 'junior', name: 'junior' },
  { id: 'middle', name: 'middle' },
  { id: 'senior', name: 'senior' },
  { id: 'lead', name: 'lead' },
];

const teamDescriptions = [
  { id: 'team_description_1', name: 'The vacancy does not include teamwork, only individual tasks' },
  { id: 'team_description_2', name: 'The candidate will work in a large IT department, we do not have a team division' },
  { id: 'team_description_3', name: 'The candidate will work in a component team (for example backend team, frontend team, DevOps team, etc.)' },
  { id: 'team_description_4', name: 'The candidate will work in a cross-functional team made up of experts from different fields of expertise' },
  { id: 'team_description_5', name: 'Full scrum team or agile team' },
];

const candidateResponsibilities = [
  { id: 'candidate_responsibility_1', name: 'The candidate will be responsible only for a specific task (ticket) that they implement' },
  { id: 'candidate_responsibility_2', name: 'The candidate, together with their team, will be fully responsible for the component or stack of the team' },
  { id: 'candidate_responsibility_3', name: 'Overall team’s responsibility for the entire product development' },
];

const workTypes = [
  { id: 'work_type_remote', name: 'Remote work only' },
  { id: 'work_type_office', name: 'Office work only' },
  { id: 'work_type_hybrid', name: 'Hybrid (office+remote)' },
];

const recruiterTypes = [
  { id: 'recruiter_type_in_house', name: 'In-house recruiter' },
  { id: 'recruiter_type_agency', name: 'Talent acquisition manager representing recruitment agency' },
];

type Props = {
  user?: UserBasicModel
}

const QuestionnaireForm: FC<Props> = ({user}) => {
  const intl = useIntl()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [benefits, setBenefits] = useState<BenefitModel[]>([]);
  const [jobRoles, setJobRoles] = useState<JobRoleModel[]>([]);
  const [languageSkills, setLanguageSkills] = useState<SkillModel[]>([]);

  const validationSchema = Yup.object().shape({
    companyName: Yup.string()
        .required(intl.formatMessage({id: 'QUESTIONNAIRE_FORM.COMPANY_NAME.ERROR.REQUIRED'})),
    companyDescription: Yup.string()
        .required(intl.formatMessage({id: 'QUESTIONNAIRE_FORM.COMPANY_DESCRIPTION.ERROR.REQUIRED'})),
    benefits: Yup.array()
        .min(1, intl.formatMessage({id: 'QUESTIONNAIRE_FORM.BENEFITS.ERROR.MIN'}))
        .required(intl.formatMessage({id: 'QUESTIONNAIRE_FORM.BENEFITS.ERROR.REQUIRED'})),
    projectDescription: Yup.string()
        .required(intl.formatMessage({id: 'QUESTIONNAIRE_FORM.PROJECT_DESCRIPTION.ERROR.REQUIRED'})),
    englishMandatory: Yup.boolean()
        .required(intl.formatMessage({id: 'QUESTIONNAIRE_FORM.ENGLISH_MANDATORY.ERROR.REQUIRED'})),
    leadingRole: Yup.string()
        .required(intl.formatMessage({id: 'QUESTIONNAIRE_FORM.LEADING_ROLE.ERROR.REQUIRED'})),
    skills: Yup.array()
        .min(1, intl.formatMessage({id: 'QUESTIONNAIRE_FORM.SKILLS.ERROR.MIN'}))
        .required(intl.formatMessage({id: 'QUESTIONNAIRE_FORM.SKILLS.ERROR.REQUIRED'})),
    experience: Yup.string()
        .required(intl.formatMessage({id: 'QUESTIONNAIRE_FORM.EXPERIENCE.ERROR.REQUIRED'})),
    teamDescription: Yup.string()
        .required(intl.formatMessage({id: 'QUESTIONNAIRE_FORM.TEAM_DESCRIPTION.ERROR.REQUIRED'})),
    candidateResponsibility: Yup.string()
        .required(intl.formatMessage({id: 'QUESTIONNAIRE_FORM.CANDIDATE_RESPONSIBILITY.ERROR.REQUIRED'})),
    workType: Yup.string()
        .required(intl.formatMessage({id: 'QUESTIONNAIRE_FORM.WORK_TYPE.ERROR.REQUIRED'})),
    // recruiterPosition: Yup.string()
    //     .required(intl.formatMessage({id: 'QUESTIONNAIRE_FORM.RECRUITER_POSITION.ERROR.REQUIRED'})),
    recruiterEmail: Yup.string()
        .email(intl.formatMessage({id: 'QUESTIONNAIRE_FORM.RECRUITER_EMAIL.ERROR.EMAIL'}))
        .required(intl.formatMessage({id: 'QUESTIONNAIRE_FORM.RECRUITER_EMAIL.ERROR.REQUIRED'})),
    // recruiterAlternativeContact: Yup.string()
    //     .required(intl.formatMessage({id: 'QUESTIONNAIRE_FORM.RECRUITER_ALTERNATIVE_CONTACT.ERROR.REQUIRED'})),
    recruiterType: Yup.string()
        .required(intl.formatMessage({id: 'QUESTIONNAIRE_FORM.RECRUITER_TYPE.ERROR.REQUIRED'})),
    // dedicatedOffer: Yup.number()
    //     .required(intl.formatMessage({id: 'QUESTIONNAIRE_FORM.DEDICATED_OFFER.ERROR.REQUIRED'})),
    budgetFrom: Yup.number()
        .required(intl.formatMessage({id: 'QUESTIONNAIRE_FORM.BUDGET_FROM.ERROR.REQUIRED'})),
    budgetTo: Yup.number()
        .required(intl.formatMessage({id: 'QUESTIONNAIRE_FORM.BUDGET_TO.ERROR.REQUIRED'})),

  })

  const postQuestionnaire = (values: Questionnaire, userSlug: string) => {
    return axios.post(`${API_URL}/rest/api/proposals/${userSlug}`, values)
  }

  const submitStep = async (values : Questionnaire , actions: FormikValues) => {
    console.log(values);
    setLoading(true)
    setError(false)
    setSuccess(false)
    try {
      if(user?.url){
        const {data: auth} = await postQuestionnaire(values, user?.url)
        setLoading(false)
        setSuccess(true)
        actions.resetForm()
      }else{
        setError(true)
        setLoading(false)
      }
    } catch (error) {
      setError(true)
      setLoading(false)
      // actions.resetForm()
    }
  }

  const errorDiv = (msg: any) => {
    return <div className='notice bg-light-danger rounded border-danger border border-dashed p-2 px-4 w-100 text-danger fw-bold mt-2 text-center'>{msg}</div>;
  }

  useEffect(() => {
    const updateBenefits = async () => {
      const response = await getBenefits();
      setBenefits(response.data);
    }
    const updateJobRoles = async () => {
      const response = await getJobRoles();
      setJobRoles(response.data);
    }
    const updateLanguageSkills = async () => {
      const response = await getLanguagesSkills();
      setLanguageSkills(response.data);
    }

    updateBenefits().catch(console.error);
    updateJobRoles().catch(console.error);
    updateLanguageSkills().catch(console.error);
  }, [])

  return (
    <div className='d-flex flex-column flex-xl-row flex-row-fluid mt-0' id='questionnaire_form'>
      <div className='d-flex flex-row-fluid flex-center bg-body rounded'>

        {success &&
        <div className='mw-500px px-1 py-3 m-auto text-center'>
          <div className='px-1 py-3 m-auto text-center fs-4 mt-4'>
            <div className='notice d-flex bg-light-success rounded border-success border border-dashed p-6 w-100 text-success fw-bold'>
              {intl.formatMessage({id: 'QUESTIONNAIRE_FORM.SUCCESS'})}
            </div>
          </div>
        </div>
        }

        {!success &&
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitStep}
        >
          {() => (
              <>
                <Form className="form py-10 w-100 px-9">
                  <div className="card">
                    <div className="card-header pb-10">
                      <div className="card-title fs-3 fw-bold">
                        <h2 className='fw-bolder align-items-center text-dark'>{intl.formatMessage({id: 'QUESTIONNAIRE_FORM.TITLE'})}
                          <div className="text-gray-400 fw-bold fs-3 mt-2">{intl.formatMessage({id: 'QUESTIONNAIRE_FORM.TITLE2'})}</div>
                        </h2>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row mb-20">
                        <div className="col-xl-3">
                          <div className="fs-4 fw-bolder mt-2 mb-3 text-right">{intl.formatMessage({id: 'QUESTIONNAIRE_FORM.COMPANY_NAME'})} <span className='text-danger fw-bold'>*</span></div>
                          <ErrorMessage name='companyName'>{(msg) => errorDiv(msg)}</ErrorMessage>
                        </div>
                        <div className="col-xl-9">
                          <Field type='text' className='form-control form-control-solid' placeholder={intl.formatMessage({id: 'QUESTIONNAIRE_FORM.COMPANY_NAME.PLACEHOLDER'})} name='companyName'/>
                        </div>
                      </div>
                      <div className="row mb-20">
                        <div className="col-xl-3">
                          <div className="fs-4 fw-bolder mt-2 mb-3 text-right">{intl.formatMessage({id: 'QUESTIONNAIRE_FORM.COMPANY_DESCRIPTION'})} <span className='text-danger fw-bold'>*</span></div>
                          <ErrorMessage name='companyDescription'>{(msg) => errorDiv(msg)}</ErrorMessage>
                        </div>
                        <div className="col-xl-9">
                          <Field className='form-control form-control-solid' placeholder={intl.formatMessage({id: 'QUESTIONNAIRE_FORM.COMPANY_DESCRIPTION.PLACEHOLDER'})} name='companyDescription'/>
                        </div>
                      </div>
                      <div className="row mb-20">
                        <div className="col-xl-3">
                          <div className="fs-4 fw-bolder mt-2 mb-3 text-right">{intl.formatMessage({id: 'QUESTIONNAIRE_FORM.BENEFITS'})} <span className='text-danger fw-bold'>*</span></div>
                          <ErrorMessage name='benefits'>{(msg) => errorDiv(msg)}</ErrorMessage>
                        </div>
                        <div className="col-xl-9">
                          <div className='row mb-5' data-kt-buttons='true'>
                            <>
                              {benefits.map((benefit, key) => {
                                return (
                                    <div className='col-4 mb-4' key={key}>
                                      <Field type='checkbox' className='btn-check' name='benefits' value={benefit.id} id={'questionnaire_form_benefit_' + benefit.id} />
                                      <label className='btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary w-100 p-4 text-left' htmlFor={'questionnaire_form_benefit_' + benefit.id} >
                                        <span className='fs-4'>{intl.formatMessage({id: benefit.name})}</span>
                                      </label>
                                    </div>
                                )
                              })}
                            </>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-20">
                        <div className="col-xl-3">
                          <div className="fs-4 fw-bolder mt-2 mb-3 text-right">{intl.formatMessage({id: 'QUESTIONNAIRE_FORM.PROJECT_DESCRIPTION'})} <span className='text-danger fw-bold'>*</span></div>
                          <ErrorMessage name='projectDescription'>{(msg) => errorDiv(msg)}</ErrorMessage>
                        </div>
                        <div className="col-xl-9">
                          <Field className='form-control form-control-solid' placeholder={intl.formatMessage({id: 'QUESTIONNAIRE_FORM.PROJECT_DESCRIPTION.PLACEHOLDER'})} name='projectDescription'/>
                        </div>
                      </div>
                      <div className="row mb-20">
                        <div className="col-xl-3">
                          <div className="fs-4 fw-bolder mt-2 mb-3 text-right">{intl.formatMessage({id: 'QUESTIONNAIRE_FORM.ENGLISH_MANDATORY'})}</div>
                          <ErrorMessage name='englishMandatory'>{(msg) => errorDiv(msg)}</ErrorMessage>
                        </div>
                        <div className="col-xl-9">
                          <div className="form-check form-switch form-check-custom form-check-solid">
                            <Field type="checkbox" className='form-check-input' name='englishMandatory' id='englishMandatory' />
                            {/*<label className="form-check-label fw-semibold text-gray-400 ms-3" htmlFor="englishMandatory">Allowed</label>*/}
                          </div>
                        </div>
                      </div>





                      <div className="row mb-20">
                        <div className="col-xl-3">
                          <div className="fs-4 fw-bolder mt-2 mb-3 text-right">{intl.formatMessage({id: 'QUESTIONNAIRE_FORM.LEADING_ROLE'})} <span className='text-danger fw-bold'>*</span></div>
                          <ErrorMessage name='leadingRole'>{(msg) => errorDiv(msg)}</ErrorMessage>
                        </div>
                        <div className="col-xl-9">
                          <div className='row mb-5' data-kt-buttons='true'>
                            <>
                              {jobRoles.map((jobRole, key) => {
                                return (
                                    <div className='col-4 mb-4' key={key}>
                                      <Field type='radio' className='btn-check' name='leadingRole' value={jobRole.id} id={'questionnaire_form_job_role_' + jobRole.id} />
                                      <label className='btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary w-100 p-4' htmlFor={'questionnaire_form_job_role_' + jobRole.id} >
                                        <span className='fs-4'>{intl.formatMessage({id: jobRole.name})}</span>
                                      </label>
                                    </div>
                                )
                              })}
                            </>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-20">
                        <div className="col-xl-3">
                          <div className="fs-4 fw-bolder mt-2 mb-3 text-right">{intl.formatMessage({id: 'QUESTIONNAIRE_FORM.SKILLS'})} <span className='text-danger fw-bold'>*</span></div>
                          <ErrorMessage name='skills'>{(msg) => errorDiv(msg)}</ErrorMessage>
                        </div>
                        <div className="col-xl-9">
                          <div className='row mb-5' data-kt-buttons='true'>
                            <>
                              {languageSkills.map((languageSkill, key) => {
                                return (
                                    <div className='col-4 mb-4' key={key}>
                                      <Field type='checkbox' className='btn-check' name='skills' value={languageSkill.id} id={'questionnaire_form_language_skill_' + languageSkill.id} />
                                      <label className='btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary w-100 p-4' htmlFor={'questionnaire_form_language_skill_' + languageSkill.id} >
                                        <span className='fs-4'>{languageSkill.name}</span>
                                      </label>
                                    </div>
                                )
                              })}
                            </>
                          </div>
                        </div>
                      </div>






                      <div className="row mb-20">
                        <div className="col-xl-3">
                          <div className="fs-4 fw-bolder mt-2 mb-3 text-right">{intl.formatMessage({id: 'QUESTIONNAIRE_FORM.EXPERIENCE'})} <span className='text-danger fw-bold'>*</span></div>
                          <ErrorMessage name='experience'>{(msg) => errorDiv(msg)}</ErrorMessage>
                        </div>
                        <div className="col-xl-9">
                          <div className='row mb-5' data-kt-buttons='true'>
                            <>
                              {allExperiences.map((experience, key) => {
                                return (
                                    <div className='col-3 mb-4' key={key}>
                                      <Field type='radio' className='btn-check' name='experience' value={experience.id} id={'questionnaire_form_experience_' + experience.id} />
                                      <label className='btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary w-100 p-4' htmlFor={'questionnaire_form_experience_' + experience.id} >
                                        <span className='fs-4'>{intl.formatMessage({id: experience.name})}</span>
                                      </label>
                                    </div>
                                )
                              })}
                            </>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-20">
                        <div className="col-xl-3">
                          <div className="fs-4 fw-bolder mt-2 mb-3 text-right">{intl.formatMessage({id: 'QUESTIONNAIRE_FORM.TEAM_DESCRIPTION'})} <span className='text-danger fw-bold'>*</span></div>
                          <ErrorMessage name='teamDescription'>{(msg) => errorDiv(msg)}</ErrorMessage>
                        </div>
                        <div className="col-xl-9">
                          <div className='row mb-5' data-kt-buttons='true'>
                            <>
                              {teamDescriptions.map((teamDescription, key) => {
                                return (
                                    <div className='col-12 mb-4' key={key}>
                                      <Field type='radio' className='btn-check' name='teamDescription' value={teamDescription.id} id={'questionnaire_form_team_description_' + teamDescription.id} />
                                      <label className='btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary w-100 p-4 text-left' htmlFor={'questionnaire_form_team_description_' + teamDescription.id} >
                                        <span className='fs-4'>{intl.formatMessage({id: teamDescription.name})}</span>
                                      </label>
                                    </div>
                                )
                              })}
                            </>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-20">
                        <div className="col-xl-3">
                          <div className="fs-4 fw-bolder mt-2 mb-3 text-right">{intl.formatMessage({id: 'QUESTIONNAIRE_FORM.CANDIDATE_RESPONSIBILITY'})} <span className='text-danger fw-bold'>*</span></div>
                          <ErrorMessage name='candidateResponsibility'>{(msg) => errorDiv(msg)}</ErrorMessage>
                        </div>
                        <div className="col-xl-9">
                          <div className='row mb-5' data-kt-buttons='true'>
                            <>
                              {candidateResponsibilities.map((candidateResponsibility, key) => {
                                return (
                                    <div className='col-12 mb-4' key={key}>
                                      <Field type='radio' className='btn-check' name='candidateResponsibility' value={candidateResponsibility.id} id={'questionnaire_form_candidate_responsibility_' + candidateResponsibility.id} />
                                      <label className='btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary w-100 p-4 text-left' htmlFor={'questionnaire_form_candidate_responsibility_' + candidateResponsibility.id} >
                                        <span className='fs-4'>{intl.formatMessage({id: candidateResponsibility.name})}</span>
                                      </label>
                                    </div>
                                )
                              })}
                            </>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-5">
                        <div className="col-xl-3">
                          <div className="fs-4 fw-bolder mt-2 mb-3 text-right">{intl.formatMessage({id: 'QUESTIONNAIRE_FORM.WORK_TYPE'})} <span className='text-danger fw-bold'>*</span></div>
                          <ErrorMessage name='workType'>{(msg) => errorDiv(msg)}</ErrorMessage>
                        </div>
                        <div className="col-xl-9">
                          <div className='row mb-5' data-kt-buttons='true'>
                            <>
                              {workTypes.map((workType, key) => {
                                return (
                                    <div className='col-12 mb-4' key={key}>
                                      <Field type='radio' className='btn-check' name='workType' value={workType.id} id={'questionnaire_form_work_type_' + workType.id} />
                                      <label className='btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary w-100 p-4 text-left' htmlFor={'questionnaire_form_work_type_' + workType.id} >
                                        <span className='fs-4'>{intl.formatMessage({id: workType.name})}</span>
                                      </label>
                                    </div>
                                )
                              })}
                            </>
                          </div>
                        </div>
                      </div>

                      <hr className='mb-10' />

                      <div className="row mb-20">
                        <div className="col-xl-3">
                          <div className="fs-4 fw-bolder mt-2 mb-3 text-right">{intl.formatMessage({id: 'QUESTIONNAIRE_FORM.RECRUITER_POSITION'})}</div>
                          <ErrorMessage name='recruiterPosition'>{(msg) => errorDiv(msg)}</ErrorMessage>
                        </div>
                        <div className="col-xl-9">
                          <Field className='form-control form-control-solid' placeholder={intl.formatMessage({id: 'QUESTIONNAIRE_FORM.RECRUITER_POSITION.PLACEHOLDER'})} name='recruiterPosition'/>
                        </div>
                      </div>
                      <div className="row mb-20">
                        <div className="col-xl-3">
                          <div className="fs-4 fw-bolder mt-2 mb-3 text-right">{intl.formatMessage({id: 'QUESTIONNAIRE_FORM.RECRUITER_EMAIL'})} <span className='text-danger fw-bold'>*</span></div>
                          <ErrorMessage name='recruiterEmail'>{(msg) => errorDiv(msg)}</ErrorMessage>
                        </div>
                        <div className="col-xl-9">
                          <Field className='form-control form-control-solid' placeholder={intl.formatMessage({id: 'QUESTIONNAIRE_FORM.RECRUITER_EMAIL.PLACEHOLDER'})} name='recruiterEmail'/>
                        </div>
                      </div>
                      <div className="row mb-10">
                        <div className="col-xl-3">
                          <div className="fs-4 fw-bolder mt-2 mb-3 text-right">{intl.formatMessage({id: 'QUESTIONNAIRE_FORM.RECRUITER_ALTERNATIVE_CONTACT'})}</div>
                          <ErrorMessage name='recruiterAlternativeContact'>{(msg) => errorDiv(msg)}</ErrorMessage>
                        </div>
                        <div className="col-xl-9">
                          <Field className='form-control form-control-solid' placeholder={intl.formatMessage({id: 'QUESTIONNAIRE_FORM.RECRUITER_ALTERNATIVE_CONTACT.PLACEHOLDER'})} name='recruiterAlternativeContact'/>
                        </div>
                      </div>
                      <div className="row mb-10">
                        <div className="col-xl-3">
                          <div className="fs-4 fw-bolder mt-2 mb-3 text-right">{intl.formatMessage({id: 'QUESTIONNAIRE_FORM.RECRUITER_TYPE'})} <span className='text-danger fw-bold'>*</span></div>
                          <ErrorMessage name='recruiterType'>{(msg) => errorDiv(msg)}</ErrorMessage>
                        </div>
                        <div className="col-xl-9">
                          <div className='row mb-5' data-kt-buttons='true'>
                            <>
                              {recruiterTypes.map((recruiterType, key) => {
                                return (
                                    <div className='col-12 mb-4' key={key}>
                                      <Field type='radio' className='btn-check' name='recruiterType' value={recruiterType.id} id={'questionnaire_form_recruiter_type_' + recruiterType.id} />
                                      <label className='btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary w-100 p-4 text-left' htmlFor={'questionnaire_form_recruiter_type_' + recruiterType.id} >
                                        <span className='fs-4'>{intl.formatMessage({id: recruiterType.name})}</span>
                                      </label>
                                    </div>
                                )
                              })}
                            </>
                          </div>
                        </div>
                      </div>

                      <hr className='mb-10' />

                      <div className="row mb-10">
                        <div className="col-xl-3">
                          <div className="fs-4 fw-bolder mt-2 mb-3 text-right">{intl.formatMessage({id: 'QUESTIONNAIRE_FORM.BUDGET_FROM'})} <span className='text-danger fw-bold'>*</span></div>
                        </div>
                        <div className="col-xl-9">
                          <div className='row'>
                            <div className='col-6'>
                              <Field type='number' className='form-control form-control-solid' placeholder={intl.formatMessage({id: 'QUESTIONNAIRE_FORM.BUDGET_FROM.PLACEHOLDER'})} name='budgetFrom'/>
                              <ErrorMessage name='budgetFrom'>{(msg) => errorDiv(msg)}</ErrorMessage>
                            </div>
                            <div className='col-6'>
                              <Field type='number' className='form-control form-control-solid' placeholder={intl.formatMessage({id: 'QUESTIONNAIRE_FORM.BUDGET_TO.PLACEHOLDER'})} name='budgetTo'/>
                              <ErrorMessage name='budgetTo'>{(msg) => errorDiv(msg)}</ErrorMessage>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-3">
                          <div className="fs-4 fw-bolder mt-2 mb-3 text-right">{intl.formatMessage({id: 'QUESTIONNAIRE_FORM.DEDICATED_OFFER'})}</div>
                          <ErrorMessage name='dedicatedOffer'>{(msg) => errorDiv(msg)}</ErrorMessage>
                        </div>
                        <div className="col-xl-9">
                          <Field type='number' className='form-control form-control-solid' placeholder={intl.formatMessage({id: 'QUESTIONNAIRE_FORM.DEDICATED_OFFER.PLACEHOLDER'})} name='dedicatedOffer'/>
                        </div>
                      </div>


                      {/*<div className="position-relative w-md-300px" data-kt-dialer="true" data-kt-dialer-min="1000" data-kt-dialer-max="50000" data-kt-dialer-step="1000" data-kt-dialer-prefix="$" data-kt-dialer-decimals="2">*/}
                      {/*  <button type="button" className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0" data-kt-dialer-control="decrease">*/}
                      {/*        <span className="svg-icon svg-icon-1">*/}
                      {/*          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                      {/*            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/><rect x="6.0104" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>*/}
                      {/*          </svg>*/}
                      {/*        </span>*/}
                      {/*  </button>*/}
                      {/*  <input type="text" className="form-control form-control-solid border-0 ps-12" data-kt-dialer-control="input" placeholder="Amount" name="manageBudget" readOnly={true} value="$36,000.00"/>*/}
                      {/*  <button type="button" className="btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0" data-kt-dialer-control="increase">*/}
                      {/*        <span className="svg-icon svg-icon-1">*/}
                      {/*          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                      {/*            <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"/><rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/><rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>*/}
                      {/*          </svg>*/}
                      {/*        </span>*/}
                      {/*  </button>*/}
                      {/*</div>*/}


                    </div>
                    <div className='mw-500px px-1 py-3 m-auto text-center'>
                      <div className='fs-2x fw-bold'>{intl.formatMessage({id: 'QUESTIONNAIRE_FORM.FOOTER.TITLE'})}</div>
                      <div className='text-gray-400 fw-bold fs-4 mt-4'>{intl.formatMessage({id: 'QUESTIONNAIRE_FORM.FOOTER.SUBTITLE'})}</div>
                      {error &&
                      <div className='px-1 py-3 m-auto text-center fs-4 mt-4'>
                        <div className='notice d-flex bg-light-danger rounded border-danger border border-dashed p-6 w-100 text-danger fw-bold'>
                          {intl.formatMessage({id: 'QUESTIONNAIRE_FORM.ERROR'})}
                        </div>
                      </div>
                      }
                    </div>
                    <div className='d-flex pt-5 align-items-center'>
                      <div className='w-200px px-1 py-3 m-auto text-center fs-4'>
                        {!loading &&
                        <button type='submit' className='btn btn-lg btn-primary'>
                          <span className='indicator-label'>
                            {intl.formatMessage({id: 'QUESTIONNAIRE_FORM.SUBMIT'})}
                            <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-3 ms-2 me-0'/>
                          </span>
                        </button>
                        }
                        {loading && <span className="spinner-border text-primary" role="status"/>}
                      </div>
                    </div>
                  </div>
                </Form>
              </>
          )}
        </Formik>
        }
      </div>
    </div>
  )
}

export {QuestionnaireForm}
