import React, {FC} from 'react'
import {ProposalSerialized} from "../../../pages/dashboard/components/Proposals";
import {useIntl} from "react-intl";
import {toAbsoluteUrl} from "../../../../_metronic/helpers";
import {OverlayTrigger} from "react-bootstrap";

type Props = {
    icon: string
    title: string
    subtitle: string
    image: string
    extraDeal?: boolean
}

const ReferralCard: FC<Props> = ({ icon, title, subtitle, image, extraDeal = false }) => {
    const intl = useIntl()

    return (
        <div
            className={'card border border-1 border-dark border-hover-primary landing-dark-bg shadow-lg'}
            style={{
                backgroundColor: '#0d1a2a'
            }}
        >
            {/*<OverlayTrigger*/}
            {/*    // key={`${twit.id}`}*/}
            {/*    key={`e131231231232312`}*/}
            {/*    placement='top'*/}
            {/*    overlay={*/}
            {/*        <span className="badge px-4 fs-7 badge-primary border border-primary rounded-5 rounded-bottom-0" title={'EXTRA DEAL'}><i className='bi bi-fire text-white me-2'/> EXTRA DEAL</span>*/}
            {/*    }*/}
            {/*    defaultShow={false} delay={0} flip={undefined} onHide={undefined} onToggle={undefined} popperConfig={undefined} show={undefined} target={undefined} trigger={undefined}*/}
            {/*>*/}
                <div>
                    <div className='card-body ribbon ribbon-top'>
                        {extraDeal && <div className="ribbon-label bg-primary"><i className='bi bi-rocket-takeoff text-white me-2'/> EXCLUSIVE</div>}

                        <div className='cursor-pointer text-left' onClick={() => { }}>
                            <div className='text-white fs-2 fw-bold px-2'>
                                <div className='w-50px d-inline-block'><i className={'bi text-white fs-3x ' + icon } /></div>
                                { title }
                            </div>
                            <div className='px-2 py-2'>
                                <p className='text-white fs-5 mt-0 mb-0'>{ subtitle }</p>
                            </div>

                            {/*<img title='' className='rounded rounded-3 w-100 cursor-pointer h-250px' style={{objectFit: 'cover'}} src={image} alt={''} onClick={() => {  }} />*/}
                        </div>
                    </div>
                </div>
            {/*</OverlayTrigger>*/}
        </div>
    )
}

export {ReferralCard}
