/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {CardsWidget17} from "../../../../_metronic/partials/widgets";
import {useAuth} from "../../../modules/auth";
import clsx from "clsx";
import {useIntl} from "react-intl";

type Props = {
  className: string
  color: string
}

const OnboardingSteps: React.FC<Props> = ({className, color}) => {
  const intl = useIntl()
  const {currentUser} = useAuth()

  return (
      <div className={`${className}`}>
        <div className='p-0'>
          <div className='shadow-xs card-rounded mx-4 mb-6 px-4 py-4 pb-4 position-relative z-index-1 bg-body'>
            <div className='text-center cursor-pointer card-label fw-bold text-dark align-items-center align-content-center' role='button' data-bs-toggle='collapse' data-bs-target='#dashboard_onboarding_steps'>
              {currentUser?.onboarding.currentStep === null && <i className='bi fs-5 text-success bi-check2-square me-2' />}
              <span className='fs-5'>{intl.formatMessage({id: 'ONBOARDING_STEPS.TITLE'})}</span>
            </div>
            <div id='dashboard_onboarding_steps' className={'collapse' + (currentUser?.onboarding.currentStep === null ? '': 'show')}>

              <div className={'row mt-6'}>
                <div className={'col-4'}>
                  <div className={clsx('d-flex align-items-center', {'border-1 rounded bg-light-primary border border-primary': currentUser?.onboarding.currentStep === 'skills'})} >
                    <div className='w-75px px-1 py-3 me-2 text-center fs-4'>
                      <span className={clsx('badge py-3 px-4 fs-7 ', {
                        'badge-light-primary': currentUser?.onboarding.isSkillFilled === false,
                        'badge-light-success': currentUser?.onboarding.isSkillFilled === true
                      })} >
                        <i className={clsx('bi fs-1', {
                          'text-primary': currentUser?.onboarding.isSkillFilled === false,
                          'text-success': currentUser?.onboarding.isSkillFilled === true,
                          'bi-box-arrow-down': currentUser?.onboarding.currentStep === 'skills',
                          'bi-square': currentUser?.onboarding.currentStep !== 'skills' && currentUser?.onboarding.isSkillFilled === false,
                          'bi-check2-square': currentUser?.onboarding.currentStep !== 'skills' && currentUser?.onboarding.isSkillFilled === true,
                        })}/>
                      </span>
                    </div>
                    <div className='d-flex align-items-center flex-wrap w-100'>
                      <div className='mb-1 pe-3 flex-grow-1'>
                        <div className='fs-5 text-gray-800 fw-bold'>{intl.formatMessage({id: 'ONBOARDING_STEPS.SKILLS.TITLE'})}</div>
                        {currentUser?.onboarding.isSkillFilled === false && <div className='text-gray-400 fw-semibold fs-7'>{intl.formatMessage({id: 'ONBOARDING_STEPS.SKILLS.DESCRIPTION'})}</div>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'col-4'}>
                  <div className={clsx('d-flex align-items-center', {'border-1 rounded bg-light-primary border border-primary': currentUser?.onboarding.currentStep === 'questionnaire'})} >
                    <div className='w-75px px-1 py-3 me-2 text-center fs-4'>
                      <span className={clsx('badge py-3 px-4 fs-7 ', {
                        'badge-light-primary': currentUser?.onboarding.isQuestionnaireDefined === false,
                        'badge-light-success': currentUser?.onboarding.isQuestionnaireDefined === true
                      })} >
                        <i className={clsx('bi fs-1', {
                          'text-primary': currentUser?.onboarding.isQuestionnaireDefined === false,
                          'text-success': currentUser?.onboarding.isQuestionnaireDefined === true,
                          'bi-box-arrow-down': currentUser?.onboarding.currentStep === 'questionnaire',
                          'bi-square': currentUser?.onboarding.currentStep !== 'questionnaire' && currentUser?.onboarding.isQuestionnaireDefined === false,
                          'bi-check2-square': currentUser?.onboarding.currentStep !== 'questionnaire' && currentUser?.onboarding.isQuestionnaireDefined === true,
                        })}/>
                      </span>
                    </div>
                    <div className='d-flex align-items-center flex-wrap w-100'>
                      <div className='mb-1 pe-3 flex-grow-1'>
                        <div className='fs-5 text-gray-800 fw-bold'>{intl.formatMessage({id: 'ONBOARDING_STEPS.QUESTIONNAIRE.TITLE'})}</div>
                        {currentUser?.onboarding.isQuestionnaireDefined === false && <div className='text-gray-400 fw-semibold fs-7'>{intl.formatMessage({id: 'ONBOARDING_STEPS.QUESTIONNAIRE.DESCRIPTION'})}</div>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'col-4'}>
                  <div className={clsx('d-flex align-items-center', {'border-1 rounded bg-light-primary border border-primary': currentUser?.onboarding.currentStep === 'resumeUpload'})} >
                    <div className='w-75px px-1 py-3 me-2 text-center fs-4'>
                       <span className={clsx('badge py-3 px-4 fs-7 ', {
                             'badge-light-primary': currentUser?.onboarding.isResumeUploaded === false,
                             'badge-light-success': currentUser?.onboarding.isResumeUploaded === true
                           })} >
                        <i className={clsx('bi fs-1', {
                          'text-primary': currentUser?.onboarding.isResumeUploaded === false,
                          'text-success': currentUser?.onboarding.isResumeUploaded === true,
                          'bi-box-arrow-down': currentUser?.onboarding.currentStep === 'resumeUpload',
                          'bi-square': currentUser?.onboarding.currentStep !== 'resumeUpload' && currentUser?.onboarding.isResumeUploaded === false,
                          'bi-check2-square': currentUser?.onboarding.currentStep !== 'resumeUpload' && currentUser?.onboarding.isResumeUploaded === true,
                        })}/>
                      </span>
                    </div>
                    <div className='d-flex align-items-center flex-wrap w-100'>
                      <div className='mb-1 pe-3 flex-grow-1'>
                        <div className='fs-5 text-gray-800 fw-bold'>{intl.formatMessage({id: 'ONBOARDING_STEPS.RESUME.TITLE'})}</div>
                        {currentUser?.onboarding.isResumeUploaded === false && <div className='text-gray-400 fw-semibold fs-7'>{intl.formatMessage({id: 'ONBOARDING_STEPS.RESUME.DESCRIPTION'})}</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export {OnboardingSteps}
