import React, {useEffect, useState} from 'react'
import {useIntl} from "react-intl";
import {useAuth} from "../../../modules/auth";
import {getProjects} from "./_requests";
import {ProjectSerialized} from "./_models";
import {ProjectCard} from "./ProjectCard";

const Projects = () => {
  const intl = useIntl()
  const { currentUser } = useAuth()

  const [loading, setLoading] = useState(false)
  const [loadingNext, setLoadingNext] = useState(false)

  const [projects, setProjects] = useState<ProjectSerialized[]>([]);

  const [page, setPage] = useState<number>(1);
  const [nextPageAllowed, setNextPageAllowed] = useState(false)

  const nextPage = () => {
      let newPage = page + 1;
      setPage(newPage);

      setLoadingNext(true)
      setNextPageAllowed(false);

      const loadTwits = async () => {
          let {data: response} = await getProjects(newPage);

          setProjects(projects.concat(response));
          setLoadingNext(false)

          if(response.length > 0) setNextPageAllowed(true);
      }

      loadTwits().catch(() => {
          setLoadingNext(false)
      });
  }

  useEffect(() => {
      setLoading(true)

      const loadTwits = async () => {
          let {data: response} = await getProjects(1); // always load first page

          setProjects(response);
          setLoading(false)
          setPage(1);

          if(response.length > 0) setNextPageAllowed(true);
      }

      loadTwits().catch(() => {
          setLoading(false)
          setPage(1);
      });
  }, [])

  return (
      <div>
          <div className='d-flex flex-wrap flex-stack mb-6 mt-6'>
              <h3 className='fw-bolder my-2'>
                  {intl.formatMessage({id: 'PROJECTS.LIST'})}
                  {/*<span className='fs-6 text-gray-400 fw-bold ms-1'>Active</span>*/}
              </h3>

              <div className='d-flex flex-wrap my-2'>
                  <div className='me-4'>
                      <select
                          name='status'
                          data-control='select2'
                          data-hide-search='true'
                          className='form-select form-select-sm form-select-white w-125px'
                          defaultValue='all'
                      >
                          <option value='all'>{intl.formatMessage({id: 'PROJECTS.FILTERS.all'})}</option>
                          <option value='active' disabled={true}>{intl.formatMessage({id: 'PROJECTS.FILTERS.active'})}</option>
                          <option value='hiring' disabled={true}>{intl.formatMessage({id: 'PROJECTS.FILTERS.hiring'})}</option>
                          <option value='paused' disabled={true}>{intl.formatMessage({id: 'PROJECTS.FILTERS.paused'})}</option>
                          <option value='closed' disabled={true}>{intl.formatMessage({id: 'PROJECTS.FILTERS.closed'})}</option>
                      </select>
                  </div>
              </div>
          </div>

          {loading &&
          <div className='mw-500px px-1 py-3 m-auto text-center'>
              <span className="spinner-border text-primary" role="status"/>
          </div>
          }

        {(!loading && projects) &&
            <>
              <div className='row g-6 row-cols-1 row-cols-xl-2'>
                {projects.map((project, key) => {
                  return (
                      <div className='col' key={key}>
                          <ProjectCard project={project} />
                      </div>
                  )
                })}
              </div>

                {loadingNext &&
                    <div className='mw-500px mt-10 px-1 py-3 m-auto text-center'>
                        <span className="spinner-border text-primary" role="status"/>
                    </div>
                }
                {(!loadingNext && nextPageAllowed) &&
                    <div className='mw-300px m-auto mt-10 mb-2 align-items-center px-5 py-3'>
                        <button
                            className='btn btn-light-primary border border-dashed border-primary hover-scale w-100 align-items-center'
                            onClick={nextPage}
                        >
                            {/*<i className="bi bi-columns-gap px-0 me-2 fs-3"/>*/}
                            {/*<i className="bi bi-hand-index-thumb px-0 me-2 fs-3"/>*/}
                            {/*<i className="bi bi-hand-index px-0 me-2 fs-3"/>*/}
                            <i className="bi bi-chevron-double-down px-0 me-2 fs-3"/>
                            Wczytaj kolejne
                        </button>
                    </div>
                }
            </>
        }

          {(!loading && projects.length === 0) &&
              <>
                  <div className='alert alert-primary'>
                      {intl.formatMessage({id: 'PROJECTS.LIST.NOT_FOUND'})}
                  </div>
              </>
          }
      </div>
  )
}

export {Projects}
