import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {ProfileBuilderPage} from "../modules/profile-builder/ProfileBuilderPage";
import {JobsPage} from "../modules/jobs/JobsPage";
import {NewsletterPage} from "../modules/newsletter/NewsletterPage";
import {ProjectsPage} from "../modules/projects/ProjectsPage";
import {TasksPage} from "../modules/tasks/TasksPage";

const PrivateRoutes = () => {
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='proposals' element={<DashboardWrapper />} />
        <Route path='projects' element={<ProjectsPage />} />
        <Route path='tasks' element={<TasksPage />} />
        <Route path='profile-builder' element={<ProfileBuilderPage />} />
        <Route path='jobs/:skillSlug' element={<JobsPage />} />
        <Route path='newsletters/zdalny-dev' element={<NewsletterPage />} />
        <Route path='*' element={<Navigate to='/crm/dashboard' />} />

        {/*<Route path='builder' element={<BuilderPageWrapper />} />*/}
        {/* Lazy Modules */}
        {/*<Route*/}
        {/*  path='crafted/account/*'*/}
        {/*  element={*/}
        {/*    <SuspensedView>*/}
        {/*      <AccountPage />*/}
        {/*    </SuspensedView>*/}
        {/*  }*/}
        {/*/>*/}
      </Route>
    </Routes>
  )
}

// const SuspensedView: FC<WithChildren> = ({children}) => {
//   const baseColor = getCSSVariableValue('--kt-primary')
//   TopBarProgress.config({
//     barColors: {
//       '0': baseColor,
//     },
//     barThickness: 1,
//     shadowBlur: 5,
//   })
//   return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
// }

export {PrivateRoutes}
