import React, {FC, useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Formik, Form, FormikValues, Field, ErrorMessage} from 'formik'
import {useAuth} from "../../../modules/auth";
import {getUserByToken} from '../../../modules/auth/core/_requests'

import axios from 'axios'
import {useIntl} from "react-intl";
const API_URL = process.env.REACT_APP_API_URL
export const UPDATE_USER_URL = `${API_URL}/rest/api/users/me/upload-resume`

type Props = {
  initialUrl?: string
}

const ResumeUploader: FC<Props> = ({ initialUrl = '' }) => {
  const intl = useIntl()
  const {setCurrentUser} = useAuth()

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const updateUser = (values: { url: string }) => {
    return axios.post(UPDATE_USER_URL, values)
  }

  const submitStep = async (values : { url: string } , actions: FormikValues) => {
    setLoading(true)
    setError(false)
    setSuccess(false)
    try {
      const {data: auth} = await updateUser(values)
      const {data: user} = await getUserByToken(auth.token)
      setCurrentUser(user)
      setLoading(false)
      setSuccess(true)
    } catch (error) {
      setError(true)
      setLoading(false)
    }
  }

  return (
    <div className='d-flex flex-column flex-xl-row flex-row-fluid mt-0' id='resume_uploader'>
      <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
        <Formik initialValues={{ url: initialUrl }} onSubmit={submitStep} >
          {() => (
            <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
              <div className='current'>
                <div className='w-100'>
                  <div className='pb-10 pb-lg-15'>
                    <h2 className='fw-bolder text-dark'>
                      {intl.formatMessage({id: 'RESUME_UPLOADER.TITLE'})} &#160;<sup><span className="badge badge-primary">{intl.formatMessage({id: 'OPTIONAL'})}</span></sup>
                    </h2>

                    <div className='text-gray-400 fw-bold fs-6'>
                      {intl.formatMessage({id: 'RESUME_UPLOADER.DESCRIPTION'})}
                    </div>
                  </div>

                  <div className='d-flex flex-column mb-7 fv-row'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                      {intl.formatMessage({id: 'RESUME_UPLOADER.URL.TITLE'})}
                    </label>
                    <Field type='text' className='form-control form-control-solid' placeholder={intl.formatMessage({id: 'RESUME_UPLOADER.URL.PLACEHOLDER'})} name='url'/>
                    <div className='text-danger mt-2'>
                      <ErrorMessage name='url' />
                    </div>
                  </div>

                </div>
              </div>

              <div className='d-flex pt-5 align-items-center'>
                <div className='d-flex align-items-center flex-wrap w-100'>
                  {error &&
                  <div className='notice d-flex bg-light-danger rounded border-danger border border-dashed p-6 w-100 text-danger fw-bold'>
                    {intl.formatMessage({id: 'RESUME_UPLOADER.ERROR'})}
                  </div>
                  }
                  {success &&
                  <div className='notice d-flex bg-light-success rounded border-success border border-dashed p-6 w-100 text-success fw-bold'>
                    {!initialUrl && intl.formatMessage({id: 'RESUME_UPLOADER.SUCCESS'})}
                    {initialUrl && intl.formatMessage({id: 'RESUME_UPLOADER.CHANGE_SUCCESS'})}
                  </div>
                  }
                </div>
                <div className='w-200px px-1 py-3 m-auto text-center fs-4'>
                  {!loading &&
                  <button type='submit' className='btn btn-lg btn-primary'>
                      <span className='indicator-label'>
                        {intl.formatMessage({id: 'SAVE'})}
                        <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-3 ms-2 me-0'/>
                      </span>
                  </button>
                  }
                  {loading && <span className="spinner-border text-primary" role="status"/>}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export {ResumeUploader}
