import {FC} from 'react'
import {Link} from 'react-router-dom'
import {ProposalSerialized} from "../../../pages/dashboard/components/Proposals";
import {useIntl} from "react-intl";
import {toAbsoluteUrl} from "../../../../_metronic/helpers";

type Props = {
    badgeColor: string
    statusColor: string
    progress: number
    proposal: ProposalSerialized
    sample: boolean
}

const ProposalCard: FC<Props> = ({ badgeColor, statusColor, progress, proposal, sample = false }) => {
    const intl = useIntl()

    return (
        <div
            // to='/proposal-evaluation'
            className='card border border-1 border-secondary border-hover-primary'
        >
            <div style={{opacity: sample ? 0.3 : 1}}>
                <div className='card-header border-0 pt-9'>
                    <div className='w-100px'>
                  <span className={`badge badge-light-${badgeColor} me-auto px-4 py-3 fs-4`}>
                    {proposal.answers.leadingRole?.name}
                  </span>
                    </div>
                    <div className='card-title m-0'>
                        {proposal.answers.skills.map((skill, key) => {
                            return (
                                <div key={key} className="skill-color-3 technology-button">
                                    <i className={skill.svgIcon} title={skill.name} />
                                </div>
                            )
                        })}
                    </div>
                    <div>
                  <span className={`badge badge-light-${badgeColor} me-auto px-4 py-3 fs-4`}>
                    {proposal.answers.experience}
                  </span>
                        {proposal.answers.englishMandatory && <img title={intl.formatMessage({id: 'PROPOSAL_CARD.ENGLISH_MANDATORY'})} className='w-20px h-20px rounded-1 ms-2' src={toAbsoluteUrl('/media/flags/united-states.svg')}  alt={intl.formatMessage({id: 'PROPOSAL_CARD.ENGLISH_MANDATORY'})} />}
                    </div>
                </div>
                <div className='card-body p-5'>
                    <div>
                        <div className='h-7px w-100 bg-light' data-bs-toggle='tooltip' title={`${progress}%`}>
                            <div className={`bg-${statusColor} rounded h-7px`} role='progressbar' style={{width: `${progress}%`}} />
                        </div>
                        <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                            <span className="fw-bolder fs-6 text-dark">&#160;</span>
                            <span className="fs-6 text-gray-400">{intl.formatMessage({id: 'PROPOSAL_CARD.MATCH'})} {progress}%</span>
                        </div>
                    </div>

                    <div style={{marginTop: '-12px', marginBottom: '12px'}}>
                        <div className='fs-3 fw-bolder text-dark'>{proposal.answers.companyName}</div>
                        <p className='text-gray-400 fs-5 mt-0 mb-0'>{proposal.answers.companyDescription}</p>
                        {/*<p className='text-gray-400 fw-bold fs-5 mt-0 mb-7'></p>*/}
                        <div className='text-gray-800'>
                            {proposal.answers.projectDescription}
                        </div>
                    </div>

                    <div className='row mb-2'>
                        <div className='col-4' title={intl.formatMessage({id: 'PROPOSAL_CARD.BUDGET_FROM.DESCRIPTION'})}>
                            <div className='border border-1 border-gray-300 border-dashed rounded py-3 px-4 me-2 mb-3 w-100 text-right'>
                                <div className='fs-6 text-gray-800 fw-bolder'>
                                    {proposal.answers.budgetFrom ? (
                                        <>
                                            {proposal.answers.budgetFrom} <span className='text-gray-800'>PLN</span>
                                        </>
                                    ) : (
                                        <>
                                            -
                                        </>
                                    )}
                                </div>
                                <div className='text-gray-400'>
                                    {intl.formatMessage({id: 'PROPOSAL_CARD.BUDGET_FROM'})}
                                </div>
                            </div>
                        </div>
                        <div className='col-4' title={intl.formatMessage({id: 'PROPOSAL_CARD.DEDICATED_OFFER.DESCRIPTION'})}>
                            <div className='border border-dashed rounded border-primary border-1 bg-light-primary p-4 text-center w-100'>
                                <div className='fs-6 text-gray-800 fw-bolder'>
                                    {proposal.answers.dedicatedOffer ? (
                                        <>
                                            {proposal.answers.dedicatedOffer} <span className='text-gray-800'>PLN</span>
                                        </>
                                    ) : (
                                        <>
                                            -
                                        </>
                                    )}
                                </div>
                                <div className='text-gray-400'>
                                    {intl.formatMessage({id: 'PROPOSAL_CARD.DEDICATED_OFFER'})}
                                </div>
                            </div>
                        </div>
                        <div className='col-4' title={intl.formatMessage({id: 'PROPOSAL_CARD.BUDGET_TO.DESCRIPTION'})}>
                            <div className='border border-1 border-gray-300 border-dashed rounded py-3 px-4 me-2 mb-3 w-100 text-left'>
                                <div className='fs-6 text-gray-800 fw-bolder'>
                                    {proposal.answers.budgetTo ? (
                                        <>
                                            {proposal.answers.budgetTo} <span className='text-gray-800'>PLN</span>
                                        </>
                                    ) : (
                                        <>
                                            -
                                        </>
                                    )}
                                </div>
                                <div className='text-gray-400'>
                                    {intl.formatMessage({id: 'PROPOSAL_CARD.BUDGET_TO'})}
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr />
                    <div className='px-2'>
                        <div className='text-gray-800'>
                            {intl.formatMessage({id: 'TEAM_DESCRIPTIONS.' + proposal.answers.teamDescription})}
                        </div>
                        <div className="separator separator-dashed my-2" />
                        <div className='text-gray-800'>
                            {intl.formatMessage({id: 'CANDIDATE_RESPONSIBILITIES.' + proposal.answers.candidateResponsibility})}
                        </div>
                        <div className="separator separator-dashed my-2" />
                        <div className='text-gray-800'>
                            {intl.formatMessage({id: 'WORK_TYPES.' + proposal.answers.workType})}
                        </div>
                    </div>
                    <hr className='mb-2' />
                    <div>
                        {proposal.answers.benefits.map((benefit, key) => {
                            return (
                                <div key={key} className='border border-1 border-gray-300 border-dashed rounded py-1 px-2 mb-2 text-left'>
                                    <i className='text-primary bi bi-check' /> {intl.formatMessage({id: benefit.name})}
                                </div>
                            )
                        })}
                    </div>
                    <hr className='mb-2' />
                    <div className='row text-gray-500'>
                        <div className='col-6 text-center'>
                            <div>
                                {proposal.answers.recruiterEmail}
                            </div>
                            <small>
                                {proposal.answers.recruiterAlternativeContact}
                            </small>
                        </div>
                        <div className='col-6 text-center'>
                            <div>
                                {intl.formatMessage({id: 'RECRUITER_TYPES.' + proposal.answers.recruiterType})}
                            </div>
                            <small>
                                {proposal.answers.recruiterPosition}
                            </small>
                        </div>
                    </div>
                </div>
            </div>

            {sample &&
            <div className='d-flex' style={{position: "absolute", top: 0, left: 0, width: '100%', height: '100%', alignContent: "center", textAlign: 'center', alignItems: 'center'}}>
                <div className="bg-light-primary text-primary border border-primary rounded m-9 p-9 w-100 shadow align-content-center align-items-center d-flex">
                    <i className="bi bi-patch-question text-primary fs-3x me-4"/>
                    <div className='w-100 fs-4'>
                        Tutaj będzie widoczne w pełni wypełnione zaproszenie od rekrutera!
                        <small className='text-gray-500 pt-2 d-block fs-7'>
                            ..zniknę gdy pojawi się pierwsze prawdziwe <br />
                            Zaproszenie będzie można <strong>Odrzucić</strong> lub <strong>Dodać do przetwarzania</strong>.
                        </small>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export {ProposalCard}
