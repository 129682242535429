import {Suspense} from 'react'
import {Outlet, useSearchParams} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import { GoogleOAuthProvider } from '@react-oauth/google';

const App = () => {
    const [searchParams] = useSearchParams();

    let refferalId = searchParams.get('ref');
    if(refferalId){
        if(localStorage.getItem('referralId') === null){
            localStorage.setItem('referralId', refferalId)
        }
    }

    return (
      <GoogleOAuthProvider clientId="893146833411-tnn0k0s9mnq0vce1dju5et8r79p2hb53.apps.googleusercontent.com" >
        <Suspense fallback={<LayoutSplashScreen />}>
          <I18nProvider>
            <LayoutProvider>
              <AuthInit>
                <Outlet />
                <MasterInit />
              </AuthInit>
            </LayoutProvider>
          </I18nProvider>
        </Suspense>
      </GoogleOAuthProvider>
  )
}

export {App}
