import React, {useEffect, useState} from 'react'
import {TwitCard} from "../../../modules/twits/components/TwitCard";
import {TwitModal} from "../../../modules/twits/components/TwitModal";
import {TwitShareModal} from "../../../modules/twits/components/TwitShareModal";
import {TwitsFilter} from "../../../modules/twits/components/TwitsFilter";
import {TopicsModal} from "../../../modules/twits/components/TopicsModal";
import {useLocation} from 'react-router-dom';
import {useIntl} from "react-intl";
import {TwitListInterface, TwitSerialized} from "../../../modules/twits/components/_models";
import {
    getTwits,
    getTwit,
    patchTwitUpvote,
    postTwitBookmark,
    patchVerifyTwits, isTwitBookmark, isTwitUpvoted
} from "../../../modules/twits/components/_requests";
import {TopicsSelection} from "../../../modules/twits/components/TopicsSelection";
import {LoginModal} from "../../../modules/twits/components/LoginModal";
import {useAuth} from "../../../modules/auth";

const Twits = (twitList: TwitListInterface) => {
  const intl = useIntl()
  const { currentUser } = useAuth()
  const originalPathname = useLocation().pathname;

  const [loading, setLoading] = useState(false)
  const [loadingNext, setLoadingNext] = useState(false)
  const [twitLoading, setTwitLoading] = useState(false); // temporary state to refresh rendering - not working without that , no idea why :D

  const [twitOpened, setTwitOpened] = useState<TwitSerialized|null>(null);
  const [twitShareOpened, setTwitShareOpened] = useState<TwitSerialized|null>(null);
  const [topicsOpened, setTopicsOpened] = useState<boolean>(false);
  const [loginOpened, setLoginOpened] = useState<boolean>(false);
  const [twits, setTwits] = useState<TwitSerialized[]>([]);

  const [page, setPage] = useState<number>(1);
  const [nextPageAllowed, setNextPageAllowed] = useState(false)
  const [topicsAllowed, setTopicsAllowed] = useState(true)

  const onTwitOpen = (twit: TwitSerialized) => {
    setTwitOpened(twit);
    window.history.pushState({ additionalInformation: intl.formatMessage({id: 'TWIT.OPENED'}) }, twit.title, '/news/' + twit.slug);
  }

  const onTwitClose = () => {
    setTwitOpened(null);
    window.history.pushState({ additionalInformation: intl.formatMessage({id: 'TWIT.CLOSED'}) }, '', originalPathname);
  }

  const onTwitShare = (twit: TwitSerialized) => {
    setTwitShareOpened(twit);
  }

  const onTwitComment = () => {
      if(!currentUser) setLoginOpened(true);
  }

  const onTwitUpvote = (twit: TwitSerialized, refreshOpened: boolean = false) => {
    let index = twits.indexOf(twit);

    twits[index].loadingUpvote = true;
    setTwitLoading(true);

    const twitUpvote = async () => {
      const response = await patchTwitUpvote(twit);
      // const {data: newTwit} = await getTwit(twit.slug);
      // twits[index] = newTwit;
    }

    twitUpvote()
        .then(() => {
            twits[index].upvoted = true;
            twits[index].loadingUpvote = false
            ;
            if(refreshOpened) setTwitOpened(twits[index]);
            setTwitLoading(false);
        })
        .catch((error) => {
            resolveResponse(error, index, refreshOpened);
        });
  }

  const onTwitBookmark = (twit: TwitSerialized, refreshOpened: boolean = false) => {
    let index = twits.indexOf(twit);

    twits[index].loadingBookmark = true;
    setTwitLoading(true);

    const twitBookmark = async () => {
      const response = await postTwitBookmark(twit);
      // const {data: newTwit} = await getTwit(twit.slug);
      // twits[index] = newTwit;
    }

    twitBookmark()
        .then(() => {
            twits[index].bookmark = true;
            twits[index].loadingBookmark = false;
            if(refreshOpened) setTwitOpened(twits[index]);
            setTwitLoading(false);
        })
        .catch((error) => {
            resolveResponse(error, index, refreshOpened);
        });
  }

  const resolveResponse = (error: any, index: number, refreshOpened: boolean) => {
      if(error.response){
          if(error.response.status === 401 || error.response.status === 403){
              setLoginOpened(true);
          }
      }

      twits[index].loadingUpvote = false;
      twits[index].loadingBookmark = false;
      if(refreshOpened) setTwitOpened(twits[index]);

      setTwitLoading(false);
  }

  const nextPage = () => {
      let newPage = page + 1;
      setPage(newPage);

      setLoadingNext(true)
      setNextPageAllowed(false);

      const loadTwits = async () => {
          let {data: twitsResponse} = await getTwits(twitList, newPage);
          twitsResponse = await completeTwitsData(twitsResponse);

          setTwits(twits.concat(twitsResponse));
          setLoadingNext(false)

          if(twitsResponse.length > 0) setNextPageAllowed(true);
      }

      loadTwits().catch(() => {
          setLoadingNext(false)
      });
  }

  const completeTwitsData = async (twitsResponse: TwitSerialized[]) => {
      if(currentUser && twitsResponse.length > 0){
          const {data: verifyResponse} = await patchVerifyTwits(twitsResponse);
          if(verifyResponse.length > 0){
              verifyResponse.map((verifyItem: {
                  id: string
                  upvoted: boolean
                  bookmark: boolean
              }) => {
                  twitsResponse.map((twitItem: TwitSerialized) => {
                      if(twitItem.id === verifyItem.id){
                          twitItem.upvoted = verifyItem.upvoted;
                          twitItem.bookmark = verifyItem.bookmark;
                      }
                  })
              })
          }
      }

      return twitsResponse;
  };

  useEffect(() => {
      if(!twitList.listType) return;
      setLoading(true)

      const loadTwits = async () => {
          let {data: twitsResponse} = await getTwits(twitList, 1); // always load first page
          twitsResponse = await completeTwitsData(twitsResponse);

          setTwits(twitsResponse);
          setLoading(false)
          setPage(1);

          if(twitsResponse.length > 0) setNextPageAllowed(true);
      }

      loadTwits().catch(() => {
          setLoading(false)
          setPage(1);
      });

      if(twitList.listType === 'by-source' || twitList.listType === 'by-tag'){
          setTopicsAllowed(false);
      }else{
          setTopicsAllowed(true);
      }
  }, [twitList])

  return (
      <div>
          {topicsAllowed &&
          <>
              <TopicsSelection
                  handleOnClick={() => {
                      setTopicsOpened(true);
                  }}
                  withUserTopics={true}
              />
          </>
          }


        <br className='mt-4' />

        {loading &&
          <div className='mw-500px px-1 py-3 m-auto text-center'>
            <span className="spinner-border text-primary" role="status"/>
          </div>
        }

        {(!loading && twits) &&
            <>
              {/*<div className='mb-4 flex-stack d-flex'>*/}
              {/*  <div>*/}

              {/*  </div>*/}
              {/*  <div>*/}
              {/*    <TwitsFilter />*/}
              {/*  </div>*/}
              {/*</div>*/}

              <div className='row g-6 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-6'>
                {twits.map((twit, key) => {
                  return (
                      <div className='col' key={key}>
                        <TwitCard
                            twit={twit}
                            onTwitOpen={onTwitOpen}
                            onTwitShare={onTwitShare}
                            onTwitUpvote={onTwitUpvote}
                            onTwitBookmark={onTwitBookmark}
                        />
                      </div>
                  )
                })}
              </div>

                {loadingNext &&
                    <div className='mw-500px mt-10 px-1 py-3 m-auto text-center'>
                        <span className="spinner-border text-primary" role="status"/>
                    </div>
                }
                {(!loadingNext && nextPageAllowed) &&
                    <div className='mw-300px m-auto mt-10 mb-2 align-items-center px-5 py-3'>
                        <button
                            className='btn btn-light-primary border border-dashed border-primary hover-scale w-100 align-items-center'
                            onClick={nextPage}
                        >
                            {/*<i className="bi bi-columns-gap px-0 me-2 fs-3"/>*/}
                            {/*<i className="bi bi-hand-index-thumb px-0 me-2 fs-3"/>*/}
                            {/*<i className="bi bi-hand-index px-0 me-2 fs-3"/>*/}
                            <i className="bi bi-chevron-double-down px-0 me-2 fs-3"/>
                            Wczytaj kolejne
                        </button>
                    </div>
                }
            </>
        }

        <TwitModal
            show={!!twitOpened}
            twit={twitOpened}
            handleClose={onTwitClose}
            onTwitUpvote={onTwitUpvote}
            onTwitComment={onTwitComment}
            onTwitBookmark={onTwitBookmark}
            onTwitShare={onTwitShare}
        />

        <TwitShareModal
            show={!!twitShareOpened}
            twit={twitShareOpened}
            handleClose={()=> {setTwitShareOpened(null)}}
        />

        <TopicsModal
            show={topicsOpened}
            handleClose={()=> {
              setTopicsOpened(false);
            }}
        />
          <LoginModal
              show={loginOpened}
              handleClose={()=> {
                  setLoginOpened(false);
              }}
          />
      </div>
  )
}

export {Twits}
