/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef} from 'react'
import {getCSSVariableValue} from "../../../../_metronic/assets/ts/_utils";
import {useThemeMode} from "../../../../_metronic/partials";

type Props = {
  numSuccess: number
  numPrimary: number
  chartSize?: number
  chartLine?: number
  chartRotate?: number
}

const ProjectsChart: FC<Props> = ({numSuccess, numPrimary,
  chartSize = 100,
  chartLine = 20,
  chartRotate = 0,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  useEffect(() => {
    refreshChart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    setTimeout(() => {
      initChart(chartSize, chartLine, chartRotate, numSuccess, numPrimary)
    }, 10)
  }

  return (
    <>
      <div className="card h-100">
        <div className="card-body p-9">
          <div className="fs-2hx fw-bold">{numSuccess + numPrimary}</div>
          <div className="fs-4 fw-semibold text-gray-400 mb-7">
            Projekty na platformie
          </div>

          <div className='pt-2 pb-4 d-flex flex-wrap align-items-center'>
            <div className='d-flex flex-center me-5'>
              <div id='projects_stats_chart' ref={chartRef} style={{minWidth: chartSize + 'px', minHeight: chartSize + 'px'}} data-kt-size={chartSize} data-kt-line={chartLine}/>
            </div>

            <div className='d-flex flex-column content-justify-center flex-row-fluid'>
              <div className='d-flex fw-semibold align-items-center'>
                <div className='bullet w-8px h-3px rounded-2 bg-success me-3'/>
                <div className='text-gray-500 flex-grow-1 me-4'>Zatrudniające</div>
                <div className='fw-bolder text-gray-700 text-xxl-end'>{numSuccess}</div>
              </div>
              <div className='d-flex fw-semibold align-items-center my-3'>
                <div className='bullet w-8px h-3px rounded-2 bg-primary me-3'/>
                <div className='text-gray-500 flex-grow-1 me-4'>Aktywne</div>
                <div className='fw-bolder text-gray-700 text-xxl-end'>{numPrimary}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const initChart = function (
  chartSize: number = 50,
  chartLine: number = 11,
  chartRotate: number = 145,
  numSuccess: number = 0,
  numPrimary: number = 0
) {
  const el = document.getElementById('projects_stats_chart')
  if (!el) { return }
  el.innerHTML = ''

  var options = {
    size: chartSize,
    lineWidth: chartLine,
    rotate: chartRotate,
    //percent:  el.getAttribute('data-kt-percent') ,
  }

  const canvas = document.createElement('canvas')
  const span = document.createElement('span')

  // @ts-ignore
  if (typeof G_vmlCanvasManager !== 'undefined') {
    // @ts-ignore
    G_vmlCanvasManager.initElement(canvas)
  }

  const ctx = canvas.getContext('2d')
  canvas.width = canvas.height = options.size

  el.appendChild(span)
  el.appendChild(canvas)

  // @ts-ignore
  ctx.translate(options.size / 2, options.size / 2) // change center
  // @ts-ignore
  ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

  //imd = ctx.getImageData(0, 0, 240, 240);
  const radius = (options.size - options.lineWidth) / 2

  const drawCircle = function (color: string, lineWidth: number, percent: number) {
    percent = Math.min(Math.max(0, percent || 1), 1)
    if (!ctx) {
      return
    }

    ctx.beginPath()
    ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
    ctx.strokeStyle = color
    ctx.lineCap = 'round' // butt, round or square
    ctx.lineWidth = lineWidth
    ctx.stroke()
  }

  if(numSuccess === 0 && numPrimary === 0){
    drawCircle('#E4E6EF', options.lineWidth, 100/100)
  }else{
    let totalTokens = numSuccess + numPrimary;
    if(totalTokens > 0){
      if(numSuccess === 0 && numPrimary === 0){
        drawCircle('#E4E6EF', options.lineWidth, 100/100)
      }

      if(numSuccess > 0 && numPrimary === 0){
        drawCircle(getCSSVariableValue('--kt-success'), options.lineWidth, 100/100)
      }

      if(numSuccess === 0 && numPrimary > 0){
        drawCircle(getCSSVariableValue('--kt-primary'), options.lineWidth, 100/100)
      }

      if(numSuccess > 0 && numPrimary > 0){
        if(numSuccess >= numPrimary){
          drawCircle(getCSSVariableValue('--kt-success'), options.lineWidth, 100/100)
          drawCircle(getCSSVariableValue('--kt-primary'), options.lineWidth, numPrimary/totalTokens)
        }else{
          drawCircle(getCSSVariableValue('--kt-primary'), options.lineWidth, 100/100)
          drawCircle(getCSSVariableValue('--kt-success'), options.lineWidth, numSuccess/totalTokens)
        }
      }
    }
  }
}

export {ProjectsChart}
