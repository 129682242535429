import React, {FC} from 'react'
import {Link} from 'react-router-dom'
import {useIntl} from "react-intl";
import {TwitOptions} from "./TwitOptions";
import {TwitSerialized} from "./_models";
import {toAbsoluteUrl} from "../../../../_metronic/helpers";

const API_URL = process.env.REACT_APP_API_URL

type Props = {
    twit: TwitSerialized
    onTwitShare: (twit: TwitSerialized) => any
    onTwitUpvote: (twit: TwitSerialized, refreshOpened: boolean) => any
    onTwitComment: () => any
    onTwitBookmark: (twit: TwitSerialized, refreshOpened: boolean) => any
    handleClose?: () => any
}

const TwitDetails: FC<Props> = ({ twit, onTwitShare, onTwitUpvote, onTwitComment, onTwitBookmark, handleClose }) => {
    const intl = useIntl()

    const generateRedirectUrl = () => {
        return `${API_URL}${twit.redirectUrl}`
    }

    const shareUrl = (platform: string) => {
        if(!twit) return '';

        const twitUrl = process.env.REACT_APP_URL + '/news/' + twit.slug;

        switch (platform) {
            case 'twitter':
                return 'http://twitter.com/share?url=' + twitUrl;
            case 'facebook':
                return 'https://www.facebook.com/sharer/sharer.php?display=page&u=' + twitUrl;
            case 'linkedin':
                return 'https://www.linkedin.com/sharing/share-offsite/?url=' + twitUrl;
            case 'telegram':
                return 'https://t.me/share/url?url=' + twitUrl;
            default:
                return '';
        }
    }

    return (
        <div className='row'>
            <div className='col-12 col-sm-8 bg-white'>
                <div className='p-0 py-5'>
                    <div className='fs-2x mb-4 fw-bold'>
                        {twit.hot &&
                        <span className="d-inline-block badge px-4 fs-7 badge-light-danger border border-danger rounded-5 me-2" title={'HOT'}><i className='bi bi-fire text-danger'/> HOT</span>
                        }
                        {twit.trending &&
                        <span className="d-inline-block badge px-4 fs-7 badge-light-info border border-info rounded-5 me-2" title={'TRENDING'}><i className='bi bi-bar-chart text-info'/> TRENDING</span>
                        }
                        <div>{twit.title}</div>
                    </div>
                    <div className={'fs-5 px-3 py-3 bg-light-primary border border-primary border-left-3 border-bottom-0 border-top-0 border-right-0 ' + (
                        twit.hot ? 'border-danger bg-light-danger' : (twit.trending && 'border-info bg-light-info')
                    )}>
                        <strong className={'d-inline-block me-2 text-primary ' + (
                            twit.hot ? 'text-danger' : (twit.trending && 'text-info')
                        )}>TLDR</strong>
                        {twit.summary}
                    </div>

                    <div className='px-2 pb-2'>
                        <p className='text-gray-400 fs-7 mt-0 mb-0 text-right'>
                            {twit.created}
                            {/*~{twit.readTime ? twit.readTime : 0} min czytania*/}
                        </p>
                    </div>

                    <img className='rounded rounded-3 w-75 mb-4' src={twit.image} alt={twit.title}/>

                    <div className='d-flex flex-wrap mb-4'>
                        <div className="border border-1 border-gray-300 border-dashed rounded py-1 px-2 text-left me-1 mt-1">
                            {intl.formatMessage({id: 'TWIT_MODAL.UPVOTES'})} <strong>{twit.numUpvotes ? twit.numUpvotes : 0}</strong>
                        </div>
                        <div className="border border-1 border-gray-300 border-dashed rounded py-1 px-2 text-left me-1 mt-1">
                            {intl.formatMessage({id: 'TWIT_MODAL.COMMENTS'})} <strong>{twit.numComments ? twit.numComments : 0}</strong>
                        </div>
                    </div>

                    <div className="card card-flush border border-1 border-secondary">
                        <div className="card-body d-flex p-1 py-3">
                            <div className='w-100'>
                                {twit.loadingUpvote &&
                                <div className='m-auto text-center py-2 align-items-center align-content-center'>
                                    <span className="spinner-border spinner-border-sm text-success" style={{width: '1.3rem', height: '1.3rem'}} role="status"/>
                                </div>
                                }

                                {!twit.loadingUpvote &&
                                (twit.upvoted ?
                                        <button className='btn m-auto d-block bg-light-success text-success p-1 py-2 align-items-center align-content-center' disabled={true}>
                                            <i className='bi bi-check fs-2 px-1 pe-0 text-success'/><span className='d-inline-block px-1'>{twit.numUpvotes}</span>
                                        </button>
                                        :
                                        <button className='btn text-muted text-hover-success m-auto d-block bg-hover-light-success p-1 py-2 align-items-center align-content-center' onClick={() => {onTwitUpvote(twit, true);}}>
                                            <i className='bi bi-arrow-up fs-2 px-1 pe-0'/>
                                            <span className='d-inline-block px-1'>{intl.formatMessage({id: 'TWIT_CARD.UPVOTE'})}</span>
                                        </button>
                                )
                                }
                            </div>
                            {/*<div className='w-100'>*/}
                            {/*    <button className='btn text-muted text-hover-primary m-auto d-block bg-hover-light-primary p-1 py-2 align-items-center align-content-center' onClick={() => {onTwitComment();}}>*/}
                            {/*        <i className='bi bi-chat-square-text fs-2 px-1 pe-0'/>*/}
                            {/*        <span className='d-inline-block px-1'>{intl.formatMessage({id: 'TWIT_CARD.COMMENTS'})}</span>*/}
                            {/*    </button>*/}
                            {/*</div>*/}
                            <div className='w-100'>
                                {twit.loadingBookmark &&
                                    <div className='m-auto text-center py-2 align-items-center align-content-center'>
                                        <span className="spinner-border spinner-border-sm text-warning" style={{width: '1.3rem', height: '1.3rem'}} role="status"/>
                                    </div>
                                }

                                {!twit.loadingBookmark &&
                                    (twit.bookmark ?
                                            <button className='btn m-auto d-block bg-light-warning text-warning p-1 py-2 align-items-center align-content-center' disabled={true}>
                                                <i className='bi bi-bookmark-check fs-2 px-1 pe-0 text-warning'/>
                                                <span className='d-inline-block px-1'>{intl.formatMessage({id: 'TWIT_CARD.BOOKMARK'})}</span>
                                            </button>
                                            :
                                            <button
                                                className='btn text-muted text-hover-warning m-auto d-block bg-hover-light-warning p-1 py-2 align-items-center align-content-center'
                                                onClick={() => {onTwitBookmark(twit, true);}}
                                                title={intl.formatMessage({id: 'TWIT_OPTIONS.BOOKMARKS_ADD'})}
                                            >
                                                <i className='bi bi-bookmark fs-2 px-1 pe-0'/>
                                                <span className='d-inline-block px-1'>{intl.formatMessage({id: 'TWIT_CARD.BOOKMARK'})}</span>
                                            </button>
                                    )
                                }
                            </div>
                            <div className='w-100'>
                                <button
                                    className='btn text-muted text-hover-info m-auto d-block bg-hover-light-info p-1 py-2 align-items-center align-content-center'
                                    onClick={() => {onTwitShare(twit);}}
                                >
                                    <i className='bi bi-arrow-90deg-right fs-2'/>
                                    <span className='d-inline-block px-1'>{intl.formatMessage({id: 'TWIT_CARD.SHARE'})}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12 col-sm-4 border border-secondary border-left-1 border-bottom-0 border-top-0 border-right-0'>
                <div className='d-flex border-0 py-5 p-0 align-items-center'>
                    <div className='w-100 me-4'>
                        <a
                            className='btn btn-outline btn-outline-dashed btn-outline-default btn-active-light-primary px-4 w-100 bg-light-dark hover-scale'
                            target="_blank" rel="noreferrer"
                            href={generateRedirectUrl()}
                        >
                            <i className='bi bi-box-arrow-up-right fs-1 me-2' />
                            {intl.formatMessage({id: 'TWIT_MODAL.OPEN_LINK'})}
                        </a>
                    </div>
                    {/*<div className='mx-4 me-0'>*/}
                    {/*    <TwitOptions />*/}
                    {/*</div>*/}
                    {handleClose &&
                    <div className='m-0'>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                            <i className='bi bi-x fs-1' />
                        </div>
                    </div>
                    }
                </div>

                <div className="card card-flush border border-1 border-secondary" title={intl.formatMessage({id: 'TWIT_MODAL.SOURCE'})}>
                    <Link
                        to={'/sources/' + twit.source.handle}
                        onClick={handleClose}
                        className="card-body d-flex p-1 py-3"
                    >
                        <div>
                            {twit.source?.image && <img title={twit.source?.name} className='w-50x h-50px rounded-1 ms-2' src={twit.source?.image} alt={twit.source?.name} />}
                        </div>
                        <div className="px-2">
                            <div className='fs-3 fw-bold'>
                                {twit.source?.name}
                            </div>
                            <div className='fs-6 text-gray-700'>
                                {twit.source?.handle}
                            </div>
                        </div>
                    </Link>
                </div>

                <div className='d-flex flex-wrap mb-4 mt-4'>
                    {twit.tags.map((tag, key) => {
                        return (
                            <Link
                                to={'/tags/' + tag.slug}
                                onClick={handleClose}
                                key={key}
                                className="border border-1 border-gray-300 border-dashed rounded py-1 px-2 text-left me-1 mt-1 bg-light-dark text-black hover-scale"
                            >
                                <i className="text-primary bi bi-check"/> #{tag.slug}
                            </Link>
                        )
                    })}
                </div>

                <div className="card card-flush border border-1 border-secondary mb-5" title={intl.formatMessage({id: 'TWIT_MODAL.RECOMMEND_POST'})}>
                    <div className='card-body d-flex p-3'>
                        {intl.formatMessage({id: 'TWIT_MODAL.RECOMMEND_POST'})}
                    </div>
                    <div className='mb-4'>
                        <a className='btn hover-scale p-0 px-3' href={shareUrl('twitter')} target="_blank" rel="noreferrer">
                            <img src={toAbsoluteUrl('/media/svg/brand-logos/twitter-2.svg')} className='w-30px' alt=''/>
                        </a>
                        <a className='btn hover-scale p-0 px-3' href={shareUrl('facebook')}  target="_blank" rel="noreferrer">
                            <img src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')} className='w-30px' alt=''/>
                        </a>
                        <a className='btn hover-scale p-0 px-3' href={shareUrl('linkedin')} target="_blank" rel="noreferrer">
                            <img src={toAbsoluteUrl('/media/svg/brand-logos/linkedin-2.svg')} className='w-30px' alt=''/>
                        </a>
                        <a className='btn hover-scale p-0 px-3' href={shareUrl('telegram')} target="_blank" rel="noreferrer">
                            <img src={toAbsoluteUrl('/media/svg/brand-logos/telegram.svg')} className='w-30px' alt=''/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {TwitDetails}
