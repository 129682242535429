import React, {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {useAuth} from "../../auth";
import {Login} from "../../auth/components/Login";

type Props = {
    show: boolean
    handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const LoginModal = ({show, handleClose}: Props) => {
    const { currentUser, setCurrentUser } = useAuth()

    useEffect(() => {

    }, [show])

    useEffect(() => {
        if(show){
            handleClose();
        }
    }, [currentUser])

    return createPortal(
        <Modal
            id='kt_modal_login'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-550px'
            show={show}
            onHide={handleClose}
            backdrop={true}
        >
            <div className={'modal-body py-0 border border-primary rounded border-1 py-4 px-0 '}>
                <div className='text-center pt-4'>
                    <Login />
                </div>
            </div>
        </Modal>,
        modalsRoot
    )
}

export {LoginModal}
