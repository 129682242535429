import React, {FC} from 'react'
import {useIntl} from "react-intl";
import {useAuth} from "../../auth";
import {Link} from "react-router-dom";

type Props = {
    handleOnClick: () => any
    withUserTopics?: boolean
}

const TopicsSelection: FC<Props> = ({ handleOnClick, withUserTopics }) => {
    const intl = useIntl()
    const { currentUser } = useAuth()

    const showTopics = () => {
        if(currentUser?.tagCategories?.length){
            return true;
        }
        return false;
    }

    return (
        <>
            <div className='mw-600px m-auto mt-10 d-flex align-items-center bg-light-primary border border-primary rounded px-5 py-3'>
                <div className='w-100 fs-5 fw-bold text-center me-2'>
                    Uzyskaj treści, <strong>dopasowane do Twoich</strong> zainteresowań
                </div>
                <button
                    className='btn btn-light-primary border border-dashed border-primary hover-scale w-200px px-2 py-2 d-flex align-items-center'
                    onClick={handleOnClick}
                >
                    {/*<i className="bi bi-columns-gap px-0 me-2 fs-3"/>*/}
                    {/*<i className="bi bi-hand-index-thumb px-0 me-2 fs-3"/>*/}
                    {/*<i className="bi bi-hand-index px-0 me-2 fs-3"/>*/}
                    <i className="bi bi-ui-checks-grid px-0 me-2 fs-3"/>
                    Wybierz tematy
                </button>
            </div>

            {(withUserTopics && currentUser && showTopics()) &&
                <div className='mw-800px m-auto mt-2 px-5 py-2 text-center'>
                    <div className='text-muted'>
                        Moje obecne zainteresowania
                    </div>
                    <div className='d-flex flex-wrap align-items-center '>
                        {
                            currentUser?.tagCategories?.map((tagCategory, key) => {
                                return (
                                    <Link
                                        to={'/topics/' + tagCategory.slug}
                                        className='col my-1 mx-1' key={key}
                                    >
                                        <div className='border border-1 border-gray-300 border-dashed rounded py-1 px-2 text-left me-1 mt-1 bg-light-dark d-flex align-items-center text-black hover-scale'>
                                                <span className='d-inline-block w-100 text-center fs-4 text-nowrap'>
                                                    <i className='bi bi-check text-primary me-1' />
                                                    {tagCategory.name}
                                                </span>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                        {/*<div className='col my-1 mx-1 w-100px' >*/}
                        {/*    <button*/}
                        {/*        className='btn btn-light-primary border border-dashed border-primary hover-scale px-2 py-2 d-flex align-items-center text-nowrap'*/}
                        {/*        onClick={handleOnClick}*/}
                        {/*    >*/}
                        {/*        <i className="bi bi-ui-checks-grid px-0 me-1 fs-3"/>*/}
                        {/*        Wybierz tematy*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                    </div>
                </div>
            }
        </>
    )
}

export {TopicsSelection}
